import React from "react";
import { Routes, Route } from "react-router-dom";
import DepartmentList from "./DepartmentList/DepartmentList";
import AddDepartment from "./AddDepartment/AddDepartment";

function Department() {
  return (
      <Routes>
        <Route path="/" element={<DepartmentList />} />
        <Route path="/add-department" element={<AddDepartment />} />
      </Routes>
  );
}

export default Department;
