import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import styles from "./StudentView.module.css";
import ViewDocument from "../../ViewDocument/ViewDocument";
// const demoData = [
//   {
//     enrollmentId: "E123",
//     studentName: "John Doe",
//     SSC: 85,
//     HSC: 90,
//     UG: 75,
//     PG: 80,
//     DIPLOMA: 70,
//     skills: ["React", "JavaScript"],
//   },
//   {
//     enrollmentId: "E124",
//     studentName: "Jane Smith",
//     SSC: 88,
//     HSC: 92,
//     UG: 78,
//     PG: 82,
//     DIPLOMA: 75,
//     skills: ["Node.js", "Express"],
//   },
//   {
//     enrollmentId: "E125",
//     studentName: "Alice Johnson",
//     SSC: 90,
//     HSC: 94,
//     UG: 80,
//     PG: 85,
//     // DIPLOMA: 78,
//     skills: ["Full Stack", "CSS"],
//   },
// ];

const StudentView = ({studentData}) => {
  const [open, setOpen] = useState(false);
  const [url,setUrl] = useState("");
  const handleViewMarksheet = async(documentUrl) =>{
    setUrl(documentUrl);
    setOpen(true)
  }

  return (
    <div className={styles.studentViewContainer}>
      <h2>Student View</h2>
      <table className={styles.studentViewTable}>
        <thead>
          <tr>
            <th>Enrollment ID</th>
            <th>Student Name</th>
            <th>SSC Percentage</th>
            <th>HSC Percentage</th>
            <th>DIPLOMA Percentage</th>

            <th>UG Percentage</th>
            <th>PG Percentage</th>
            <th>Skills</th>
          </tr>
        </thead>
        <tbody>
          { studentData.length > 0 && studentData.map((student) => (
            <tr key={student.enrollmentId}>
              <td>{student.enrollmentId}</td>
              <td>{student.studentName}</td>
              <td>
                {student.SSC?.percentage ? `${student.SSC?.percentage} %`:student.SSC?.cgpa ? `${student.SSC?.cgpa} CGPA`:"-"}
                {student.SSC?.marksheetLink && <FontAwesomeIcon icon={faEye} className={styles.viewIcon} onClick={() =>handleViewMarksheet(student.SSC?.marksheetLink)}/>}
              </td>
              <td>
                {student.HSC?.percentage ? `${student.HSC?.percentage} %`:student.HSC?.cgpa ? `${student.HSC?.cgpa} CGPA`:"-"}
                {student.HSC?.marksheetLink && <FontAwesomeIcon icon={faEye} className={styles.viewIcon}   onClick={() =>handleViewMarksheet(student.HSC?.marksheetLink)}/>}
              </td>
              <td>
                {student?.DIPLOMA?.percentage ? `${student?.DIPLOMA?.percentage} %` :student.DIPLOMA?.cgpa ? `${student.DIPLOMA?.cgpa} CGPA`:"-"}
                {student?.DIPLOMA?.marksheetLink && (
                  <FontAwesomeIcon icon={faEye} className={styles.viewIcon}  onClick={() =>handleViewMarksheet(student?.DIPLOMA?.marksheetLink)}/>
                )}
              </td>
              <td>
                {student.UG?.percentage ? `${student.UG?.percentage} %`: student.UG?.cgpa ? `${student.UG?.cgpa} CGPA`:"-"}
    
                {student.UG?.marksheetLink && <FontAwesomeIcon icon={faEye} className={styles.viewIcon} onClick={() =>handleViewMarksheet(student.UG?.marksheetLink)} />}
              </td>
              <td>
                {student.PG?.percentage ? `${student.PG?.percentage} %`:student.PG?.cgpa ? `${student.PG?.cgpa} CGPA`:"-"}
                {student.PG?.marksheetLink && <FontAwesomeIcon
                  icon={faEye}
                  className={styles.viewIcon}
                  onClick={() =>handleViewMarksheet(student.PG?.marksheetLink)}
                />}
              </td>

              <td>{student.skills.join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ViewDocument
        isOpen={open}
        onClose={() => setOpen(false)}
        documentUrl={url}
      />
    </div>
  );
};

export default StudentView;
