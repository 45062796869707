import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";
import styles from "./CreateClassroom.module.css";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const addRoom = process.env.REACT_APP_ADMIN_ADD_ROOM;
const dropdownClassroomRegister = process.env.REACT_APP_ADMIN_DROPDOWN_CLASSROOM;

const CreateClassroom = () => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [formData, setFormData] = useState({
    roomNo: "",
    roomType: "",
    floorNo: "",
    latitude: "",
    longitude: "",
    altitude: "",
  });
  const [dropDownData, setDropDownData] = useState({
    roomTypes: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "roomNo",
      "roomType",
      "floorNo",
      "latitude",
      "longitude",
      "altitude",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (formData.floorNo && (formData.floorNo < 1 || formData.floorNo > 10)) {
      validationErrors["floorNo"] = "**Floors must be between 1 and 10**";
    }

    const longitude = parseFloat(formData.longitude);
    const latitude = parseFloat(formData.latitude);

    if (longitude < -180 || longitude > 180) {
      validationErrors["longitude"] = "**Longitude must be between -180 and 180**";
    }

    if (latitude < -90 || latitude > 90) {
      validationErrors["latitude"] = "**Latitude must be between -90 and 90**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${addRoom}`, formData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      if (response.data.result) {
        Toast.success(
          response.data.message || "Classroom Added Successfully...!"
        );
        setFormData({
          roomNo: "",
          roomType: "",
          floorNo: "",
          latitude: "",
          longitude: "",
          altitude: "",
        });
      } else {
        Toast.error(response.data.message || "Failed to add classroom!");
      }
    } catch (error) {
      Toast.error(error.response.data.message || "Something went wrong");
    } finally {
      setShowLoader(false);
      navigate(-1)
    }
  };

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownClassroomRegister}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        setDropDownData(response.data.data);
      }
    } catch (error) {
      Toast.error("Failed to fetch room types.");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchDropdown();
  }, []);

  return (
    <div className={styles.classroomContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={() => navigate(-1)} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <h2 className={styles.header}>REGISTER DEPARTMENT CLASSROOMS</h2>
      <div className={styles.addClassroomScrollable}>
        <form
          className={styles.formGroup}
          onSubmit={(e) => {
            e.preventDefault();
            showConfirmation(
              `Want to register new ${formData.roomType} classroom?`,
              handleSubmit,
              e
            );
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="roomType">Room Type</label>
                <Dropdown
                  id={"roomType"}
                  value={formData.roomType}
                  options={dropDownData.roomTypes}
                  name={"roomType"}
                  onChange={handleChange}
                />
                {errors.roomType && (
                  <p className={styles.error}>{errors.roomType}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="roomNo">Room Number</label>
                <input
                  type="text"
                  id="roomNo"
                  placeholder="Room No."
                  value={formData.roomNo}
                  name="roomNo"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.roomNo && <p className={styles.error}>{errors.roomNo}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="floorNo">Floor Number</label>
                <input
                  type="number"
                  id="floorNo"
                  placeholder="Floor No."
                  value={formData.floorNo}
                  name="floorNo"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.floorNo && <p className={styles.error}>{errors.floorNo}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="latitude">
                  Latitude <span className={styles.span}>(Degrees in number)</span>
                </label>
                <input
                  type="number"
                  id="latitude"
                  placeholder="Latitude"
                  value={formData.latitude}
                  name="latitude"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.latitude && <p className={styles.error}>{errors.latitude}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="longitude">
                  Longitude <span className={styles.span}>(Degrees in number)</span>
                </label>
                <input
                  type="number"
                  id="longitude"
                  placeholder="Longitude"
                  value={formData.longitude}
                  name="longitude"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.longitude && <p className={styles.error}>{errors.longitude}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="altitude">
                  Altitude <span className={styles.span}>(Meters in number)</span>
                </label>
                <input
                  type="number"
                  id="altitude"
                  placeholder="Altitude"
                  value={formData.altitude}
                  name="altitude"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.altitude && <p className={styles.error}>{errors.altitude}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Button
                className={styles.submitButton}
                disabled={isSaveDisabled}
                variant="contained"
                color="primary"
                type="submit"
              >
                Register Classroom
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default CreateClassroom;
