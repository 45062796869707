import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import TableComponent from "../../../../CommonComponent/CommonTable/CommonTable";
import { Button, Grid, Modal, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../../../../Context/UserContext";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import axios from "axios";
import styles from "./ViewSubject.module.css";
import { BASE_URL } from "./../../../../../config/config";

function ViewSubject(FetchDataForaddCourse) {
  const { showConfirmation, getToken, setShowLoader } = useContext(UserContext);
  const navigate = useNavigate();
  const [subjectsData, setSubjectData] = useState([]);
  const [filterSubject, setFilterSubject] = useState([]);
  const [columns, setColumns] = useState([
    "Subject Code",
    "Subject Name",
    "Subject Type",
    "Semester",
    "Course Code",
    "Course Name",
    "Faculty Name",
  ]);
  const [currentData, setCurrentData] = useState();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    course: "",
    selectedSemester: "",
    subjectTypes: "",
    subjectCode: "",
    subjectName: "",
    subjectCredit: "",
    teacherId: "",
    color: "",
    minAttendance: "",
  });

  const [filter, setFilter] = useState({
    courseCode: "",
    semesterList: "",
    semester: "",
  });
  const [DropDownData, setDropDownData] = useState([]);
  const [filterSemesterDropdown, setFilterSemesterDropdown] = useState([]);
  const [semesterDropdown, setSemesterDropdown] = useState([]);
  const token = getToken();

  const getSubjectDropdown = process.env.REACT_APP_INIT_SUBJECT_DROPDOWN;
  const getSubjectList = process.env.REACT_APP_GET_SUBJECT_LIST;
  const updateSubject = process.env.REACT_APP_UPDATE_SUBJECT;
  const deleteSubject = process.env.REACT_APP_DELETE_SUBJECT;
  const recoverSubject = process.env.REACT_APP_RECOVER_SUBJECT;

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    // setFilter
    if (filter.courseCode) {
      if (filter.semester) {
        const filterByCourseAndSem = subjectsData.filter(
          (subject) =>
            subject.courseCode === filter.courseCode &&
            subject.semester === Number(filter.semester)
        );
        setFilterSubject(() => filterByCourseAndSem);
      } else {
        const filterByCourse = subjectsData.filter(
          (subject) => subject.courseCode === filter.courseCode
        );
        setFilterSubject(() => filterByCourse);
      }
      DropDownData?.courseList?.map((OBJ) => {
        if (OBJ.value === filter.courseCode) {
          setFilterSemesterDropdown(() => OBJ.semesters);
        }
      });
    } else {
      setFilterSubject(() => subjectsData);
    }
  }, [filter.courseCode, filter.semester]);

  useEffect(() => {}, [filter]);

  const filterDropdownChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const fetchDropdownData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getSubjectDropdown}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.result) {
        setDropDownData(() => response.data.data);
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  // const handleSelect = (option) => {
  //   setSelectedOption(option);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "courseCode") {
      setSemesterDropdown(() => []);
      DropDownData?.courseList?.map((OBJ) => {
        if (OBJ.value === value) {
          setSemesterDropdown(() => OBJ.semesters);
        }
      });
    }
    setCurrentData({ ...currentData, [name]: value });
  };

  const changeSubjectIsDeleteStatus = (subjectData, status) => {
    const subjectList = subjectsData.map((OBJ) => {
      if (
        OBJ.subjectCode === subjectData.subjectCode &&
        OBJ.courseCode === subjectData.courseCode &&
        OBJ.semester === subjectData.semester
      ) {
        OBJ.isActive = status;
      }
      return OBJ;
    });
    setSubjectData(() => subjectList);
  };

  const handleOnDelete = async (subjectData) => {
    try {
      setShowLoader(true);

      const response = await axios.delete(
        `${BASE_URL}${deleteSubject}/${subjectData.subjectCode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.result) {
        changeSubjectIsDeleteStatus(subjectData, false);
        setShowLoader(false);

        Toast.success(
          `Subject ${subjectData.subjectName} deleted successfully...`
        );
      } else {
        setShowLoader(false);

        Toast.error(`Failed to delete Subject ${subjectData.subjectName}...!`);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(`Error Deleting Subject...`);
    }
  };

  const handleOnRecover = async (subjectData) => {
    try {
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${recoverSubject}/${subjectData.subjectCode}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.result) {
        changeSubjectIsDeleteStatus(subjectData, true);
        setShowLoader(false);

        Toast.success(
          `Subject ${subjectData.subjectName} recovered successfully...`
        );
      } else {
        setShowLoader(false);

        Toast.error(`Failed to recover Subject ${subjectData.subjectName}...!`);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);

      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleEditSubject = async (subjectData) => {
    setIsEditing(true);
    setFormData(subjectData);
    DropDownData?.courseList?.map((OBJ) => {
      if (OBJ.value === subjectData.courseCode) {
        setSemesterDropdown(() => OBJ?.semesters);
      }
    });
    setCurrentData({...subjectData});
    setOpen(true);
  };

  const handleEditedSave = async (req, res) => {
    try {
      setShowLoader(true);

      const response = await axios.patch(
        `${BASE_URL}${updateSubject}`,
        currentData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.result) {
        Toast.success(`Subject Details Updated Successfully...`);
        fetchSubjectData();
        setCurrentData({});
        setOpen(false);
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handelAddsubject = () => {
    navigate("/hod/subjects/add-subject");
  };

  // useEffect(() => {
  //   if (subjectsData?.length !== 0) {
  //     const transformedRows = subjectsData?.map((subject) => ({
  //     ...subject,
  //     ['Subject Code']: subject.subjectCode,
  //     ['Subject Name']: subject.subjectName,
  //     ['Subject Type']: subject.subjectType,
  //     ['Semester']: subject.semester,
  //     'Course Code': subject.courseCode,
  //     'Course Name': subject.courseName,
  //     'Faculty Name':subject.facultyName
  //     }));

  //     setSubjectData(() => transformedRows)
  //     // let columnNames = Object.keys(obj);
  //     // columnNames = columnNames.filter(
  //     //   (name) =>
  //     //     name !== "facultyId" &&
  //     //     name !== "isDeleted" &&
  //     //     name !== "isActive" &&
  //     //     name !== "color" &&
  //     //     name !== "minAttendance" &&
  //     //     name !== "_id"
  //     // );
  //     setColumns([
  //       'Subject Code',
  //       'Subject Name',
  //       'Subject Type',
  //       'Semester',
  //       'Course Code',
  //       'Course Name',
  //       'Faculty Name'
  //     ]);
  //   }
  // }, [subjectsData]);

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "courseCode",
      "semester",
      "subjectType",
      "subjectCode",
      "subjectName",
      "subjectCredit",
      "color",
      "minAttendance",
      // "teacherId",
    ];

    // Check for empty fields
    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (
      formData.minAttendance &&
      (Number(formData.minAttendance) > 100 ||
        Number(formData.minAttendance) < 35)
    ) {
      validationErrors["minAttendance"] =
        "**Attendance criteria should be greater than 35 and less than 100 **";
    }

    if (formData.subjectCode && formData.subjectCode.length > 20) {
      validationErrors["subjectCode"] =
        "**Subject Code must be less than 20 characters**";
    }

    if (formData.subjectName && formData.subjectName.length > 40) {
      validationErrors["subjectName"] =
        "**Subject Name must be less than 40 characters**";
    }

    if (formData.subjectCredit) {
      const subjectCredit = Number(formData.subjectCredit); // Convert to a number
      if (
        subjectCredit < 1 || // Changed to > 1
        subjectCredit >= 10 ||
        isNaN(subjectCredit) // Check if it's a valid number
      ) {
        validationErrors.subjectCredit =
          "**Must be a number greater than 0 and less than 10**";
      }
    }

    // Check for subjectCode (custom validation for alphanumeric)
    const subjectCodePattern = /^[a-zA-Z0-9]+$/;
    if (
      formData.subjectCode &&
      !subjectCodePattern.test(formData.subjectCode)
    ) {
      validationErrors.subjectCode =
        "Subject Code should contain only alphanumeric characters.";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const fetchSubjectData = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${getSubjectList}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.result) {
        const transformedRows = response?.data?.data?.map((subject) => ({
          ...subject,
          "Subject Code": subject.subjectCode,
          "Subject Name": subject.subjectName,
          "Subject Type": subject.subjectType,
          Semester: subject.semester,
          "Course Code": subject.courseCode,
          "Course Name": subject.courseName,
          "Faculty Name": subject.facultyName,
        }));

        setSubjectData(() => transformedRows || []);
        setFilterSubject(() => transformedRows || []);
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleChangeSearch = (e) => {
    setSearchText(() => e.target.value);
  };

  useEffect(() => {
    const data = subjectsData.filter((subject) =>
      String(subject?.subjectName)
        .toLowerCase()
        .includes(String(searchText).toLowerCase())
    );
    setFilterSubject(() => data);
  }, [searchText]);

  useEffect(() => {
    fetchSubjectData();
    fetchDropdownData();
  }, []);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Course Subjects</h1>
      <div className={styles.yourSubjectsContainer}>
        <Box className={styles.addSubjectBtnContainer}>
          <Button variant="contained" onClick={handelAddsubject}>
            Add Subject
          </Button>
        </Box>

        <Box className={styles.dropDownParentContainer}>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="searchBar" className={styles.searchLabel}>
              Search Subject
            </label>
            <input
              id="searchBar"
              className={styles.searchBar}
              variant="outlined"
              onChange={handleChangeSearch}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="courseName" className={styles.dropDownLabel}>
              Course Name
            </label>
            <DropDown
              name={"courseCode"}
              value={filter?.courseCode}
              options={DropDownData?.courseList}
              onChange={filterDropdownChange}
              className={styles.dropDownSelect}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="semester" className={styles.dropDownLabel}>
              Semester
            </label>
            <DropDown
              name={"semester"}
              value={filter?.semester}
              options={filterSemesterDropdown}
              onChange={filterDropdownChange}
              className={styles.dropDownSelect}
            />
          </Box>
        </Box>

        <div className={styles.tableContainer}>
          {filterSubject && filterSubject.length > 0 ? (
            <TableComponent
              className={styles.tableContainer}
              columns={columns}
              rows={filterSubject}
              onEdit={handleEditSubject}
              onDelete={handleOnDelete}
              onRecover={handleOnRecover}
            />
          ) : (
            <h2 className={styles.noData}>
              No subjects available. Please adjust your filters or add a new
              subject.
            </h2>
          )}
        </div>

        <Modal
          className={styles.modal}
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className={styles.editSubjectContainer}>
            <div className={styles.closeIconContainer}>
              <IconButton
                className={styles.closeIconBtn}
                onClick={() => setOpen(false)}
              >
                <CloseIcon className={styles.closeIcon} />
              </IconButton>
            </div>
            <h2 className={styles.editSubjectHeader}>
              {isEditing ? "Edit Subject" : "Add New Subject"}
            </h2>
            <div className={styles.editSubjectScrollable}>
              <form className={styles.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="course">Course</label>
                      <DropDown
                        options={DropDownData.courseList}
                        value={currentData?.courseCode || ""}
                        // label={"Course List"}
                        name={"courseCode"}
                        onChange={handleChange}
                      />
                      {errors.courseCode && (
                        <p className={styles.error}>{errors.courseCode}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="semester">Semester</label>
                      <DropDown
                        options={semesterDropdown}
                        value={currentData?.semester || ""}
                        // label={"Course List"}
                        name={"semester"}
                        onChange={handleChange}
                      />
                      {errors.semester && (
                        <p className={styles.error}>{errors.semester}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="subjectType">Subject Type</label>
                      <DropDown
                        options={DropDownData.subjectTypes}
                        value={currentData?.subjectType}
                        // label={"Subject Types"}
                        name={"subjectType"}
                        onChange={handleChange}
                      />
                      {errors.subjectType && (
                        <p className={styles.error}>{errors.subjectType}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="subjectCode">Subject Code</label>
                      <input
                        type="text"
                        id="subjectCode"
                        name="subjectCode"
                        placeholder="Subject Code"
                        value={currentData?.subjectCode || ""}
                        onChange={handleChange}
                        // onInput={(e) => {
                        //   if (e.target.value.length > 6)
                        //     e.target.value = e.target.value.slice(0, 6);
                        // }}
                      />
                      {errors.subjectCode && (
                        <p className={styles.error}>{errors.subjectCode}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="subjecName">Subject Name</label>
                      <input
                        type="text"
                        id="subjecName"
                        name="subjectName"
                        placeholder="Subject Name"
                        value={currentData?.subjectName || ""}
                        onChange={handleChange}
                      />
                      {errors.subjectName && (
                        <p className={styles.error}>{errors.subjectName}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="subjectCredit">Subject Credit</label>
                      <input
                        type="number"
                        id="subjectCredit"
                        name="subjectCredit"
                        value={formData.subjectCredit}
                        onChange={handleChange}
                        placeholder="Subject Credit"
                        className={styles.inputbox}
                      />
                      {errors.subjectCredit && (
                        <p className={styles.error}>{errors.subjectCredit}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="colorPicker">Subject Color</label>
                      <input
                        type="color"
                        id="colorPicker"
                        name="color"
                        value={formData.color}
                        onChange={handleChange}
                        placeholder="Subject Color"
                        style={{
                          backgroundColor: `${formData.color}`,
                          width: "100%",
                          height: "45px",
                          color: "transparent",
                        }}
                        className={styles.inputbox}
                      />
                      {/* <span style={{backgroundColor:`${formData.color}`, height: '20px', width:'100%'}}></span> */}
                      {errors.color && (
                        <p className={styles.error}>{errors.color}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="minAttendance">
                        Least Attendance Criteria{" "}
                        <span className={styles.span}>(in Percentage)</span>
                      </label>
                      <input
                        type="number"
                        id="minAttendance"
                        name="minAttendance"
                        value={formData.minAttendance}
                        onChange={handleChange}
                        placeholder="Least Attendance Criteria"
                        className={styles.inputbox}
                      />
                      {errors.minAttendance && (
                        <p className={styles.error}>{errors.minAttendance}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.subjectInputGroup}>
                      <label htmlFor="facultyName">Faculty Name</label>
                      <DropDown
                        options={DropDownData.facultyList}
                        value={currentData?.facultyId}
                        // label={"Faculty List"}
                        name={"facultyId"}
                        onChange={handleChange}
                      />
                      {errors.facultyId && (
                        <p className={styles.error}>{errors.facultyId}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12} className={styles.submitButtonEditSubject}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      // type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        showConfirmation(
                          "Want to edit Subject ?",
                          handleEditedSave,
                          e
                        );
                      }}
                      disabled={isSaveDisabled}
                    >
                      {isEditing ? "Save" : "Add"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ViewSubject;
