import React, { useContext, useEffect, useState } from "react";
import styles from "./ViewBatches.module.css";
import { Box, IconButton, Modal } from "@mui/material";
import Toast from "../../ToastContainer/ToastContainer";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "./../../../../config/config";

const getBatches = process.env.REACT_APP_ADMIN_GET_BATCHES
const deleteBatch = process.env.REACT_APP_ADMIN_DELETE_BATCH

const ViewBatches = () => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [batches, setBatches] = useState([]);
  const [viewBatch, setViewBatch] = useState({});
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const fetchBatchesList = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(
        `${BASE_URL}${getBatches}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      if (response?.data?.result) {
        setBatches(() => response?.data?.data || []);
        setShowLoader(false)

      }
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
      Toast.error(error?.response?.data?.message || "Something Went Wrong !")
    }
  };

  const handleNavigateAddNewBatch = () => {
    navigate("./add-new-batch");
  };

  const handleNavigateViewBatchUsers = () => {
    navigate("./batch-users");
  }

  const handleNavigateAddPracticalBatch = () => {
    navigate("./add-practical-batch");
  }

  const handleClose = () => {
    setOpen(false);
  };


  const handleViewBatch = (data) => {
    setOpen(true);
    setViewBatch(() => data);
  };

  useEffect(() => {
    fetchBatchesList();
  }, []);

  const handleDeleteBatch = async (_id) => {
    try {
      setShowLoader(true)
      const response = await axios.delete(`${BASE_URL}${deleteBatch}?batchId=${_id}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
        "Content-Type": "application/json",
      });

      if (response.status === 200) {
        setBatches((prevBatches) =>
          prevBatches?.filter((batch) => batch._id !== _id),
          Toast.success(response?.data?.message || "Deleted Succesfully!")
        );
        fetchBatchesList();
      } else {
      }
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false);
    }
    handleClose();
  };


  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Academic Year</h1>
      <div className={styles.viewBatchesMainContainer}>
        <div className={styles.buttonContainer}>
          <Button variant="contained" onClick={handleNavigateAddNewBatch}>Register Academic Year Batch</Button>
          <Button variant="contained" onClick={handleNavigateAddPracticalBatch}>Register Practical Batch</Button>
          <Button variant="contained" onClick={handleNavigateViewBatchUsers}>Allocate Students</Button>
        </div>
        <hr className={styles.horizontalRow} />
        <h2 className={styles.batchTitle}>Current Batches</h2>
        <div className={styles.batchCardContainer}>
          {batches.map((BATCH, index) => (
            <div
              onClick={() => handleViewBatch(BATCH)}
              className={styles.cardContainer}
              key={index}
            >
              <h4>{BATCH.batchName}</h4>
            </div>
          ))}
        </div>

        {batches.length === 0 && (
          <h2 className={styles.noData}>
            No batches available. Please add a new batch.
          </h2>
        )}

        <Modal open={open} className={styles.modalParent} onClose={handleClose}>
          <Box className={styles.modalBackground}>
            <Box className={styles.modal}>
              <Box className={styles.closeIconContainer}>
                <IconButton onClick={handleClose} className={styles.closeIcon}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <h2 className={styles.batchName}>{viewBatch?.batchName}</h2>
              <p className={styles.modalPara}>
                Department: {viewBatch?.departmentName}
              </p>
              <p className={styles.modalPara}>Course : {viewBatch?.courseName}</p>
              <p className={styles.modalPara}>
                Start : {new Date(viewBatch?.startDate).toLocaleDateString()}
              </p>
              <p className={styles.modalPara}>
                End : {new Date(viewBatch?.endDate).toLocaleDateString()}
              </p>
              <p className={styles.modalPara}>
                Description :{" "}
                {viewBatch?.description ? viewBatch?.description : "NA"}
              </p>

              <div className={styles.sectionsContainer}>
                <p className={styles.modalPara}>Sections:</p>
                {viewBatch?.sections?.length > 0 ? (
                  viewBatch.sections.map((data) => {
                    return (
                      <Button
                        variant="outlined"
                        className={styles.batchButtons}
                        key={data}
                      >
                        {data}
                      </Button>
                    );
                  })
                ) : (
                  <p>No Sections</p>
                )}
              </div>

              <div className={styles.deleteBatchContainer}>
                <IconButton
                  onClick={() => {
                    navigate(`/hod/batches/edit-batch/${viewBatch.batchId}`);
                  }}
                >
                  <ModeEditIcon className={styles.editBatchButton} />
                </IconButton>

                <IconButton
                  onClick={() => {
                    showConfirmation(
                      "want to Delete Batch ??",
                      handleDeleteBatch,
                      viewBatch.batchId
                    );
                  }}
                >
                  <DeleteIcon className={styles.deleteBatchButton} />
                </IconButton>

              </div>
            </Box>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ViewBatches;
