import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { useContext } from "react";
import UserContext from "../../../Context/UserContext";
import styles from "./ConfirmationPopup.module.css";
import { Box, IconButton } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

export default function AlertDialog() {
  const {
    onConfirm,
    dataForConfirm,
    showConfirmationPopup,
    setShowConfirmationPopup,
    confirmationMessage,
  } = useContext(UserContext);

  const handleClose = () => {
    setShowConfirmationPopup(false);
  };

  const handleConfirm = () => {

    onConfirm(dataForConfirm);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={showConfirmationPopup}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 10000,
        }}
      >
        <div className={styles.confirmationPopup}>
          <Box className={styles.confirmationIconContainer}>
            <span>
              <HelpIcon className={styles.confirmationIcon} />
            </span>
          </Box>
          <h2 className={styles.confirmationTitle}>Are you Sure ?</h2>
          <span>{confirmationMessage}</span>

          <div className={styles.confirmationButtonContainer}>
            <IconButton onClick={handleClose} className={styles.confirmationButton} >
              <CloseIcon className={styles.noButton} />
            </IconButton>
            <IconButton onClick={handleConfirm} className={styles.confirmationButton} >
              <CheckIcon className={styles.yesButton} />
            </IconButton>
          </div>
        </div>
      </Dialog>
    </>
  );
}
