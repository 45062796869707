import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import styles from "./TimerClock.module.css";

const convertSeconds = (totalSeconds) => {
    if (!totalSeconds) {
        return `Timer Not Started Yet`;
    }
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
};

const TimerClock = ({ remainingTime, initTimer, timerRunning }) => {
    const [timeLeft, setTimeLeft] = useState(remainingTime);
    const [totalTime, setTotalTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);

    useEffect(() => {
        setTotalTime(initTimer);
    }, [initTimer]);

    useEffect(() => {
        if (remainingTime === 0) {
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
            setTimeLeft(remainingTime);
        }
    }, [remainingTime]);

    const renderTime = ({ remainingTime }) => {
        return (
            <div>
                <div>Remaining:</div>
                <div>{convertSeconds(timeLeft)}</div>
            </div>
        );
    };

    return (
        <Box className={styles.timerContainer}>
            <Typography id="remaining-time-description" variant="body1" component="p" padding={'10px'}>
                {timeLeft ? "Remaining Time" : "Timer Not Started Yet"}
            </Typography>
            {timeLeft &&
                <CountdownCircleTimer
                    isPlaying={true}
                    initialRemainingTime={timeLeft}
                    strokeWidth={7}
                    duration={totalTime}
                    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                    colorsTime={[60, 30, 15, 0]}
                    size={140}
                    onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                >
                    {renderTime}
                </CountdownCircleTimer>}
        </Box>
    );
};

export default TimerClock;
