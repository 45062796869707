import React, { useEffect, useContext, useState } from "react";
import { Button, Modal, IconButton, Box, Grid } from "@mui/material";
import TableComponent from "../../../../CommonComponent/CommonTable/CommonTable";
import UserContext from "../../../../../Context/UserContext";
import axios from "axios";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import CloseIcon from "@mui/icons-material/Close";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./DepartmentList.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "./../../../../../config/config";

const getDepartmentList = process.env.REACT_APP_GET_DEPARTMENT_LIST;
const getHodList = process.env.REACT_APP_GET_HOD_LIST;
const updateDepartment = process.env.REACT_APP_UPDATE_DEPARTMENT;
const addDepartment = process.env.REACT_APP_ADD_DEPARTMENT;
const deleteDepartment = process.env.REACT_APP_DELETE_DEPARTMENT;
const recoverDepartment = process.env.REACT_APP_RECOVER_DEPARTMENT;

function DepartmentList() {
  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);
  const [currentRow, setCurrentRow] = useState(null);
  const [departmentData, setDepartmentData] = useState();
  const [DropDownData, setDropDownData] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [formData, setFormData] = useState({
    departmentCode: "",
    departmentName: "",
    longitude: "",
    latitude: "",
    buildingName: "",
    hodId: "",
  });

  const navigate = useNavigate();

  const columns = [
    "Department Code",
    "Department Name",
    "Head of Department",
    "Building Name",
    "Latitude",
    "Longitude",
    "Altitude",
  ];

  useEffect(() => {
    if (open) {
      setFormData(currentRow);
    }
  }, [currentRow, open]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleAddDepartmentClick = () => {
    navigate('./add-department')
  };

  const handleEdit = (row) => {
    setIsEditing(true);
    setCurrentRow(row);
    setOpen(true);
    FetchDataForaddCourse();
  };

  const handleClose = () => {
    setIsEditing(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentRow((prev) => ({ ...prev, [name]: value }));
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateForm();
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "departmentCode",
      "departmentName",
      "buildingName",
      "latitude",
      "longitude",
      "altitude",
      // "hodId",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (formData.departmentCode && formData.departmentCode.length > 20) {
      validationErrors["departmentCode"] = "**Department Code must be less than 20 characters**";
    }

    if (formData.departmentName && formData.departmentName.length > 50) {
      validationErrors["departmentName"] = "**Department Name must be less than 50 characters**";
    }

    if (formData.buildingName && formData.buildingName.length > 50) {
      validationErrors["buildingName"] = "**Building Name must be less than 50 characters**";
    }

    const longitude = parseFloat(formData.longitude);
    const latitude = parseFloat(formData.latitude);

    if (longitude && (longitude <= -180 || longitude >= 180)) {
      validationErrors["longitude"] = "**Longitude must be between -180 and 180**";
    }

    if (latitude && (latitude <= -90 || latitude >= 90)) {
      validationErrors["latitude"] = "**Latitude must be between -90 and 90**";
    }

    setErrors(validationErrors);
    const hasErrors = Object.keys(validationErrors).length > 0;
    setIsSaveDisabled(hasErrors);
  };


  const handleUpdateDepartment = async () => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);

      const response = await axios.put(
        `${BASE_URL}${updateDepartment}`,
        currentRow,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setShowLoader(false);

      if (response.data.result) {
        fetchDepartmentList()
        Toast.success(response.data.message)
      } else {
        Toast.error(response.data.message)

      }
      setOpen(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error("Something Went Wrong...!")
    }
  };

  const handleAddDepartment = async () => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${addDepartment}`,
        currentRow,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setShowLoader(false);

      if (response.data.result) {
        fetchDepartmentList()
        Toast.success(response.data.message);
      } else {
        Toast.success(response.data.message);
      }

      setOpen(false);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");
    }
  };

  const handleSave = async (e) => {
    e?.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      if (isEditing) {
        handleUpdateDepartment();
      } else {
        handleAddDepartment();
      }
    } catch (error) {
      Toast.error("Something went Wrong !");
    }
  };

  useEffect(() => {
  }, [currentRow]);
  const fetchDepartmentList = async () => {
    try {
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getDepartmentList}`, {
        headers,
      });
      const data = response?.data?.data?.departmentsList;
      setShowLoader(false);

      const transformedRows = data?.map((department) => {
        return {
          ...department,
          ["Department Code"]: department.departmentCode,
          ["Department Name"]: department.departmentName,
          ["Building Name"]: department.buildingName,
          ["Latitude"]: department.latitude,
          ["Longitude"]: department.longitude,
          ["Altitude"]: department.altitude,
          ["Head of Department"]: department.hodName,
        }
      })
      // setDepartmentData( response.data.data.departmentsList)
      setDepartmentData(transformedRows);
      return data;
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");

      throw error;
    }
  };
  useEffect(() => {
    fetchDepartmentList();
  }, []);


  const filterAfterIsDeleteChange = (data, check) => {
    const newDepartmentsData = departmentData?.map((department) => {
      if (data.departmentCode === department.departmentCode) {
        department.isActive = check
      }
      return department
    })
    setDepartmentData(() => newDepartmentsData);
  }

  const handleDeleteClick = async (data) => {
    try {
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      setShowLoader(true)
      const response = await axios.delete(
        `${BASE_URL}${deleteDepartment}/${data.departmentCode}`,
        { headers }
      )
      setShowLoader(false)
      if (response.data.result) {
        filterAfterIsDeleteChange(data, false)
        Toast.success(response.data.message);
      } else {
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");
    }
  };

  const handleRecoverClick = async (data) => {
    try {
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      setShowLoader(true)
      const response = await axios.post(
        `${BASE_URL}${recoverDepartment}/${data.departmentCode}`,
        {},
        { headers }
      )
      setShowLoader(false)
      if (response.data.result) {
        filterAfterIsDeleteChange(data, true)
        Toast.success(response?.data?.message);
      } else {
        Toast.error(response?.data?.message);
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");

    }
  }

  const FetchDataForaddCourse = async () => {
    try {
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BASE_URL}${getHodList}`, {
        headers,
      });
      const data = response?.data;
      // Toast.success(response?.data?.message || "Success !")
      setDropDownData(() => data?.data);
      return data;
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");
      throw error;
    }
  };
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Department</h1>
      <Box className={styles.departmentParentContainer}>
        <Box className={styles.inputParentContainer}>
          <Box className={styles.inputContainer}>
            <label htmlFor="searchDepartment" className={styles.searchLabel}>
              Search Department
            </label>
            <input
              id="searchDepartment"
              className={styles.searchDepartment}
              variant="outlined"
            // onChange={handleChangeSearch} 
            />
          </Box>

          <Box className={styles.btnContainer}>
            <Button variant="contained" className={styles.addDepartmentButton} onClick={handleAddDepartmentClick}>
              Add Department
            </Button>
          </Box>
        </Box>

        {departmentData && departmentData.length > 0 ? (
          <TableComponent
            className={styles.tableContainer}
            columns={columns}
            rows={departmentData}
            onEdit={handleEdit}
            // onView={handleDeleteClick}
            onDelete={handleDeleteClick}
            onRecover={handleRecoverClick}
          />
        ) : (
          <h2 className={styles.noData}>
            No Department available. Please adjust your filters or add a new
            subject.
          </h2>
        )}

        <Modal open={open} onClose={handleClose}>
          <Box className={styles.updateDepartmentContainer}>
            <Box className={styles.closeIconContainer}>
              <IconButton>
                <CloseIcon
                  className={styles.closeButton}
                  onClick={handleClose}
                />
              </IconButton>
            </Box>
            <h2 className={styles.updateDepartmentHeader}>
              {isEditing
                ? "Edit Department"
                : "Add New Department"}
            </h2>

            <div className={styles.updateDepartmentScrollable}>
              <form className={styles.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="departmentCode">Department Code</label>
                      {isEditing ? (
                        <input
                          type="text"
                          id="departmentCode"
                          placeholder="Department Code"
                          name="departmentCode"
                          value={currentRow?.departmentCode || ""}
                          onChange={handleChange}
                          readOnly={false}
                        />
                      ) : (
                        <input
                          type="text"
                          id="departmentCode"
                          placeholder="Department Code"
                          name="departmentCode"
                          value={currentRow?.departmentCode || ""}
                          onChange={handleChange}
                          autoComplete="departmentCode"
                        />
                      )}
                      {errors.departmentCode && <p className={styles.error}>{errors.departmentCode}</p>}
                    </div>
                  </Grid>


                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="departmentName">Department Name</label>
                      <input
                        type="text"
                        id="departmentName"
                        placeholder="Department Name"
                        name="departmentName"
                        value={currentRow?.departmentName || ""}
                        onChange={handleChange}
                      // autoComplete="departmentName"
                      />
                      {errors.departmentName && <p className={styles.error}>{errors.departmentName}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="buildingName">Building Name</label>
                      <input
                        type="text"
                        id="buildingName"
                        placeholder="Building Name"
                        name="buildingName"
                        value={currentRow?.buildingName || ""}
                        onChange={handleChange}
                      // autoComplete="buildingName"
                      />
                      {errors.buildingName && <p className={styles.error}>{errors.buildingName}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="latitude">Latitude <span className={styles.span}>(Degrees in number)</span></label>
                      <input
                        type="number"
                        id="latitude"
                        placeholder="Latitude"
                        name="latitude"
                        value={currentRow?.latitude || ""}
                        onChange={handleChange}
                      // autoComplete="latitude"
                      />
                      {errors.latitude && <p className={styles.error}>{errors.latitude}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="longitude">Longitude <span className={styles.span}>(Degrees in number)</span></label>
                      <input
                        type="number"
                        id="longitude"
                        placeholder="Longitude"
                        name="longitude"
                        value={currentRow?.longitude || ""}
                        onChange={handleChange}
                      // autoComplete="longitude"
                      />
                      {errors.longitude && <p className={styles.error}>{errors.longitude}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="altitude">Altitude <span className={styles.span}>(Metres in number)</span></label>
                      <input
                        type="number"
                        id="altitude"
                        placeholder="Altitude"
                        name="altitude"
                        value={currentRow?.altitude || ""}
                        onChange={handleChange}
                      // autoComplete="longitude"
                      />
                      {errors.altitude && <p className={styles.error}>{errors.altitude}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="hodId">Head of Department <span className={styles.span}>(HOD)</span></label>
                      {
                        < DropDown
                          options={DropDownData}
                          value={currentRow?.hodId || ""}
                          name={"hodId"}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setCurrentRow((prev) => ({ ...prev, hodId: selectedValue }));
                            setFormData((prev) => ({ ...prev, hodId: selectedValue })); // Update formData as well
                            validateForm(); // Validate after changing
                          }}
                        />
                      }
                      {errors.hodId && <p className={styles.error}>{errors.hodId}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12} className={styles.submitButton}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        showConfirmation(
                          "Want to add this Department?",
                          handleSave
                        );
                      }}
                      disabled={isSaveDisabled}
                    >
                      {isEditing ? "Save" : "Add"}
                    </Button>
                  </Grid>

                </Grid>
              </form>
            </div>
          </Box>
        </Modal >
      </Box >
    </div>
  );
}

export default DepartmentList;
