import React, { useContext } from 'react'
import styles from "./SuperAdminDashboard.module.css"
import UserContext from '../../../../Context/UserContext';

const SuperAdminDashboard = () => {
    const {
        getDecodedToken
    } = useContext(UserContext);

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.header}>
                Greetings to {getDecodedToken().fname}!
            </h1>
            <h2 className={styles.greetHeader}>Welcome to Attendance Management.</h2>
        </div>
    )
}

export default SuperAdminDashboard