import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Grid,
  Typography,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../../../Context/UserContext";
import TimerClock from "../../../CommonComponent/TimerClock/TimerClock";
import axios from "axios";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./StudentDashboard.module.css";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Chart } from "react-google-charts";
import { BASE_URL } from "./../../../../config/config";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const getLectures = process.env.REACT_APP_GET_LECTURES;
const getStudentWholeAttendance =
  process.env.REACT_APP_STUDENT_GET_WHOLE_ATTENDANCE;
const getNewsLetter = process.env.REACT_APP_HOD_GET_NEWSLETTER;

// Utility function to format the time
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const StudentDashboard = () => {
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [pieChartData, setPieChartData] = useState([["Task", "Hours per Day"]]);
  const [remainingTime, setRemainingTime] = useState(0);
  const {
    createAttendanceSocketConnection,
    attendanceSocket,
    facultyId,
    setShowLoader,
    getDecodedToken,
  } = useContext(UserContext);
  const [lectureData, setLectureData] = useState([]);
  const { getToken } = useContext(UserContext);
  const [markedAttendanceLectures, setMarkedAttendanceLectures] = useState(
    new Set()
  );
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLecture, setCurrentLecture] = useState(null);
  const [timerRunning, setTimerRunning] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [activeLectures, setActiveLectures] = useState([]);
  const [attendanceStatus, setAttendanceStatus] = useState({});
  const [currentActiveLecture, setCurrentActiveLecture] = useState(null);

  const navigate = useNavigate();
  const checkLectureActive = (startTimeISO, endTimeISO) => {
    const currentTime = new Date();

    const currentHours = currentTime.getUTCHours();
    const currentMinutes = currentTime.getUTCMinutes();

    const startTime = new Date(startTimeISO);
    const startHours = startTime.getUTCHours();
    const startMinutes = startTime.getUTCMinutes();

    const endTime = new Date(endTimeISO);
    const endHours = endTime.getUTCHours();
    const endMinutes = endTime.getUTCMinutes();

    const currentTotalMinutes = currentHours * 60 + currentMinutes;
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;

    return (
      currentTotalMinutes >= startTotalMinutes &&
      currentTotalMinutes < endTotalMinutes
    );
  };

  const fetchLectures = async () => {
    try {
      const currentDate = new Date();

      const token = getToken();

      setShowLoader(true);
      const studentResponse = await axios.get(
        `${BASE_URL}${getLectures}?date=${currentDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowLoader(false);

      // Sorting lectures to put active ones at the start
      const sortedLectures = studentResponse.data.data.sort((a, b) => {
        const aIsActive = checkLectureActive(a.startTime, a.endTime);
        const bIsActive = checkLectureActive(b.startTime, b.endTime);

        // Place active lectures before inactive ones
        if (aIsActive && !bIsActive) return -1;
        if (!aIsActive && bIsActive) return 1;
        return 0; // Maintain current order if both are either active or inactive
      });

      setLectureData(sortedLectures);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const getPiechartData = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${getStudentWholeAttendance}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      let subjectData = response?.data?.data?.subjectList;

      subjectData = subjectData.map((subjectData) => {
        return [
          `${subjectData.subjectName}`,
          Number(`${subjectData.presentCount}`),
        ];
      });

      subjectData.push([`Absent`, Number(response?.data?.data?.wholeAbsent)]);

      setPieChartData([...pieChartData, ...subjectData]);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  useEffect(() => {
    const stopper = setInterval(() => {
      if (lectureData.length > 0) {
        const activeLecturesList = lectureData.filter((OBJ) =>
          checkLectureActive(OBJ.startTime, OBJ.endTime)
        );
        setActiveLectures(activeLecturesList);

        // Set the current active lecture
        if (activeLecturesList.length > 0) {
          setCurrentActiveLecture(activeLecturesList[0]);
        }
      }
    }, 1000);

    return () => {
      clearInterval(stopper);
    };
  }, [lectureData]);

  useEffect(() => {
    if (remainingTime === 0) {
      // setIsAttendanceMarked(true);
      setTimerRunning(false);
    }
  }, [remainingTime]);

  useEffect(() => {
    fetchLectures();
  }, [facultyId]);

  useEffect(() => {}, [activeLectures, currentActiveLecture]);

  useEffect(() => {
    getPiechartData();
  }, []);

  const handleOpen = (lecture) => {
    createAttendanceSocketConnection();
    if (markedAttendanceLectures.has(lecture.lectureId)) return;
    setSelectedLecture(() => lecture);
    setCurrentLecture(lecture.lectureId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSecondModalClose = () => {
    setOpenSecondModal(false);
  };

  const joinRoom = () => {
    const userId = getDecodedToken()._id;

    attendanceSocket?.off(`${userId}`);
    attendanceSocket?.off("setTimeoutValue");
    attendanceSocket?.off(`${selectedLecture?.lectureId}`);
    attendanceSocket?.off(`${selectedLecture?.lectureId}-close`);

    // Set new event listeners
    attendanceSocket?.on(`${userId}`, async (data) => {
      Toast.success("Attendance Marked...🎉");
      setOpenSecondModal(false);
      setOpen(false);
    });

    attendanceSocket?.on("setTimeoutValue", (data) => {
      setTotalTime(() => Number(data.time));
      setOpenSecondModal(true);
    });

    attendanceSocket?.on(`${selectedLecture?.lectureId}`, (data) => {
      if (currentLecture === selectedLecture?.lectureId) {
        setRemainingTime(Number(data.time));
      }
    });

    attendanceSocket?.on(`${selectedLecture?.lectureId}-close`, () => {
      Toast.error("Times Up...❗❗");
      setCurrentLecture(null);
      setOpen(false);
      setOpenSecondModal(false);
    });

    const data = {
      lectureId: selectedLecture?.lectureId,
      subjectName: selectedLecture?.subjectName,
      startTime: selectedLecture?.startTime,
      endTime: selectedLecture?.endTime,
      timer: "1",
      userId: userId,
      batchId: selectedLecture?.batchId,
      sectionId: selectedLecture?.sectionId,
    };

    attendanceSocket?.emit("joinRoom", data);
  };

  const handleMarkAttendance = () => {
    if (attendanceStatus[selectedLecture?.lectureId]) {
      return;
    }

    const userId = getDecodedToken()._id;

    attendanceSocket.on(`${userId}-presentStatus`, (data) => {
      if (data === "P") {
        setAttendanceStatus((prev) => ({
          ...prev,
          [selectedLecture?.lectureId]: true,
        }));
      }
    });

    const data = {
      lectureId: selectedLecture?.lectureId,
      subjectName: selectedLecture?.subjectName,
      startTime: selectedLecture?.startTime,
      endTime: selectedLecture?.endTime,
      studentId: userId,
    };

    attendanceSocket?.emit("markAttendance", data);

    attendanceSocket?.on("attendanceData", () => {
      Toast.success("Attendance Marked...🎉");
      setMarkedAttendanceLectures((prevSet) =>
        new Set(prevSet).add(selectedLecture?.lectureId)
      );
    });
  };

  const pieChartOptions = {
    pieHole: 0.4,
    is3D: true,
    slices: [
      { color: "#FF7F50" }, // Coral (lighter shade of Vibrant Red-Orange)
      { color: "red" }, // Light Green (lighter shade of Bright Green)
      { color: "#6699FF" }, // Light Blue (lighter shade of Vibrant Blue)
      { color: "#F9E79F" }, // Light Yellow (lighter shade of Bright Yellow)
      { color: "#BB8FCE" }, // Light Purple (lighter shade of Purple)
      { color: "#F39C12" }, // Light Orange (lighter shade of Orange)
      { color: "#2ECC71" }, // Emerald Green (keeping as is)
      { color: "#D0E1E9" }, // Light Blue (lighter shade of Light Blue)
    ],
    // pieSliceText: "label",
    legend: {
      position: "none",
    },
    backgroundColor: "transparent",
  };

  const getColor = (index) => {
    const colors = [
      { color: "#FF7F50" }, // Coral (lighter shade of Vibrant Red-Orange)
      { color: "red" }, // Light Green (lighter shade of Bright Green)
      { color: "#6699FF" }, // Light Blue (lighter shade of Vibrant Blue)
      { color: "#F9E79F" }, // Light Yellow (lighter shade of Bright Yellow)
      { color: "#BB8FCE" }, // Light Purple (lighter shade of Purple)
      { color: "#F39C12" }, // Light Orange (lighter shade of Orange)
      { color: "#2ECC71" }, // Emerald Green (keeping as is)
      { color: "#D0E1E9" }, // Light Blue (lighter shade of Light Blue)
    ];
    return colors[index % colors.length];
  };

  const getNews = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BASE_URL}${getNewsLetter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.result) {
        setNewsList(response.data.data || []);
      } else {
        // setNewsList([]);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const itemsPerPage = 1;
  const totalPages = Math.ceil(newsList.length / itemsPerPage);

  const pageColors = [
    "#f0f8ff",
    "#ffe4e1",
    "#e6fffa",
    "#ffebcd",
    "#fffacd",
    "#f5f5dc",
    "#fafad2",
    "#d3d3d3",
    "#add8e6",
    "#90ee90",
    "#dcdcdc",
    "#f0fff0",
    "#ffb6c1",
    "#ff6347",
    "#ff4500",
  ];

  useEffect(() => {
    if (totalPages === 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(1);
    }

    const intervalId = setInterval(() => {
      setCurrentPage((prevPage) => {
        if (totalPages === 0) return 0;
        return prevPage < totalPages ? prevPage + 1 : 1; // Loop back to page 1 when reaching last page
      });
    }, 7000);

    return () => clearInterval(intervalId);
  }, [totalPages]);

  const currentNewsItem = newsList[currentPage - 1]; // Adjusted for 1-based indexing

  // Handle next page
  const nextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  // Handle previous page
  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const pageStyle = {
    backgroundColor: pageColors[currentPage] || "#ffffff",
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainDashboard}>
        <h1 className={styles.titleDashboard}>Today's Lectures</h1>
        <Box className={styles.cardsContainer}>
          <Box className={styles.lectureContainer}>
            <Grid container spacing={3}>
              {lectureData.length > 0 ? (
                lectureData.map((lecture, index) => (
                  <Grid item key={index} xs={12} sm={6} md={3} lg={4}>
                    <Card
                      className={
                        activeLectures.some(
                          (data) => data.lectureId === lecture.lectureId
                        )
                          ? styles.activeCard
                          : styles.cards
                      }
                      onClick={() => handleOpen(lecture)}
                    >
                      <Box className={styles.cardContent}>
                        <Typography variant="h6" className={styles.cardHeader}>
                          {lecture.subjectName}
                        </Typography>
                        <Typography variant="body2" className={styles.cardPara}>
                          Room No: {lecture.roomNo}
                        </Typography>
                        <Typography variant="body2" className={styles.cardPara}>
                          Type: {lecture.subjectType}
                        </Typography>
                        <div className={styles.lectureTime}>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Start Time: {formatTime(lecture.startTime)}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            End Time: {formatTime(lecture.endTime)}
                          </Typography>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Box className={styles.noLectureContainer}>
                  <h2 className={styles.noLecture}>No lectures available</h2>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </div>

      <Box className={styles.sideContainer}>
        <div className={styles.marqueeContainer}>
          <h2 className={styles.newsTitle}>News / Events</h2>
          <Box className={styles.marqueeScrollable} style={pageStyle}>
            <Box className={styles.newsContent}>
              {currentNewsItem ? (
                <div className={styles.contentInfo}>
                  <div className={styles.descContainer}>
                    <span>
                      <p>Title</p>
                      <p>{currentNewsItem.title}</p>
                    </span>
                    <span>
                      <p>Date</p>
                      <p>
                        {" "}
                        {new Date(currentNewsItem.date).toLocaleDateString()}
                      </p>
                    </span>
                    <span>
                      <p>Details</p>
                      <p>{currentNewsItem.description}</p>
                    </span>
                  </div>
                </div>
              ) : (
                <p className={styles.noData}>No news available.</p>
              )}
            </Box>
          </Box>

          <Box
            className={styles.paginationContainer}
            display="flex"
            alignItems="center"
          >
            <IconButton
              className={styles.pageButton}
              onClick={prevPage}
              disabled={currentPage === 1 || totalPages === 0}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <h4 style={{ fontStyle: "italic" }}>
              {totalPages === 0 ? "0 of 0" : `${currentPage} of ${totalPages}`}
            </h4>

            <IconButton
              className={styles.pageButton}
              onClick={nextPage}
              disabled={currentPage === totalPages || totalPages === 0}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </div>

        <Box className={styles.pieChartContainer}>
          <h2 className={styles.pieChartTitle}>Total Attendance</h2>
          <div
            onClick={() => {
              navigate("./attendance");
            }}
          >
            <Chart
              className={styles.pichart}
              chartType="PieChart"
              data={pieChartData}
              options={pieChartOptions}
            />
          </div>
          <Box className={styles.customLegend}>
            {pieChartData.slice(1).map(([label, value], index) => (
              <Box key={index} className={styles.legendItem}>
                <span
                  className={styles.legendColor}
                  style={{ backgroundColor: getColor(index).color }}
                ></span>
                <span className={styles.legendLabel}>{label}</span>
              </Box>
            ))}
          </Box>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            className={styles.calendar}
            sx={{
              "& .MuiPickersDay-root": {
                color: "white",
              },
              "& .Mui-selected": {
                backgroundColor: "yourSelectedColor",
              },
              "& .MuiIconButton-root": {
                color: "white",
              },
              "& .MuiTypography-root": {
                color: "white",
              },
            }}
          />{" "}
        </LocalizationProvider>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modal}>
            <Box className={styles.closeIconContainer}>
              <IconButton onClick={handleClose} className={styles.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Box>
            {selectedLecture && (
              <Box className={styles.modalContent}>
                <h2 className={styles.modalSubjectHeader}>
                  {selectedLecture.subjectName}
                </h2>
                <h3 className={styles.modalSubCodeHeader}>
                  ({selectedLecture.subjectCode})
                </h3>
                <p className={styles.modalPara}>
                  Course Code: {selectedLecture.courseCode}
                </p>
                <p className={styles.modalPara}>
                  Course Name: {selectedLecture.courseName}
                </p>
                <p className={styles.modalPara}>
                  Room No: {selectedLecture.roomNo}
                </p>
                <p className={styles.modalPara}>
                  Type: {selectedLecture.subjectType}
                </p>
                <p className={styles.modalPara}>Day: {selectedLecture.day}</p>
                <p className={styles.modalPara}>
                  Start Time: {formatTime(selectedLecture.startTime)}
                </p>
                <p className={styles.modalPara}>
                  End Time: {formatTime(selectedLecture.endTime)}
                </p>
                <p className={styles.modalPara}>
                  {selectedLecture.facultyName}
                </p>

                {currentActiveLecture?.lectureId ===
                selectedLecture?.lectureId ? (
                  <Button
                    className={styles.modalButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      joinRoom();
                    }}
                  >
                    Join Lecture
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.modalInactiveButton}
                    disabled
                  >
                    Lecture Inactive
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal open={openSecondModal} onClose={handleSecondModalClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.lectureModal}>
            <Box className={styles.closeIconContainer}>
              <IconButton>
                <CloseIcon
                  onClick={handleSecondModalClose}
                  className={styles.closeIconSecondModal}
                />
              </IconButton>
            </Box>
            <h2 className={styles.lectureTimeHeader}>
              {currentActiveLecture?.lectureId === selectedLecture?.lectureId
                ? "Time is Ticking"
                : "This Lecture is inactive"}
            </h2>
            {currentActiveLecture?.lectureId === selectedLecture?.lectureId &&
              remainingTime !== null && (
                <TimerClock
                  className={styles.timer}
                  remainingTime={remainingTime}
                  initTimer={totalTime}
                />
              )}
            {remainingTime > 0 &&
              !attendanceStatus[selectedLecture?.lectureId] &&
              currentActiveLecture?.lectureId ===
                selectedLecture?.lectureId && (
                <Button
                  className={styles.markAttendanceButton}
                  variant="contained"
                  color="primary"
                  onClick={handleMarkAttendance}
                >
                  Mark Attendance
                </Button>
              )}
            {remainingTime > 0 &&
              attendanceStatus[selectedLecture?.lectureId] &&
              currentActiveLecture?.lectureId ===
                selectedLecture?.lectureId && (
                <Typography className={styles.attendanceStatus}>
                  You have already marked your attendance
                </Typography>
              )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StudentDashboard;
