import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Img from '../../../images/Confirmed attendance.gif';
import Logo from "../../../images/HM-logo.png";
import navbarLogo from "../../../images/Untitled design.png";
import styles from "./UpdatePassword.module.css";
import { Button, Box, AppBar, Toolbar } from "@mui/material";
import Toast from '../../CommonComponent/ToastContainer/ToastContainer';
import UserContext from "../../../Context/UserContext";
import { BASE_URL } from "./../../../config/config";
// import MenuIcon from "@mui/icons-material/Menu";

const updatePassword = process.env.REACT_APP_FORGOT_PASSWORD;

function UpdatePassword() {
  const { setShowLoader } = useContext(UserContext)
  const [formData, setFormData] = useState({
    otp: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [errors, setErrors] = useState({
    otp: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [isFormValid, setIsFormValid] = useState("");
  const [loginType, setLoginType] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();

  const validateNewPassword = (newpassword) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
    return passwordRegex.test(newpassword);
  };

  const validateConfirmNewPassword = (confirmnewpassword) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
    return passwordRegex.test(confirmnewpassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const newErrors = { ...errors };

    if (name === 'newPassword') {
      newErrors.newPassword = validateNewPassword(value) ? '' : 'Password must start with a capital letter, must include at least a symbol and a number, and consist of 8 characters.';
    }

    if (name === 'confirmNewPassword') {
      newErrors.confirmNewPassword = validateConfirmNewPassword(value) ? '' : 'Password must start with a capital letter, must include at least a symbol and a number, and consist of 8 characters.';
    }

    setErrors(newErrors);

    // Form validity check
    setIsFormValid(
      validateNewPassword(formData.newPassword) && validateConfirmNewPassword(formData.confirmNewPassword)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Resetting errors
    setErrors({
      otp: "",
      newPassword: "",
      confirmNewPassword: "",
    });


    // Validating password match
    if (formData.newPassword !== formData.confirmNewPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: "Passwords do not match",
        confirmNewPassword: "Passwords do not match",
      }));
      return;
    }

    // Validating new password format
    if (!validateNewPassword(formData.newPassword)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword:
          "Password must start with a capital letter, must include at least a symbol and a number, and consist of 8 characters.",
      }));
      return;
    }

    // Validating confirm new password format
    if (!validateConfirmNewPassword(formData.confirmNewPassword)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPassword:
          "Password must start with a capital letter, must include at least a symbol and a number, and consist of 8 characters.",
      }));
      return;
    }

    // Proceed with API call to update password
    try {
      setShowLoader(true)
      const response = await axios.post(
        `${BASE_URL}${updatePassword}`,
        formData
      );
      const data = response.data;
      if (data?.result) {
        Toast.success(data?.message || "Success !")
        navigate("/login");
      } else {
        Toast.error(data?.message || "Something Went Wrong !")
      }
      setShowLoader(false)

    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !")
      setShowLoader(false)
    }
  };

  useEffect(() => {
    // We update the form validity whenever formData or errors change
    setIsFormValid(
      validateNewPassword(formData.newPassword) && validateConfirmNewPassword(formData.confirmNewPassword)
    );
  }, [formData.otp, formData.newPassword, formData.confirmNewPassword]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <AppBar position="sticky" className={styles.theNavbar}>
        <Toolbar className={styles.navbarflex}>
          <a href="/">
            <img src={navbarLogo} className={styles.navbarLogo} alt="logo pic" />
          </a>
          {/* Hamburger menu icon for small screens */}
          {/* <IconButton
            onClick={toggleDrawer}
            className={styles.menuIcon}
          >
            <MenuIcon />
          </IconButton> */}

          {/* Desktop Menu (Visible only on large screens) */}
          {/* <Box className={styles.navbarButtons}>
            <Button
              color="inherit"
              onClick={() => setLoginType('institute')}
              className={loginType === 'institute' ? styles.activeLink : ''}
            >
              Institute
            </Button>
            <Button
              color="inherit"
              onClick={() => setLoginType('campus')}
              className={loginType === 'campus' ? styles.activeLink : ''}
            >
              Campus
            </Button>
          </Box> */}
        </Toolbar>
      </AppBar>

      <div className={styles.main}>
        <Box className={styles.leftContainer}>
          <img src={Logo} id={styles.logoLogin} alt='logo pic' />
          <img src={Img} id={styles.imageLogin} alt='login pic' />
        </Box>
        <div className={styles.rightContainer}>
          <h2>Update Password</h2>
          <form className={styles.UpdatePasswordForm} onSubmit={handleSubmit} noValidate>
            <div className={styles.loginGroup}>
              <label>OTP</label>
              <input
                type="text"
                id="otp"
                placeholder="OTP"
                name="otp"
                value={formData.otp}
                onChange={handleChange}
                autoComplete="OTP"
              />
            </div>
            <div className={styles.loginGroup}>
              <label>New Password</label>
              <input
                type="password"
                id="newPassword"
                placeholder="New Password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                autoComplete="new-password"
              />
              {errors.newPassword && (
                <span className={styles.error}>{errors.newPassword}</span>
              )}
            </div>
            <div className={styles.loginGroup}>
              <label>Confirm New Password</label>
              <input
                type="password"
                id="confirmNewPassword"
                placeholder="Confirm New Password"
                name="confirmNewPassword"
                value={formData.confirmNewPassword}
                onChange={handleChange}
                autoComplete="new-password"
              />
              {errors.confirmNewPassword && (
                <span className={styles.error}>{errors.confirmNewPassword}</span>
              )}
            </div>

            <div className={styles.loginGroup}>
              <Button variant="contained" type="submit" className={styles.btnUpdate} disabled={!isFormValid}>
                Update Password
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.footer}>
        {/* <p>© 2024 Attendance Management System by Hostedminds. All rights reserved.</p> */}
        <p>Copyright © {new Date().getFullYear()} HOSTEDMINDS. All rights reserved.</p>
      </div>
    </>
  );
}

export default UpdatePassword;
