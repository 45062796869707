import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import styles from "./EditWorkExperience.module.css";
import Dropdown from "../../../../DropDown/DropDown";
import UserContext from "../../../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../../config/config";

const employmentTypeDropdown = process.env.REACT_APP_DROPDOWN_EMPLOYMENT_TYPE;

const EditWorkExperience = ({
  experienceData,
  handleAddWorkExperience,
  handleCloseModal,
  handleUpdateWorkExperience,
}) => {
  const [workFormData, setWorkFormData] = useState({});
  const [isWorking, setIsWorking] = useState(false);
  const [tenure, setTenure] = useState("");
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [empTypeOptions, setEmpTypeOptions] = useState([]);

  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);
  const token = Cookies.get("token");

  useEffect(() => {
    if (experienceData) {
      setWorkFormData({
        ...experienceData,
        joiningDate: experienceData.joiningDate
          ? dayjs(experienceData.joiningDate)
          : null,
        lastDate: experienceData.lastDate
          ? dayjs(experienceData.lastDate)
          : null,
      });

      setIsWorking(experienceData.isWorking || false);
      if (experienceData.joiningDate) {
        const tenure = calculateTenure(
          experienceData.joiningDate,
          experienceData.lastDate
        );
        setTenure(tenure);
      }
    }
  }, [experienceData]);

  useEffect(() => {
    fetchEmpTypeDropdown();
  }, []);

  useEffect(() => {
    validateForm();
  }, [workFormData, isWorking]);

  const fetchEmpTypeDropdown = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${employmentTypeDropdown}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmpTypeOptions(response.data.data.employmentType || []);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "position",
      "company",
      "location",
      "empType",
      "joiningDate",
      "roleDescription",
    ];

    // Check required fields
    requiredFields.forEach((field) => {
      const value = workFormData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (workFormData.position && workFormData.position.trim().length > 40) {
      validationErrors.position = "**Position must be at most 40 characters**";
    }

    if (workFormData.company && workFormData.company.trim().length > 40) {
      validationErrors.company = "**Company must be at most 40 characters**";
    }

    if (workFormData.location && workFormData.location.trim().length > 40) {
      validationErrors.location = "**Location must be at most 40 characters**";
    }

    if (
      workFormData.roleDescription &&
      workFormData.roleDescription.trim().length > 300
    ) {
      validationErrors.roleDescription =
        "**Role Description must be at most 300 characters**";
    }

    // Validate joiningDate
    const joiningDate = dayjs(workFormData.joiningDate);
    const today = dayjs();

    if (joiningDate.isAfter(today, "day")) {
      validationErrors.joiningDate = "**Date cannot be in the future**";
    }

    // Validate lastDate
    const lastDate = dayjs(workFormData.lastDate);
    if (lastDate.isValid()) {
      if (lastDate.isAfter(today, "day")) {
        validationErrors.lastDate = "**Date cannot be in the future**";
      }
    } else if (!isWorking) {
      validationErrors.lastDate = "**This field is required**";
    }

    // Ensure joiningDate is before lastDate
    if (
      joiningDate.isValid() &&
      lastDate.isValid() &&
      lastDate.isBefore(joiningDate)
    ) {
      validationErrors.lastDate = "**Last date must be after joining date**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setWorkFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "isWorking") {
      setIsWorking(checked);
      if (checked) {
        setWorkFormData((prevFormData) => ({
          ...prevFormData,
          lastDate: null,
        }));
        const tenure = calculateTenure(workFormData.joiningDate, null);
        setTenure(tenure);
      }
    }
  };

  const handleDateChange = (name, date) => {
    const value = date ? date.format("YYYY-MM-DD") : null;

    setWorkFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value,
      };

      if (name === "joiningDate" || name === "lastDate") {
        const tenure = calculateTenure(
          newFormData.joiningDate,
          newFormData.lastDate
        );
        setTenure(tenure);
      }

      // Validate the updated form data
      validateForm();

      return newFormData;
    });
  };

  const calculateTenure = (startDate, endDate) => {
    if (!startDate) return "";

    const start = dayjs(startDate);
    const end = endDate ? dayjs(endDate) : dayjs();

    const years = end.diff(start, "year");
    start.add(years, "year");

    const months = end.diff(start, "months");

    return `${years} year(s) and ${months} month(s)`;
  };

  const handleWorkSubmit = (e) => {
    e.preventDefault();
    validateForm();

    if (Object.keys(errors).length === 0) {
      const formDataWithCurrent = {
        ...workFormData,
        isWorking,
        tenure,
      };
      if (experienceData && experienceData._id) {
        showConfirmation(
          "want to update work experience ?",
          handleUpdateWorkExperience,
          formDataWithCurrent
        );
      } else {
        showConfirmation(
          "want to add work experience ?",
          handleAddWorkExperience,
          formDataWithCurrent
        );
      }
    }
  };

  return (
    <Box className={styles.editWorkExperienceContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton>
          <CloseIcon
            className={styles.closeButtonEditWorkExperience}
            onClick={handleCloseModal}
          />
        </IconButton>
      </Box>
      <h2 className={styles.editWorkExperienceHeader}>
        {experienceData._id ? "EDIT WORK EXPERIENCE" : "ADD WORK EXPERIENCE"}
      </h2>
      <div className={styles.editWorkExperienceScrollable}>
        <form onSubmit={handleWorkSubmit}>
          <Grid container spacing={2}>
            {[
              { name: "position", label: "Position" },
              { name: "company", label: "Company" },
              { name: "location", label: "Location" },
            ].map((field) => (
              <Grid item xs={12} key={field.name}>
                <div className={styles.editJobInputs}>
                  <label htmlFor={field.name}>{field.label}</label>
                  <input
                    type="text"
                    id={field.name}
                    placeholder={field.label}
                    name={field.name}
                    value={workFormData[field.name] || ""}
                    onChange={handleChange}
                  />
                  {errors[field.name] && (
                    <p className={styles.error}>{errors[field.name]}</p>
                  )}
                </div>
              </Grid>
            ))}
            <Grid item xs={12} key="empType">
              <div className={styles.editJobInputs}>
                <label htmlFor="empType">Employment Type</label>
                <Dropdown
                  id={styles.empType}
                  name="empType"
                  placeholder="Employment Type"
                  value={workFormData.empType || ""}
                  onChange={handleChange}
                  options={empTypeOptions}
                />
                {errors.empType && (
                  <p className={styles.error}>{errors.empType}</p>
                )}
              </div>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} key="joiningDate">
                <div className={styles.editJobInputs}>
                  <label htmlFor="joiningDate">Joining Date</label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    value={
                      workFormData.joiningDate
                        ? dayjs(workFormData.joiningDate)
                        : null
                    }
                    onChange={(date) => handleDateChange("joiningDate", date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  {errors.joiningDate && (
                    <p className={styles.error}>{errors.joiningDate}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} key="lastDate">
                <div className={styles.editJobInputs}>
                  <label htmlFor="lastDate">Last Date</label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    value={
                      workFormData.lastDate
                        ? dayjs(workFormData.lastDate)
                        : null
                    }
                    onChange={(date) => handleDateChange("lastDate", date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth required={!isWorking} />
                    )}
                    disabled={isWorking}
                  />
                  {!isWorking && errors.lastDate && (
                    <p className={styles.error}>{errors.lastDate}</p>
                  )}
                </div>
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12}>
              <div className={styles.editJobInputs}>
                <label htmlFor="isWorking" className={styles.isWorkingLabel}>
                  Currently Working?
                </label>
                <input
                  type="checkbox"
                  id="isWorking"
                  name="isWorking"
                  checked={isWorking}
                  onChange={handleChange}
                />
              </div>
            </Grid>

            <Grid item xs={12} key="roleDescription">
              <div className={styles.editJobInputs}>
                <label htmlFor="roleDescription">
                  Role Description / Responsibilities
                </label>
                <textarea
                  id="roleDescription"
                  rows="6"
                  cols="50"
                  placeholder="Role Description"
                  name="roleDescription"
                  value={workFormData.roleDescription || ""}
                  onChange={handleChange}
                  className={styles.textareaContainer}
                />
                {errors.roleDescription && (
                  <p className={styles.error}>{errors.roleDescription}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12} className={styles.SaveButtonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={styles.saveButton}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Box>
  );
};

export default EditWorkExperience;
