import React from "react";
import { Routes, Route } from "react-router-dom";
import CourseList from "./CourseList/CourseList";
import AddCourses from "./AddCourses/AddCourses";

function Courses() {
  return (
    <>
      <Routes>
        <Route path="/" element={<CourseList />}></Route>
        <Route path="add-course" element={<AddCourses />}></Route>
      </Routes>
    </>

  );
}

export default Courses;
