import { BASE_URL } from "./../../../../../config/config";
import UserContext from "../../../../../Context/UserContext";
import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import Toast from "../../../ToastContainer/ToastContainer";
import styles from "./ViewPracticalBatches.module.css"
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

const getPracticalBatch = process.env.REACT_APP_ADMIN_GET_PRACTICAL_BATCH;
const renamePracticalBatch = process.env.REACT_APP_ADMIN_RENAME_PRACTICAL_BATCH;
const moveStudentOtherBatch = process.env.REACT_APP_ADMIN_MOVE_STUDENT_OTHER_BATCH;
const deletePracticalBatch = process.env.REACT_APP_ADMIN_DELETE_PRACTICAL_BATCH;

const ViewPracticalBatches = () => {
  const {
    setShowLoader,
    getToken,
    courseCode,
    batchId,
    sectionId,
    showConfirmation,
  } = useContext(UserContext);
  const [batches, setBatches] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [newBatchName, setNewBatchName] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [targetBatchId, setTargetBatchId] = useState("");


  const navigate = useNavigate();

  const fetchPracticalBatches = async () => {
    setShowLoader(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${getPracticalBatch}?courseCode=${courseCode}&batchId=${batchId}&sectionId=${sectionId}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        setBatches(response.data.data || []);
      } else {
      }
    } catch (error) {
    } finally {
      //   setLoading(false);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (courseCode && batchId && sectionId) {
      fetchPracticalBatches();
    }
  }, [courseCode, batchId, sectionId]);

  const handleClickOpen = (batch = null) => {
    setCurrentBatch(batch);
    setNewBatchName(batch ? batch.batchName : "");
    setStudents(batch ? batch.studentsData : []);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentBatch(null);
    setNewBatchName("");
    setStudents([]);
    setSelectedStudent(null);
    setTargetBatchId("");
  };

  const handleAddBatch = () => {
    const newBatch = {
      _id: Date.now().toString(),
      batchName: newBatchName,
      studentsData: [],
    };
    setBatches([...batches, newBatch]);
    handleClose();
  };

  const handleUpdateBatch = async () => {
    try {
      const data = {
        courseCode: courseCode,
        batchId: batchId,
        sectionId: sectionId,
        practicalBatchId: currentBatch._id,
        newBatchName: newBatchName,
      };
      setShowLoader(true);
      const response = await axios.put(
        `${BASE_URL}${renamePracticalBatch}`,
        data,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response?.data?.result === true) {
        Toast.success(response.data.message || "Renamed Successfully");
        fetchPracticalBatches();
      } else {
        Toast.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      Toast.error(error.response.data.message || "Something went wrong");
    } finally {
      setShowLoader(false);
    }
    handleClose();
  };

  const handleDeleteBatch = async (id) => {
    try {
      setShowLoader(true);

      const data = {
        courseCode: courseCode,
        batchId: batchId,
        sectionId: sectionId,
        practicalBatchId: id,
      };

      const queryData = new URLSearchParams(data)

      const response = await axios.delete(`${BASE_URL}${deletePracticalBatch}?${queryData}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
        data: data,
      });


      if (response?.data?.result === true) {
        Toast.success(response.data.message || "Batch deleted successfully");
        const updatedBatches = batches.filter((batch) => batch._id !== id);
        setBatches(updatedBatches);
      } else {
        Toast.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting batch:", error);
      Toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setShowLoader(false);
    }
  };

  const handleMoveOpen = (student) => {
    setSelectedStudent(student);
    setMoveDialogOpen(true);
  };

  const handleMoveClose = () => {
    setMoveDialogOpen(false);
    setSelectedStudent(null);
    setTargetBatchId("");
  };

  const handleMoveStudent = async () => {
    try {
      const data = {
        courseCode: courseCode,
        batchId: batchId,
        sectionId: sectionId,
        practicalBatchId: currentBatch._id,
        targetBatchId: targetBatchId,
        studentEnrollmentId: selectedStudent.enrollmentId,
        studentId: selectedStudent?.studentId || null,
      };
      setShowLoader(true);
      const response = await axios.put(
        `${BASE_URL}${moveStudentOtherBatch}`,
        data,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result === true) {
        Toast.success(response.data.message || "Moved Successfully");
        fetchPracticalBatches();
      } else {
        Toast.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      Toast.error(error.response.data.message || "Something went wrong");
    } finally {
      setShowLoader(false);
      handleClose();
      handleMoveClose();
    }
  };


  return (
    <div className={styles.viewPracticalBatchContainer}>
      <Box>
        <ArrowBackIcon
          className={styles.previousButton}
          onClick={() => {
            navigate(-1);
          }}
        />
      </Box>

      <h2 className={styles.batchContainerTitle}>Practical Batches</h2>

      <TableContainer component={Paper} className={styles.containerTable}>
        <Table className={styles.theTable}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHead}>Batch Name</TableCell>
              <TableCell className={styles.tableHead}>Students Count</TableCell>
              <TableCell className={styles.tableHead}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {batches?.map((batch) => (
              <TableRow key={batch._id}>
                <TableCell className={styles.tableData}>{batch.batchName}</TableCell>
                <TableCell className={styles.tableData}>{batch.studentsData.length}</TableCell>
                <TableCell className={styles.tableData}>
                  <div className={styles.actionButtons}>
                    <Button>
                      <ModeEditIcon
                        className={styles.editIcon}
                        onClick={() => handleClickOpen(batch)}
                      >
                      </ModeEditIcon>
                    </Button>

                    <Button>
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={() => showConfirmation("Want to delete this Practical Batch?", () => handleDeleteBatch(batch._id))}
                      />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <Box className={styles.closeIconContainer}>
          <IconButton onClick={handleClose} className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
        <h2 className={styles.dialogTitle}>EDIT BATCH</h2>

        <DialogContent className={styles.dialogContainer}>
          <div className={styles.inputGroup}>
            <label htmlFor="batchName">Batch Name</label>
            <input
              autoFocus
              margin="dense"
              value={newBatchName}
              onChange={(e) => setNewBatchName(e.target.value)}
            />
          </div>

          <h3 className={styles.title}>Students in this Batch</h3>

          <TableContainer component={Paper} className={styles.dialogContainerTable}>
            <Table className={styles.theTable}>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHead}>First Name</TableCell>
                  <TableCell className={styles.tableHead}>Last Name</TableCell>
                  <TableCell className={styles.tableHead}>Enrollment ID</TableCell>
                  <TableCell className={styles.tableHead}>Primary Phone</TableCell>
                  <TableCell className={styles.tableHead}>Email</TableCell>
                  <TableCell className={styles.tableHead}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.enrollmentId}>
                    <TableCell className={styles.tableData}>{student.fname}</TableCell>
                    <TableCell className={styles.tableData}>{student.lname}</TableCell>
                    <TableCell className={styles.tableData}>{student.enrollmentId}</TableCell>
                    <TableCell className={styles.tableData}>{student.primaryPhone}</TableCell>
                    <TableCell className={styles.tableData}>{student.email}</TableCell>
                    <TableCell className={styles.tableData}>
                      <Button>
                        <DriveFileMoveIcon
                          className={styles.moveIcon}
                          onClick={() => handleMoveOpen(student)}>
                        </DriveFileMoveIcon>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            className={styles.updateBatchDialogButton}
            onClick={() => {
              if (currentBatch) {
                showConfirmation("update batch name ? ", handleUpdateBatch, {});
              }
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={moveDialogOpen}
        onClose={handleMoveClose}
        className={styles.moveStudentDialogContainer}
        maxWidth="xs"
        fullWidth
      >
        <h3 className={styles.titleMoveStudent}>Move Student</h3>

        <div className={styles.inputGroup}>
          <label htmlFor="batchName">Select Target Batch</label>
          <FormControl fullWidth>
            <Select
              value={targetBatchId}
              placeholder="Select"
              onChange={(e) => setTargetBatchId(e.target.value)}
            >
              {batches.map((batch) => (
                <MenuItem key={batch._id} value={batch._id}>
                  {batch.batchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <DialogActions>
          <div className={styles.confirmationButtonContainer}>
            <IconButton onClick={handleMoveClose} className={styles.confirmationButton} >
              <CloseIcon className={styles.noButton} />
            </IconButton>

            <IconButton onClick={() => {
              showConfirmation(
                `want to move ${selectedStudent.fname} ${selectedStudent.mname} ${selectedStudent.lname} to another Batch ?`,
                handleMoveStudent,
                {}
              );
            }} className={styles.confirmationButton} >
              <CheckIcon className={styles.yesButton} />
            </IconButton>
          </div>


        </DialogActions>
      </Dialog >
    </div >
  );
};

export default ViewPracticalBatches;
