import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import {
  Modal,
  Button,
  IconButton,
  Box,
  Grid,
  TableContainer,
  Paper,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./ListOfInstitute.module.css";
import { BASE_URL } from "./../../../../config/config";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RestoreIcon from "@mui/icons-material/Restore";
import LockIcon from '@mui/icons-material/Lock';
// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";

const getInstituteList = process.env.REACT_APP_SUPERADMIN_GET_INSTITUTE_LIST;
const updateInstitute = process.env.REACT_APP_SUPERADMIN_UPDATE_INSTITUTE;
const deleteInstitute = process.env.REACT_APP_SUPERADMIN_DELETE_INSTITUTE;
const recoverInstitute = process.env.REACT_APP_SUPERADMIN_RECOVER_INSTITUTE
const getAdminList = process.env.REACT_APP_SUPERADMIN_GET_ADMIN_LIST

const InstituteList = () => {
  const [institutes, setInstitutes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedInstitute, setEditedInstitute] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [instituteAdmins, setInstituteAdmins] = useState([]);
  // const [dropdownData, setDropdownData] = useState({
  //   instituteAdmins: []
  // });
  const navigate = useNavigate();

  const fetchInstitutes = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${getInstituteList}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setInstitutes(response.data.Institutedata);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const fetchAdmin = async (instituteId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}${getAdminList}?instituteId=${instituteId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          withCredentials: true
        }
      );
      // Set the fetched admin list to the state
      setInstituteAdmins(response?.data?.data.adminList || []);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong!");
    }
  };

  useEffect(() => {
    fetchInstitutes();
  }, []);

  useEffect(() => {
    if (editedInstitute.instituteId) {
      fetchAdmin(editedInstitute.instituteId); // Trigger fetching admins
    }
  }, [editedInstitute]);

  const openModal = (instituteData) => {
    setEditedInstitute({ ...instituteData }); // Ensure this sets the state correctly
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditedInstitute({});
  };

  const handleSave = async () => {
    try {
      const { _id, ...updatedData } = editedInstitute;
      // const token = Cookies.get("token");
      setShowLoader(true);

      const response = await axios.put(
        `${BASE_URL}${updateInstitute}`,
        {
          ...updatedData,
          instituteId: editedInstitute.instituteId,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success!");
        fetchInstitutes();
      }

      setModalIsOpen(false);
      setEditMode(false);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleDelete = async (instituteData) => {
    try {
      // const token = Cookies.get("token");
      setShowLoader(true);
      // return
      const data = { instituteId: instituteData?._id, isDeleted: true };

      const response = await axios.post(`${BASE_URL}${deleteInstitute}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success!");
        fetchInstitutes();
      }
      // setInstitutes(institutes.filter(institute => institute.instituteId !== id));
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };


  const validateForm = (updatedInstitute) => {
    const newErrors = {};

    // Validation checks (as you already have)
    if (!updatedInstitute.instituteCode || updatedInstitute.instituteCode.trim() === "") {
      newErrors.instituteCode = "**Institute Code is required**";
    }
    if (!updatedInstitute.instituteName || updatedInstitute.instituteName.trim() === "") {
      newErrors.instituteName = "**Institute Name is required**";
    }
    if (!updatedInstitute.emailId || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(updatedInstitute.emailId)) {
      newErrors.emailId = "**Invalid email format**";
    }
    if (!updatedInstitute.phoneNo || !/^\d{10}$/.test(updatedInstitute.phoneNo)) {
      newErrors.phoneNo = "**Phone number must be 10 digits**";
    }
    if (!updatedInstitute.pincode || !/^\d{6}$/.test(updatedInstitute.pincode) || updatedInstitute.pincode < 110000 || updatedInstitute.pincode > 855999) {
      newErrors.pincode = "**Pincode must be between 110000 and 855999**";
    }
    if (!updatedInstitute.latitude || updatedInstitute.latitude === "" || updatedInstitute.latitude < -90 || updatedInstitute.latitude > 90) {
      newErrors.latitude = "**Latitude must be between -90 and 90**";
    }
    if (!updatedInstitute.longitude || updatedInstitute.longitude === "" || updatedInstitute.longitude < -180 || updatedInstitute.longitude > 180) {
      newErrors.longitude = "**Longitude must be between -180 and 180**";
    }
    if (updatedInstitute.altitude === null || isNaN(updatedInstitute.altitude)) {
      newErrors.altitude = "**Altitude is required**";
    }
    if (!updatedInstitute.addressLine || updatedInstitute.addressLine.trim() === "") {
      newErrors.addressLine = "**Address Line is required**";
    }
    if (!updatedInstitute.instituteAdminId) {
      newErrors.adminName = "**Admin selection is required**";
    }
    if (!updatedInstitute.instituteType) {
      newErrors.instituteType = "**Institute Type is required**";
    }

    setErrors(newErrors);

    // Check if the form is valid
    const isFormValid = Object.values(newErrors).every((error) => !error);
    setIsSaveDisabled((prev) => !isFormValid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedInstitute((prevState) => {
      const updatedInstitute = { ...prevState, [name]: value };
      validateForm(updatedInstitute);
      return updatedInstitute;
    });
  };

  const handleRecover = async (instituteData) => {
    try {
      // const token = Cookies.get("token");
      setShowLoader(true);
      // return
      const data = { instituteId: instituteData?._id, isDeleted: true };

      const response = await axios.post(`${BASE_URL}${recoverInstitute}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success!");
        fetchInstitutes();
      }
      // setInstitutes(institutes.filter(institute => institute.instituteId !== id));
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  // const HandleViewInstitute = (id) => {
  //   navigate(`/ListOfInstitute/InstituteHomePage/${id}`);
  // };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.instituteHeader}>List of Institutes</h1>

      <TableContainer component={Paper} className={styles.containerTable}>
        <Table className={styles.theTable}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHead}>Serial No.</TableCell>
              <TableCell className={styles.tableHead}>Institute Type</TableCell>
              <TableCell className={styles.tableHead}>Institute Code</TableCell>
              <TableCell className={styles.tableHead}>Institute Name</TableCell>
              <TableCell className={styles.tableHead}>University Name</TableCell>
              <TableCell className={styles.tableHead}>Address Line</TableCell>
              <TableCell className={styles.tableHead}>City / Town</TableCell>
              <TableCell className={styles.tableHead}>District</TableCell>
              <TableCell className={styles.tableHead}>State / Province</TableCell>
              <TableCell className={styles.tableHead}>Pin / Zipcode</TableCell>
              <TableCell className={styles.tableHead}>Latitude</TableCell>
              <TableCell className={styles.tableHead}>Longitude</TableCell>
              <TableCell className={styles.tableHead}>Altitude</TableCell>
              <TableCell className={styles.tableHead}>Institute Phone</TableCell>
              <TableCell className={styles.tableHead}>Institute Email</TableCell>
              <TableCell className={styles.tableHead}>
                Institute Admin
              </TableCell>
              <TableCell className={styles.tableHead}>
                Admin Email
              </TableCell>
              <TableCell className={styles.tableHead}>
                Admin Phone
              </TableCell>
              <TableCell className={styles.tableHead}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {institutes && institutes.length > 0 ? (
              institutes.map((institute, index) => (
                <TableRow
                  key={institute?._id}
                  className={institute.isActive === false ? `${styles.deletedRow}` : ""}
                  style={institute.isActive === false ? { backgroundColor: "#AA0000", color: "white" } : {}}
                >
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {index + 1}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.instituteType ? institute?.instituteType : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.instituteCode ? institute?.instituteCode : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.instituteName ? institute?.instituteName : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.university ? institute?.university : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.addressLine ? institute?.addressLine : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.city ? institute?.city : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.district ? institute?.district : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.state ? institute?.state : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.pincode ? institute?.pincode : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.latitude ? institute?.latitude : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.longitude ? institute?.longitude : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.altitude ? institute?.altitude : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.phoneNo ? institute?.phoneNo : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.emailId ? institute?.emailId : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.adminName ? institute.adminName : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.adminEmail ? institute.adminEmail : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    {institute?.adminPhoneNo ? institute.adminPhoneNo : "N/A"}
                  </TableCell>
                  <TableCell className={styles.tableData} style={institute.isActive === false ? { color: "white" } : {}}>
                    <div className={styles.buttonContainer}>
                      {/* <Button
                      onClick={() => HandleViewInstitute(institute?.instituteId)}
                        style={institute.isActive === false ? { color: "white", borderColor: "white" } : {}}
                      >
                        <RemoveRedEyeOutlinedIcon/>
                      </Button> */}
                      <Button
                        onClick={() => openModal(institute)}
                        disabled={institute?.isActive === false}
                        style={institute.isActive === false ? { color: "white", borderColor: "white" } : {}}
                      >
                        <ModeEditIcon />
                      </Button>
                      {institute?.isActive ? (
                        <Button
                          style={institute.isActive === false ? { color: "white", borderColor: "white" } : {}}
                          onClick={() =>
                            showConfirmation(
                              "You want to lock this institute?",
                              () => handleDelete(institute)
                            )
                          }
                          disabled={institute.isDeleted === "true"}
                        >
                          <LockIcon />
                        </Button>
                      ) : (
                        <Button
                          style={institute.isActive === false ? { color: "white", borderColor: "white" } : {}}
                          onClick={() =>
                            showConfirmation(
                              "You want to recover this institute?",
                              () => handleRecover(institute)
                            )
                          }
                          disabled={institute.isDeleted === "true"}
                        >
                          <RestoreIcon />
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={11}>
                  <h2 className={styles.noData}>No Institutes Found</h2>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={modalIsOpen} onClose={closeModal} className={styles.modal}>
        <Box className={styles.editInstituteContainer}>
          <Box className={styles.closeIconContainer}>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <h2 className={styles.editInstituteHeader}>EDIT INSTITUTE</h2>
          <div className={styles.editInstituteScrollable}>
            <form className={styles.formGroup}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="instituteType">Institute Type</label>
                    <input
                      type="text"
                      id="instituteType"
                      name="instituteType"
                      required
                      placeholder="Institute Type"
                      value={editedInstitute.instituteType || ""}
                      onChange={handleChange}
                      className={styles.disabled}
                      disabled
                    />
                    {errors.instituteType && <p className={styles.error}>{errors.instituteType}</p>}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="university">University Name</label>
                    <input
                      type="text"
                      id="university"
                      name="university"
                      placeholder="University Name"
                      required
                      value={editedInstitute.university || ""}
                      onChange={handleChange}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.university && (
                      <p className={styles.error}>{errors.university}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="instituteCode">Institute Code</label>
                    <input
                      type="text"
                      id="instituteCode"
                      name="instituteCode"
                      required
                      placeholder="Institute Code"
                      value={editedInstitute.instituteCode || ""}
                      onChange={handleChange}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.instituteCode && <p className={styles.error}>{errors.instituteCode}</p>}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="instituteName">Institute Name</label>
                    <input
                      type="text"
                      id="instituteName"
                      name="instituteName"
                      placeholder="Institute Name"
                      required
                      value={editedInstitute.instituteName || ""}
                      onChange={handleChange}
                    />
                    {errors.instituteName && (
                      <p className={styles.error}>{errors.instituteName}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="addressLine">Address Line</label>
                    <input
                      type="text"
                      id="addressLine"
                      name="addressLine"
                      placeholder="Address Line"
                      required
                      value={editedInstitute.addressLine || ""}
                      onChange={handleChange}
                    />
                    {errors.addressLine && (
                      <p className={styles.error}>{errors.addressLine}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="city">City / Town</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder="City"
                      required
                      value={editedInstitute.city || ""}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.city && (
                      <p className={styles.error}>{errors.city}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="district">District</label>
                    <input
                      type="text"
                      id="district"
                      name="district"
                      placeholder="District"
                      required
                      value={editedInstitute.district || ""}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.district && (
                      <p className={styles.error}>{errors.district}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="state">State / Province</label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      placeholder="State"
                      required
                      value={editedInstitute.state || ""}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.state && (
                      <p className={styles.error}>{errors.state}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="pincode">Pincode / Zipcode</label>
                    <input
                      type="number"
                      id="pincode"
                      name="pincode"
                      placeholder="Pincode"
                      required
                      value={editedInstitute.pincode || ""}
                      onChange={handleChange}
                    />
                    {errors.pincode && (
                      <p className={styles.error}>{errors.pincode}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="latitude">Latitude <span className={styles.span}>(Degrees in number)</span></label>
                    <input
                      type="number"
                      id="latitude"
                      name="latitude"
                      placeholder="Latitude"
                      required
                      value={editedInstitute.latitude || ""}
                      onChange={handleChange}
                    />
                    {errors.latitude && (
                      <p className={styles.error}>{errors.latitude}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="longitude">Longitude <span className={styles.span}>(Degrees in number)</span></label>
                    <input
                      type="number"
                      id="longitude"
                      name="longitude"
                      placeholder="Longitude"
                      required
                      value={editedInstitute.longitude || ""}
                      onChange={handleChange}
                    />
                    {errors.longitude && (
                      <p className={styles.error}>{errors.longitude}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="altitude">Altitude <span className={styles.span}>(Metres in number)</span></label>
                    <input
                      type="number"
                      id="altitude"
                      name="altitude"
                      placeholder="Altitude"
                      required
                      value={editedInstitute.altitude || ""}
                      onChange={handleChange}
                    />
                    {errors.altitude && (
                      <p className={styles.error}>{errors.altitude}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="phoneNo">Phone Number</label>
                    <input
                      type="number"
                      id="phoneNo"
                      name="phoneNo"
                      placeholder="Institute Contact"
                      required
                      value={editedInstitute.phoneNo || ""}
                      onChange={handleChange}
                    />
                    {errors.phoneNo && (
                      <p className={styles.error}>{errors.phoneNo}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="emailId">Institute Email</label>
                    <input
                      type="email"
                      id="emailId"
                      name="emailId"
                      placeholder="Institute Email"
                      required
                      value={editedInstitute.emailId || ""}
                      onChange={handleChange}
                    />
                    {errors.emailId && (
                      <p className={styles.error}>{errors.emailId}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="adminName">Institute Admin</label>

                    <Dropdown
                      options={instituteAdmins.map(admin => ({
                        value: admin.value,   // The admin ID
                        label: admin.label,   // The admin name
                      }))}
                      value={editedInstitute.instituteAdminId || ""}
                      name="instituteAdminId"
                      onChange={handleChange}
                    />

                    {errors.adminName && <p className={styles.error}>{errors.adminName}</p>}
                  </div>
                </Grid>

                <Grid item xs={12} className={styles.submitButton}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSaveDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      showConfirmation(
                        " Want to edit this Institute ?",
                        handleSave,
                        e
                      );
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </div >
  );
};

export default InstituteList;
