import React, { useState, useContext, useRef } from "react";
import {
  Modal,
  Button,
  Typography,
  Card,
  CardMedia,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../ToastContainer/ToastContainer";
import styles from "./ProfilePhotoUpload.module.css";
import { BASE_URL } from "./../../../../config/config";
import defaultUserImage from "../../../../images/user (1).png";

const uploadProfilePicture = process.env.REACT_APP_UPLOAD_PROFILE_PICTURE;

function ProfilePhotoUpload() {
  const [dialogs, setDialogs] = useState(false);
  const UserImag = localStorage.getItem("profileImage");
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(
    "**Upload file size more than 1 kb and less than 300 Kb**"
  );
  const [isCameraMode, setIsCameraMode] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [cameraFacing, setCameraFacing] = useState("user");

  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);

  const validateFile = (file) => {
    if (file.size > 300 * 1024) {
      return "**File size exceeds. Please choose a file less than 300Kb**";
    }
    return "";
  };

  const handleChoosePhoto = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const error = validateFile(selectedFile);
      setFileError(error);
      setFile(error ? null : selectedFile);
    }
  };

  const createFormData = (file) => {
    const formData = new FormData();
    formData.append("photo", file);
    return formData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    try {
      const token = Cookies.get("token");
      const formData = createFormData(file);
      setShowLoader(true);

      const response = await axios.put(
        `${BASE_URL}${uploadProfilePicture}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result) {
        localStorage.setItem("profileImage", response?.data?.data);
        setDialogs(false);
        Toast.success(response?.data?.message || "Success!");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong!");
    }
  };

  const startCamera = () => {
    setIsCameraMode(true);
    setImageSrc(null); // Reset the previous captured image

    // Get all media devices
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        // Filter the devices to get only video input devices (cameras)
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        // Check if we have at least one camera
        if (videoDevices.length > 0) {
          const selectedDevice =
            videoDevices.find((device) => device.facing === cameraFacing) ||
            videoDevices[0];

          // Now get the stream using the selected device
          return navigator.mediaDevices.getUserMedia({
            video: { deviceId: selectedDevice.deviceId },
          });
        } else {
          Toast.error("No camera found");
          throw new Error("No camera found");
        }
      })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        Toast.error("Camera access denied or error occurred");
      });
  };

  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Get the video dimensions to preserve the aspect ratio
    const video = videoRef.current;
    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    // Set the canvas size to match the video aspect ratio
    const aspectRatio = videoWidth / videoHeight;
    const canvasWidth = 400; // You can adjust this based on your design
    const canvasHeight = canvasWidth / aspectRatio; // Adjust height based on the aspect ratio

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Draw video frame on canvas
    context.drawImage(video, 0, 0, canvasWidth, canvasHeight);

    // Get the image from the canvas
    const imageData = canvas.toDataURL("image/png");

    // Set the captured image as the file
    const byteString = atob(imageData.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([uintArray], { type: "image/png" });
    const file = new File([blob], "captured-photo.png", { type: "image/png" });

    const error = validateFile(file);
    setFileError(error);
    setFile(error ? null : file);

    // Stop camera stream after capturing
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());

    // Hide the video element and display the captured image
    setImageSrc(imageData);
    setIsCameraMode(false); // Disable camera mode after capturing
  };

  return (
    <>
      <Card
        sx={{
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          margin: "10px auto",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          image={
            UserImag ? `data:image/png;base64,${UserImag}` : defaultUserImage
          }
          alt="Profile Image"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          onClick={() => {
            setDialogs(true);
          }}
        />
      </Card>

      <div>
        <Modal open={dialogs} onClose={() => setDialogs(false)}>
          <div className={styles.modalBackground}>
            <div className={styles.modal}>
              <div className={styles.modalHeader}>
                <Box className={styles.closeIconContainer}>
                  <IconButton
                    onClick={() => setDialogs(false)}
                    className={styles.closeIcon}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <h2>Update Profile Picture</h2>
              </div>
              <div className={styles.modalButtonContainer}>
                <Button
                  className={styles.modalBtn}
                  variant="contained"
                  onClick={handleChoosePhoto}
                >
                  Choose Photo
                </Button>
                <input
                  accept="image/*"
                  id="btn-upload"
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <Button
                  className={styles.modalBtn}
                  variant="contained"
                  onClick={startCamera}
                  sx={{ marginTop: "10px" }}
                >
                  Take Picture
                </Button>

                {isCameraMode && (
                  <div style={{ margin: "1rem" }}>
                    <video
                      ref={videoRef}
                      width="100%"
                      height="auto"
                      style={{
                        borderRadius: "10px",
                        display: imageSrc ? "none" : "block",
                      }}
                    ></video>
                    <canvas
                      ref={canvasRef}
                      width="200"
                      height="300"
                      style={{ display: "none" }}
                    ></canvas>
                    <Button
                      className={styles.modalBtn}
                      variant="contained"
                      onClick={capturePhoto}
                      sx={{ marginTop: "10px" }}
                    >
                      Capture
                    </Button>
                  </div>
                )}

                {imageSrc && (
                  <div style={{ margin: "1rem" }}>
                    <img
                      src={imageSrc}
                      alt="Captured"
                      style={{
                        width: "200px",
                        height: "auto",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                )}

                {file && <Typography>{file.name}</Typography>}

                {fileError && <p className={styles.error}>{fileError}</p>}

                <Button
                  className={styles.modalBtn}
                  variant="contained"
                  onClick={(event) => {
                    event.preventDefault();
                    showConfirmation(
                      "Want to update Profile image ..?",
                      handleSubmit,
                      event
                    );
                  }}
                  sx={{ marginTop: "10px" }}
                  disabled={fileError !== ""}
                >
                  Update Image
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ProfilePhotoUpload;
