import React, { useContext, useEffect, useState } from "react";
import styles from "./AddBatch.module.css";
import Dropdown from "../../DropDown/DropDown";
import Toast from "../../ToastContainer/ToastContainer";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import { Checkbox, FormControlLabel, IconButton, Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BASE_URL } from "./../../../../config/config";

const addNewBatch = process.env.REACT_APP_ADMIN_ADD_NEW_BATCH;
const dropDownAddBatches = process.env.REACT_APP_DROPDOWN_INIT_ADD_BATCHES;

const AddBatch = () => {
  const { getToken, showConfirmation, setShowLoader } = useContext(UserContext);
  const [dropdownData, setDropdownData] = useState({
    courseList: [],
    departmentList: [],
  });
  const [courseList, setCourseList] = useState([]);
  const [allowDepartmentSelect, setAllowSelectDepartment] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [sections, setSections] = useState([{ sectionName: "" }]);
  const [formValues, setFormValues] = useState({
    startDate: null,
    endDate: null,
    batchName: "",
    batchCode: "",
    courseCode: "",
    departmentCode: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    validateForm();
  };

  const handleCheckBoxChange = (e) => {
    setChecked(e.target.checked);
  };

  const setDate = (value, name) => {
    setFormValues({
      ...formValues,
      [name]: value ? new Date(value) : null,
    });
    validateForm();
  };

  useEffect(() => {
    if (!checked) {
      setSections([{ sectionName: "" }]);
    }
  }, [checked]);

  const handleAddSectionField = () => {
    setSections([...sections, { sectionName: "" }]);
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "startDate",
      "endDate",
      "batchName",
      "courseCode",
      "departmentCode",
      "description",
    ];

    // requiredFields.forEach((field) => {
    //   const value = formValues[field];
    //   if (!(typeof value === "number")) {
    //     if (!value || value.trim() === "") {
    //       validationErrors[field] = "**This field is required**";
    //     }
    //   } else {
    //     if (!value) {
    //       validationErrors[field] = "**This field is required**";
    //     }
    //   }
    // });

    // requiredFields.forEach((field) => {
    //   const value = formValues[field];
    //   if (typeof value === "string" && !value.trim()) {
    //     validationErrors[field] = "**This field is required**";
    //   }
    // });

    requiredFields.forEach((field) => {
      const value = formValues[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    const startDateValue = formValues.startDate;
    if (!startDateValue) {
      validationErrors.startDate = "**This field is required.**";
    } else {
      const startDate = dayjs(startDateValue);
      if (!startDate.isValid()) {
        validationErrors.startDate = "**Invalid date format**";
      } else if (startDate.isAfter(dayjs(), "day")) {
        validationErrors.startDate = "**Date cannot be in the future**";
      }
    }

    const endDateValue = formValues.endDate;
    if (!endDateValue) {
      validationErrors.endDate = "**This field is required.**";
    } else {
      const endDate = dayjs(endDateValue);
      if (!endDate.isValid()) {
        validationErrors.endDate = "**Invalid date format**";
      } else if (endDate.isBefore(dayjs(startDateValue))) {
        validationErrors.endDate = "**End Date must be after Start Date.**";
      }
    }

    // Validation for batchName length
    const batchName = formValues.batchName;
    if (batchName && batchName.length > 30) {
      validationErrors.batchName = "**Batch Name must be less than 30 characters.**";
    }

    // Validation for description length
    const description = formValues.description;
    if (description && description.length > 70) {
      validationErrors.description = "**Description must be less than 70 characters.**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();

    try {
      formValues.sections = sections;
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${addNewBatch}`,
        formValues,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response.data.result) {
        Toast.success(response.data.message);
        delete formValues.sections
        setFormValues({
          startDate: null,
          endDate: null,
          batchName: "",
          batchCode: "",
          courseCode: "",
          departmentCode: "",
          description: "",
        });
        setSections([{ sectionName: "" }]);
        setChecked(false);
        setOpen(false);
      }
      setShowLoader(false);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");
      setShowLoader(false);
      setOpen(false);
    }
  };

  const fetchDropdownData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${dropDownAddBatches}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      setDropdownData(() => response.data.data);

      if (response.data?.data?.departmentCode) {


        setFormValues({
          ...formValues,
          ["departmentCode"]: response.data?.data?.departmentCode,
        });
        setAllowSelectDepartment(false);
      } else {
        setAllowSelectDepartment(true);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error("Something Went Wrong....");
    }
  };

  useEffect(() => {
    const selectedDepartment = dropdownData.departmentList?.find(
      (DATA) => DATA.value === formValues.departmentCode
    );

    if (selectedDepartment && selectedDepartment.courseList) {
      setCourseList(selectedDepartment.courseList);
      setFormValues({ ...formValues, courseCode: "" });
    } else {
      setCourseList([]);
    }
  }, [formValues.departmentCode, dropdownData]);

  useEffect(() => {
  }, [sections]);

  const handleSectionNameChange = (e) => {
    const { name, value } = e.target;
    const updatedSections = [...sections];
    updatedSections[parseInt(name, 10)].sectionName = value;
    setSections(updatedSections);
  };

  useEffect(() => {
    fetchDropdownData();
    validateForm();
  }, []);

  useEffect(() => {
    validateForm();
  }, [formValues]);

  return (
    <Box className={styles.addBatchContainer}>
      <Box className={styles.formContainer}>
        <Box className={styles.closeIconContainer}>
          <IconButton onClick={() => {
            navigate(-1)
          }}
            className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
        <h2 className={styles.formContainerTitle}>CREATE ACADEMIC YEAR BATCH</h2>
        <form className={styles.formSection} onSubmit={(e) => {
          e.preventDefault();
          showConfirmation("want to register new Academic year ? ", handleSubmit, e)
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {allowDepartmentSelect && (
                <div className={styles.inputGroup}>
                  <label htmlFor="departmentName">Department Name</label>
                  <Dropdown
                    id="departmentName"
                    name="departmentCode"
                    placeholder="Department Name"
                    value={formValues.departmentCode}
                    onChange={handleInputChange}
                    options={dropdownData.departmentList}
                  />
                  {errors.departmentCode && <p className={styles.error}>{errors.departmentCode}</p>}
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="courseName">Course Name</label>
                <Dropdown
                  id="courseName"
                  name="courseCode"
                  placeholder="Course Name"
                  value={formValues.courseCode}
                  onChange={handleInputChange}
                  options={courseList}
                />
                {errors.courseCode && <p className={styles.error}>{errors.courseCode}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={styles.inputGroup}>
                  <label htmlFor="startDate">Start Date</label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    className={styles.datePicker}
                    variant="filled"
                    value={formValues.startDate ? dayjs(formValues.startDate) : null}
                    onChange={(newValue) => {
                      setDate(newValue, "startDate");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={false}
                  />
                  {errors.startDate && <p className={styles.error}>{errors.startDate}</p>}
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={styles.inputGroup}>
                  <label htmlFor="endDate">End Date</label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    className={styles.datePicker}
                    variant="filled"
                    value={formValues.endDate ? dayjs(formValues.endDate) : null}
                    onChange={(newValue) => {
                      setDate(newValue, "endDate");
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={false}
                  />
                  {errors.endDate && <p className={styles.error}>{errors.endDate}</p>}
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="batchName">Batch Name</label>
                <input
                  type="text"
                  id="batchName"
                  name="batchName"
                  placeholder="Batch Name"
                  value={formValues.batchName}
                  onChange={handleInputChange}
                />
                {errors.batchName && <p className={styles.error}>{errors.batchName}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Description"
                  value={formValues.description}
                  onChange={handleInputChange}
                />
                {errors.description && <p className={styles.error}>{errors.description}</p>}
              </div>
            </Grid >

            <Grid item xs={12}>
              <div className={styles.sectionContainer}>
                {
                  sections?.map((DATA, index) => {
                    return (
                      <div key={index} className={styles.inputGroup}>
                        <label htmlFor={`section-${index}`}>
                          Section {index + 1}
                        </label>
                        <input
                          type="text"
                          name={index}
                          value={DATA.sectionName}
                          placeholder={`Name of Section ${index + 1}`}
                          required
                          onChange={handleSectionNameChange}
                        />
                      </div>
                    );
                  })
                }

                {checked && (
                  <Button variant="contained" className={styles.addMoreButton} onClick={handleAddSectionField}>
                    Add More Section
                  </Button>
                )}
              </div>
            </Grid>


            <Grid item xs={12}>
              <div className={styles.inputGroupCheckbox}>
                <FormControlLabel
                  label="Want to Create More Sections ? "
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckBoxChange}
                      color="primary"
                    />
                  }
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.buttonContainer}>
                <Button type="submit" className={styles.saveButton} variant="contained" disabled={isSaveDisabled}>
                  Save
                </Button>
              </div>
            </Grid >
          </Grid >

        </form >
      </Box >
    </Box >
  );
};

export default AddBatch;
