import React, { useContext, useEffect, useState } from "react";
import styles from "./ClassroomList.module.css";
import { Box, Button, Grid, IconButton, Modal, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import TableComponent from "../../CommonTable/CommonTable";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "./../../../../config/config";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import Dropdown from "../../DropDown/DropDown";

const dropdownClassroomRegister = process.env.REACT_APP_ADMIN_DROPDOWN_CLASSROOM;
const getClassrooms = process.env.REACT_APP_ADMIN_GET_CLASSROOMS;
const updateClassroom = process.env.REACT_APP_ADMIN_UPDATE_CLASSROOM;
const deleteClassroom = process.env.REACT_APP_ADMIN_DELETE_CLASSROOM;

const ClassroomList = () => {
    const { Cookies, getToken, setShowLoader, getUserRole, showConfirmation } =
        useContext(UserContext);
    const [formData, setFormData] = useState({
        roomNo: "",
        roomType: "",
        floorNo: "",
        latitude: "",
        longitude: "",
        altitude: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [classroomData, setClassroomData] = useState([]);
    const [dropDownData, setDropDownData] = useState({
        roomTypes: [],
    });

    const navigate = useNavigate();

    const columns = [
        "Room Type",
        "Room Number",
        "Floor Number",
        "Latitude",
        "Longitude",
        "Altitude",
    ];

    const handelAddClassroom = () => {
        navigate(`/${getUserRole()}/classrooms/add-classroom`);
    };

    useEffect(() => {
        validateForm();
    }, [formData]);

    const fetchClassroomData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getClassrooms}`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });
            if (response.data.result) {
                const transformedRows = response?.data?.data?.map((classroom) => ({
                    ...classroom,
                    "Room Type": classroom.roomType,
                    "Room Number": classroom.roomNo,
                    "Floor Number": classroom.floorNo,
                    "Latitude": classroom.latitude,
                    "Longitude": classroom.longitude,
                    "Altitude": classroom.altitude,
                }));
                setClassroomData(transformedRows);
            } else {
                Toast.error(response?.data?.message || "Something Went Wrong!");
            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something Went Wrong!");
        }
    };

    const fetchDropdown = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(
                `${BASE_URL}${dropdownClassroomRegister}`,
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );
            if (response.data.result) {
                setDropDownData(response.data.data);
            }
        } catch (error) {
            Toast.error("Failed to fetch room types.");
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        fetchClassroomData();
        fetchDropdown();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        validateForm();
    };

    const validateForm = () => {
        let validationErrors = {};
        const requiredFields = [
            "roomType",
            "roomNo",
            "floorNo",
            "latitude",
            "longitude",
            "altitude",
        ];

        requiredFields.forEach((field) => {
            const value = formData[field];
            // Check if value is a string before using .trim()
            if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
                validationErrors[field] = "**This field is required**";
            } else if (typeof value === "string" && value.trim() === "") {
                validationErrors[field] = "**This field is required**";
            }
        });

        if (formData.floorNo && (formData.floorNo < 1 || formData.floorNo > 10)) {
            validationErrors["floorNo"] = "**Floors must be between 1 and 10**";
        }

        const longitude = parseFloat(formData.longitude);
        const latitude = parseFloat(formData.latitude);

        if (longitude < -180 || longitude > 180) {
            validationErrors["longitude"] = "**Longitude must be between -180 and 180**";
        }

        if (latitude < -90 || latitude > 90) {
            validationErrors["latitude"] = "**Latitude must be between -90 and 90**";
        }

        setErrors(validationErrors);
        setIsSaveDisabled(Object.keys(validationErrors).length > 0);
    };

    const handleEdit = (row) => {
        setCurrentRow(row);
        setFormData({
            roomNo: row.roomNo,
            roomType: row.roomType,
            floorNo: row.floorNo,
            latitude: row.latitude,
            longitude: row.longitude,
            altitude: row.altitude,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsEditing(false);
        setFormData({
            roomType: "",
            roomNo: "",
            floorNo: "",
            latitude: "",
            longitude: "",
            altitude: "",
        });
        setErrors({});
    };

    const handleSave = async (e) => {
        e.preventDefault();
        validateForm();

        try {
            setShowLoader(true);

            const updatedFormData = {
                ...formData,
                roomId: currentRow?.roomId,
            };

            const response = await axios.put(
                `${BASE_URL}${updateClassroom}`,
                updatedFormData,
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );

            if (response.data.result) {
                Toast.success("Classroom updated successfully.");
                setOpen(false);
                fetchClassroomData();
            } else {
                Toast.error(response?.data?.message || "Failed to update classroom.");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };


    const handleDelete = async (roomData) => {
        try {
            setShowLoader(true);
            const response = await axios.delete(
                `${BASE_URL}${deleteClassroom}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                    data: { roomId: roomData.roomId },
                }
            );

            if (response.data.result) {
                Toast.success("Classroom deleted successfully.");
                fetchClassroomData();
            } else {
                Toast.error(response?.data?.message || "Failed to delete classroom.");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };


    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>Department Classrooms</h1>
            <Box className={styles.classroomParentContainer}>
                <Box className={styles.inputParentContainer}>
                    <Box className={styles.inputContainer}>
                        <label htmlFor="searchClassroom" className={styles.searchLabel}>
                            Search Classroom
                        </label>
                        <input
                            id="searchClassroom"
                            className={styles.searchClassroom}
                            variant="outlined"
                        />
                    </Box>

                    <Box className={styles.btnContainer}>
                        <Button
                            variant="contained"
                            className={styles.addCourseButton}
                            onClick={handelAddClassroom}
                        >
                            Add Classroom
                        </Button>
                    </Box>
                </Box>

                {classroomData && classroomData.length > 0 ? (
                    <TableComponent
                        columns={columns}
                        rows={classroomData}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                ) : (
                    <h2 className={styles.noData}>
                        No Classroom data available. You can add a new classroom.
                    </h2>
                )}

                <Modal className={styles.modal} open={open} onClose={handleClose}>
                    <Box className={styles.updateClassroomContainer}>
                        <Box className={styles.closeIconContainer}>
                            <IconButton>
                                <CloseIcon
                                    className={styles.closeButton}
                                    onClick={handleClose}
                                />
                            </IconButton>
                        </Box>

                        <h2 className={styles.updateClassroomHeader}>EDIT CLASSROOM</h2>
                        <div className={styles.updateClassroomScrollable}>
                            <form className={styles.formGroup}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="roomType">Room Type</label>
                                            <Dropdown
                                                id={"roomType"}
                                                value={formData.roomType}
                                                options={dropDownData.roomTypes}
                                                name={"roomType"}
                                                onChange={handleChange}
                                            />
                                            {errors.roomType && (
                                                <p className={styles.error}>{errors.roomType}</p>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="roomNo">Room Number</label>
                                            <input
                                                type="text"
                                                id="roomNo"
                                                placeholder="Room No."
                                                value={formData.roomNo}
                                                name="roomNo"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.roomNo && <p className={styles.error}>{errors.roomNo}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="floorNo">Floor Number</label>
                                            <input
                                                type="number"
                                                id="floorNo"
                                                placeholder="Floor No."
                                                value={formData.floorNo}
                                                name="floorNo"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.floorNo && <p className={styles.error}>{errors.floorNo}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="latitude">
                                                Latitude <span className={styles.span}>(Degrees in number)</span>
                                            </label>
                                            <input
                                                type="number"
                                                id="latitude"
                                                placeholder="Latitude"
                                                value={formData.latitude}
                                                name="latitude"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.latitude && <p className={styles.error}>{errors.latitude}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="longitude">
                                                Longitude <span className={styles.span}>(Degrees in number)</span>
                                            </label>
                                            <input
                                                type="number"
                                                id="longitude"
                                                placeholder="Longitude"
                                                value={formData.longitude}
                                                name="longitude"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.longitude && <p className={styles.error}>{errors.longitude}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="altitude">
                                                Altitude <span className={styles.span}>(Meters in number)</span>
                                            </label>
                                            <input
                                                type="number"
                                                id="altitude"
                                                placeholder="Altitude"
                                                value={formData.altitude}
                                                name="altitude"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.altitude && <p className={styles.error}>{errors.altitude}</p>}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={styles.submitButton}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                        disabled={isSaveDisabled}
                                    >
                                        Save
                                    </Button>
                                </Grid>

                            </form>
                        </div>
                    </Box>
                </Modal>
            </Box>
        </div>
    );
};

export default ClassroomList;
