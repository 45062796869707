import { useContext, useState } from "react";
import styles from "./Profile.module.css";
import {
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Card, Stack } from "@mui/material";
import ProfilePhotoUpload from "./ProfilePhotoUpload/ProfilePhotoUpload";
import { Routes, Route, Link } from "react-router-dom";
import UserContext from "../../../Context/UserContext";
import BasicDetail from "./ProfilePages/BasicDetails/BasicDetails";
import EducationalDetails from "./ProfilePages/EducationalDetails/EducationalDetails";
import WorkExperience from "./ProfilePages/WorkExperience/WorkExperience";
import Skills from "./ProfilePages/Skills/Skills";
import SettingsPage from "./ProfilePages/SettingsPage/SettingsPage";
import ResetPassword from "./ProfilePages/SettingsPage/ResetPassword/ResetPassword";
import Certifications from "./ProfilePages/Certifications/Certifications";
import SocialMedia from "./ProfilePages/SocialMedia/SocialMedia";

function Profile({ user }) {
  const { userData, getDecodedToken, getUserRole } = useContext(UserContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const UserImag = localStorage.getItem("profileImage");

  const currentUser = user;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Card className={styles.leftSideContainer}>
        <Card className={styles.photoContainer}>
          <Stack direction={"column"}>
            <ProfilePhotoUpload UserImag={UserImag} UserData={userData} />
            <Typography className={styles.nameCardHeader}>{`${
              getDecodedToken()?.fname
            } ${getDecodedToken()?.mname} ${
              getDecodedToken()?.lname
            }`}</Typography>
          </Stack>
        </Card>

        <div className={styles.leftSideContainerNavbar}>
          <div className={styles.navbarOption}>
            <div className={styles.optionContainer}>
              <Link to={`/${currentUser}/profile/`} className={styles.link}>
                <span className={styles.optionText}>Basic Details</span>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/profile/education`}
                className={styles.link}
              >
                <span className={styles.optionText}>Educational Details</span>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/profile/work-experience`}
                className={styles.link}
              >
                <span className={styles.optionText}>Experiential Details</span>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/profile/skills-hobbies`}
                className={styles.link}
              >
                <span className={styles.optionText}>
                  Skills, Languages & Hobbies
                </span>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/profile/certifications-achievements`}
                className={styles.link}
              >
                <span className={styles.optionText}>
                  Certifications & Achievements
                </span>
              </Link>
            </div>
            {/* <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/profile/social-media`}
                className={styles.link}
              >
                <span className={styles.optionText}>Social Media</span>
              </Link>
            </div> */}
            {/* <div className={styles.optionContainer}>
              <span
                className={styles.optionText}
                onClick={() => {
                  alert("Implement download resume here");
                }}
              >
                Download Resume
              </span>
            </div> */}
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/profile/settings`}
                className={styles.link}
              >
                <span className={styles.optionText}>Settings</span>
              </Link>
            </div>
          </div>
        </div>
      </Card>

      {/* Responsive burger icon for opening Navbar options for profile */}
      <IconButton onClick={handleDrawerToggle} aria-label="menu">
        <MenuIcon className={styles.burgerIcon} />
      </IconButton>

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        className={styles.drawer}
      >
        <List>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${getUserRole()}/profile/`}
          >
            <ListItemText
              primary="Basic Details"
              className={styles.drawerListItems}
            />
          </ListItem>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${getUserRole()}/profile/education`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Educational Details"
            />
          </ListItem>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${getUserRole()}/profile/work-experience`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Experiential Details"
            />
          </ListItem>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${getUserRole()}/profile/skills-hobbies`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Skills, Languages & Hobbies"
            />
          </ListItem>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${getUserRole()}/profile/certifications-achievements`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Certifications & Achievements"
            />
          </ListItem>
          {/* <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${getUserRole()}/profile/social-media`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Social Media"
            />
          </ListItem> */}
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${getUserRole()}/profile/settings`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Settings"
            />
          </ListItem>
          {/* <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${currentUser}/profile/education`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Download Resume"
            />
          </ListItem> */}
        </List>
      </Drawer>

      <div className={styles.rightSideContainer}>
        <Routes>
          <Route
            path="/"
            element={
              <BasicDetail currentUser={currentUser} userData={userData} />
            }
          ></Route>
          <Route
            path="/basicDetails"
            element={<BasicDetail userData={userData} />}
          />
          <Route path="/education" element={<EducationalDetails />}></Route>
          <Route path="/work-experience" element={<WorkExperience />}></Route>
          <Route path="/skills-hobbies" element={<Skills />}></Route>
          <Route
            path="/certifications-achievements"
            element={<Certifications />}
          ></Route>
          {/* <Route path="/social-media" element={<SocialMedia />}></Route> */}
          <Route path="/settings" element={<SettingsPage />}></Route>
          <Route
            path="/settings/reset-password"
            element={<ResetPassword />}
          ></Route>
        </Routes>
      </div>
    </>
  );
}

export default Profile;
