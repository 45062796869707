import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import styles from "./CommonTable.module.css";
import UserContext from "../../../Context/UserContext";

const CommonTable = ({
  title,
  columns,
  rows,
  onEdit,
  onView,
  onDelete,
  onRecover,
}) => {
  const { showConfirmation } = useContext(UserContext);

  return (
    <TableContainer component={Paper} className={styles.containerTable}>
      <Table className={styles.theTable}>
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell key={index} className={styles.tableHead}>
                {column?.toUpperCase()}
              </TableCell>
            ))}
            <TableCell className={styles.tableHead}>ACTIONS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, rowIndex) => {
            return (
              <TableRow
                key={rowIndex}
                sx={
                  row?.isActive
                    ? {}
                    : { backgroundColor: "#AA0000", color: "white" }
                }
              >
                {columns?.map((column, colIndex) => (
                  <TableCell
                    key={colIndex}
                    className={styles.tableData}
                    sx={row?.isActive ? {} : { color: "white" }}
                  >
                    {row[column]}
                  </TableCell>
                ))}
                <TableCell
                  className={styles.tableData}
                  sx={row?.isActive ? {} : { color: "white" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => onEdit(row)}>
                      <ModeEditIcon />
                    </Button>
                    {row.isActive ? (
                      <Button
                        onClick={() => {
                          showConfirmation(" want to Delete ?", onDelete, row);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          showConfirmation(
                            " want to Restore ?",
                            onRecover,
                            row
                          );
                        }}
                      >
                        <RestoreIcon />
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CommonTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRecover: PropTypes.func.isRequired,
};

export default CommonTable;
