import React, { useContext, useEffect, useState } from "react";
import styles from "./AddJob.module.css";
import dayjs from "dayjs";
import { Close } from "@mui/icons-material";
import { Button, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { BASE_URL } from "../../../../../config/config";
import UserContext from "../../../../../Context/UserContext";
import Dropdown from "../../../../CommonComponent/DropDown/DropDown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddJob = () => {
  const { getToken } = useContext(UserContext);
  const theme = useTheme();

  const [formData, setFormData] = useState({
    jobTitle: "",
    jobType: "",
    jobDescription: "",
    jobRole: "",
    keySkills: [],
    location: "",
    salary: 0,
    applicationDeadline: null,
    hiringInstitutes: [],
  });
  const [skills, setSkills] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [selectedInstitutes, setSelectedInstitutes] = useState([]);
  const [instituteList, setInstituteList] = useState([]);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [instituteDropdown, setInstituteDropdown] = useState([]);

  const navigate = useNavigate();
  const handleInstitueChange = async (e) => {
    const { name, value } = e.target;

    const data = instituteDropdown.filter(
      (data) => data.value === value[value.length - 1]
    );
    // setSelectedInstitutes([...selectedInstitutes,...data] )
    setSelectedInstitutes(value);
  };

  const removeInstitute = (value) => {
    setSelectedInstitutes(() =>
      selectedInstitutes.filter((item) => item !== value)
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSkillChange = (event) => {
    const {
      target: { value },
    } = event;
    setSkills(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const removeSkill = (value) => {
    setSkills(skills.filter((skill) => skill !== value));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      formData.hiringInstitutes = selectedInstitutes;
      formData.keySkills = skills;
      const response = await axios.post(`${BASE_URL}recruiter/postJob`, formData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data?.result) {
        Toast.success(response.data?.message || "Job Added Successfuly...!");
      } else {
        Toast.error(response.data?.message || "Something went wrong !");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");
    }
  };

  const fetchDropdown = async (req, res) => {
    try {
      const response = await axios.get(`${BASE_URL}dropdown/initPostJob`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        const data = response.data.data;
        setSkillList(data?.skillsList || []);
        setInstituteDropdown(data?.instituteList || []);
        setJobTypes(data?.jobTypes || []);
      }
    } catch (error) {}
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      const applicationDeadline = dayjs(newValue);
      setFormData({...formData,applicationDeadline: applicationDeadline ? dayjs(newValue) : null})

      // Check if the date is valid
      if (!applicationDeadline.isValid()) {
        // setErrors((prevErrors) => ({
        //   ...prevErrors,
        //   applicationDeadline: "**Invalid date format**",
        // }));
      }
      // Check if the date is in the future
      else if (applicationDeadline.isAfter(dayjs(), "day")) {
        // setErrors((prevErrors) => ({
        //   ...prevErrors,
        //   dob: "**Date of Birth cannot be in the future**",
        // }));
      }
      // If valid, update the state and clear errors
      else {
        setFormData({
          ...formData,
          applicationDeadline: applicationDeadline.toISOString(), // Store the date as an ISO string
        });
        // setErrors((prevErrors) => ({
        //   ...prevErrors,
        //   applicationDeadline: "", // Clear any existing errors
        // }));
      }
    } else {
      // Handle case when newValue is empty (e.g., user clears the input)
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   applicationDeadline: "**This field is required**",
      // }));
      setFormData({
        ...formData,
        applicationDeadline: "", // Reset the value if input is cleared
      });
    }
  };

  useEffect(() => {
    fetchDropdown();
  }, []);
  return (
    <div className={styles.addJobContainer}>
      {/* <IconButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <Close />
      </IconButton> */}
      <h1 className={styles.addJobtitle}>Add New Job</h1>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="jobType">Job Type</label>
            <Dropdown
              options={jobTypes}
              onChange={handleChange}
              value={formData.jobType}
              name="jobType"
            />
            {/* {errors.jobType && <p className={styles.error}>{errors.jobType}</p>} */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="instituteList">Intitutes List</label>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                margin: "15px 0px 15px 0px",
              }}
            >
              {selectedInstitutes.map((value, index) => (
                <div className={styles.tagContainer}>
                  <h3 className={styles.tagName}>
                    {
                      instituteDropdown.filter(
                        (data) => data.value === value
                      )[0].label
                    }
                  </h3>
                  <IconButton
                    className={styles.tagRemoveBtn}
                    onClick={() => removeInstitute(value)}
                  >
                    <Close />
                  </IconButton>
                </div>
              ))}
            </Box>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedInstitutes}
              onChange={handleInstitueChange}
              // input={
              //   <OutlinedInput id="select-multiple-chip" label="Chip" />
              // }
              // MenuProps={MenuProps}
            >
              {instituteDropdown.map((instituteData, index) => {
                if (
                  !selectedInstitutes.some(
                    (data) => data === instituteData.value
                  )
                ) {
                  return (
                    <MenuItem key={index} value={instituteData.value}>
                      {instituteData.label}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            {/* {errors.instituteList && <p className={styles.error}>{errors.instituteList}</p>} */}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="jobTitle">Job Title</label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              placeholder="Job Title"
              className={styles.inputbox}
            />
            {/* {errors.jobTitle && (
              <p className={styles.error}>{errors.jobTitle}</p>
            )} */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="jobRole">Job Role</label>
            <input
              type="text"
              id="jobRole"
              name="jobRole"
              value={formData.jobRole}
              onChange={handleChange}
              placeholder="Job Role"
              className={styles.inputbox}
            />
            {/* {errors.jobRole && (
              <p className={styles.error}>{errors.jobRole}</p>
            )} */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="jobDescription">Job Description</label>
            <textarea
              type="text"
              id="jobDescription"
              name="jobDescription"
              value={formData.jobDescription}
              onChange={handleChange}
              placeholder="Job Description"
              className={styles.jobDescriptionInput}
            />
            {/* {errors.jobDescription && (
              <p className={styles.error}>{errors.jobDescription}</p>
            )} */}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="skills">Key Skills</label>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                margin: "15px 0px 15px 0px",
              }}
            >
              {skills.map((value) => (
                <div className={styles.tagContainer}>
                  <h3 className={styles.tagName}>{value}</h3>
                  <IconButton
                    className={styles.tagRemoveBtn}
                    onClick={() => removeSkill(value)}
                  >
                    <Close />
                  </IconButton>
                </div>
              ))}
            </Box>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={skills}
              onChange={handleSkillChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              // MenuProps={MenuProps}
            >
              {skillList.map((name) => {
                if (!skills.includes(name)) {
                  return (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
            {/* {errors.skills && (
              <p className={styles.error}>{errors.skills}</p>
            )} */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="location">Job Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              placeholder="Job Location"
              className={styles.inputbox}
            />
            {/* {errors.location && (
              <p className={styles.error}>{errors.location}</p>
            )} */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.inputGroup}>
            <label htmlFor="salary">Salary / Stipend</label>
            <input
              type="text"
              id="salary"
              name="salary"
              value={formData.salary}
              onChange={handleChange}
              placeholder="Salary / Stipend"
              className={styles.inputbox}
            />
            {/* {errors.salary && (
              <p className={styles.error}>{errors.salary}</p>
            )} */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={styles.inputGroup}>
              <label htmlFor="applicationDeadline">Applicaion DeadLine</label>
              <DesktopDatePicker
                className={styles.datePicker}
                views={["year", "month", "day"]}
                inputFormat="MM/DD/YYYY"
                value={
                  formData.applicationDeadline
                    ? dayjs(formData.applicationDeadline)
                    : null
                }
                onChange={handleDateChange}
              />
              {/* {errors.applicationDeadline && <p className={styles.error}>{errors.applicationDeadline}</p>} */}
            </div>
          </LocalizationProvider>
        </Grid>
        <Button
          className={styles.submitButton}
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddJob;
