import React, { useContext, useEffect, useState } from "react";
import Dropdown from "../../DropDown/DropDown";
import Toast from "../../ToastContainer/ToastContainer";
import axios from "axios";
import { BASE_URL } from "../../../../config/config";
import UserContext from "../../../../Context/UserContext";
import { Button, Stack, Tab, Tabs, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./BulkAttendance.module.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const getDropdownBulkAttendance = process.env.REACT_APP_HOD_UPLOAD_BULK_ATTENDANCE_DROPDOWN;
const getTemplate = process.env.REACT_APP_HOD_ATTENDANCE_TEMPLATE;
const uploadBulkAttendance = process.env.REACT_APP_HOD_UPLOAD_BULK_ATTENDANCE;

const BulkAttendance = ({ selectedField }) => {
  const { getToken, setShowLoader, showLoader, showConfirmation } = useContext(UserContext);
  const [date, setDate] = useState(dayjs()); // Initialize with Day.js
  const [startTime, setStartTime] = useState(dayjs().hour(0).minute(0)); // Start time
  const [endTime, setEndTime] = useState(dayjs().hour(1).minute(0)); // End time
  const [dropdownSelected, setDropdownSelected] = useState({
    facultyId: '',
    roomId: '',
  });
  const [dropDownData, setDropDownData] = useState({
    roomsList: [],
    facultyList: []
  });
  const [dateList, setDateList] = useState([]);
  const [value, setValue] = useState(0);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDropdownSelected({ ...dropdownSelected, [name]: value });
  };

  const fetchDropdownData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${getDropdownBulkAttendance}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      if (response.data.result) {
        setDropDownData(response.data.data);
      } else {
        Toast.error(response?.data?.message || "Failed to fetch dropdown data");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  const getTemplateFile = async () => {
    try {
      setShowLoader(true);
      selectedField.dateList = dateList;

      const response = await axios.post(
        `${BASE_URL}${getTemplate}`,
        selectedField,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      const blob = new Blob([response.data]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${selectedField.subjectType}-Attendance_Template.xlsx`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      Toast.success(response.data.message || "Template Downloaded Successfully!");
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  const handleAddDate = () => {
    if (date && startTime && endTime) {
      setDateList((prev) => [
        ...prev,
        { date: date.format("YYYY-MM-DD"), startTime: startTime.format("HH:mm"), endTime: endTime.format("HH:mm") },
      ]);
      setDate(dayjs()); // Reset to current date
      setStartTime(dayjs().hour(0).minute(0)); // Reset start time
      setEndTime(dayjs().hour(1).minute(0)); // Reset end time
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      Toast.error("Please select a file to upload.");
      return;
    }

    let dataToSend = { ...selectedField, ...dropdownSelected, ['excelFile']: file }

    try {
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}${uploadBulkAttendance}`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result) {
        Toast.success(response.data.message || "File uploaded successfully!");
      } else {
        Toast.error(response.data.message || "File upload failed!");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    const { courseCode, subjectCode, subjectName, subjectType, semester, batchId, sectionId } = selectedField;

    if (!(courseCode && subjectCode && subjectName && subjectType && semester && batchId && sectionId)) {
      navigate("../");
    }
  }, [selectedField]);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  return (
    <Box className={styles.bulkAttendanceContainer}>
      <Stack spacing={2}>
        <h2 className={styles.bulkAttendanceTitle}>Bulk Attendance</h2>
        <div className={styles.tabs}>
          <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
            <Tab label="Download Template" />
            <Tab label="Upload Excel File" />
          </Tabs>
        </div>
        <Grid container spacing={2} className={styles.mainBox}>
          {value === 0 && (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="date">Date</label>
                    <DesktopDatePicker
                      views={["year", "month", "day"]}
                      value={date}
                      onChange={(newDate) => setDate(dayjs(newDate))}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="startTime">Start Time</label>
                    <input
                      type="time"
                      value={startTime.format("HH:mm")}
                      onChange={(e) => setStartTime(dayjs().hour(e.target.value.split(':')[0]).minute(e.target.value.split(':')[1]))}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="endTime">End Time</label>
                    <input
                      type="time"
                      value={endTime.format("HH:mm")}
                      onChange={(e) => setEndTime(dayjs().hour(e.target.value.split(':')[0]).minute(e.target.value.split(':')[1]))}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </div>
                </Grid>
              </LocalizationProvider>

              <Grid item xs={12}>
                <div className={styles.buttonContainer}>
                  <Button variant="contained" onClick={handleAddDate}>
                    Add New Date
                  </Button>
                </div>

                <div className={styles.createdTime}>
                  <h3 className={styles.createdTimeTitle}>Bulk Attendance slots for</h3>
                  <Stack spacing={1} className={styles.timeStack}>
                    {dateList.map((d, index) => (
                      <Box key={index} className={styles.individualBox}>
                        <h3 className={styles.data}>
                          {index + 1}. {d.date} - {d.startTime} to {d.endTime}
                        </h3>
                      </Box>
                    ))}
                  </Stack>
                </div>

                <div className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={getTemplateFile}
                    disabled={showLoader}
                  >
                    {showLoader ? "Loading..." : "Download Tempelate"}
                  </Button>
                </div>
              </Grid>
            </>
          )}

          {value === 1 && (
            <>
              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="faculty">Faculty</label>
                  <Dropdown
                    options={dropDownData.facultyList}
                    value={dropdownSelected.facultyId}
                    name="facultyId"
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="roomId">Room No</label>
                  <Dropdown
                    options={dropDownData.roomsList}
                    value={dropdownSelected.roomId}
                    name="roomId"
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="file">Choose File</label>
                  <input
                    type="file"
                    accept=".xlsx"
                    onChange={handleFileChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showConfirmation("Want to upload excel for bulk attendance upload?", handleFileUpload)}
                    disabled={showLoader}
                  >
                    {showLoader ? "Uploading..." : "Upload File"}
                  </Button>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
    </Box >
  );
};

export default BulkAttendance;
