import React, { useContext, useEffect, useState } from "react";
import styles from "./JobPost.module.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../../../config/config";
import UserContext from "../../../../../Context/UserContext";
const JobPost = () => {
  const {getToken} = useContext(UserContext);
  const [jobData, setJobData] = useState([]);
  const navigate = useNavigate();
  const getJobData = async() => {
    try {
      const response = await axios.get(`${BASE_URL}recruiter/myPostedJobs`,{headers:{Authorization:`Bearer ${getToken()}`}})
      setJobData(response?.data?.data || []);
      // setJobData( []);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getJobData()
  },[])
  return (
    <div className={styles.jobPostContainer}>
      <Button
        className={styles.postJobButton}
        variant="contained"
        onClick={() => {
          navigate("./add-job");
        }}
      >
        Post New Job
      </Button>
      {jobData.length > 0 ? (
        jobData.map((job, index) => {
          return (
            <div className={styles.postContainer}>
              <h1 className={styles.jobHeading}>
                {job?.jobTitle} - {job?.jobType}
              </h1>
              <h2>
                Application DeadLine :  {new Intl.DateTimeFormat("en-GB").format(
                  new Date(job?.applicationDeadline || null)
                )}
              </h2>
              <h2>
                Posted On : {new Intl.DateTimeFormat("en-GB").format(
                  new Date(job?.createdDate || null)
                )}
              </h2>
              <h2>{job.jobRole}</h2>

              {job?.jobDescription &&
                job?.jobDescription.split("\n").map((data, index) => {
                  return <p key={index}>{data}</p>;
                })}

              <div>
                {job.keySkills &&
                  job.keySkills.map((data, index) => {
                    return <p key={index}>{data}</p>;
                  })}
              </div>
              <p>Salary : {job.salary}</p>
            </div>
          );
        })
      ) : (
        <p>You Haven't Posted Any Job Yet !</p>
      )}
    </div>
  );
};

export default JobPost;
