import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './SettingsPage.module.css';
import settings from "../../../../../images/settings.png";
import UserContext from "../../../../../Context/UserContext";

const SettingsPage = ({ user }) => {
    const { getUserRole } = useContext(UserContext);

    return (
        <div className={styles.settingsContainer}>
            <h2 className={styles.headingSettings}>
                Settings{" "}
                <img
                    src={settings}
                    alt="Settings Icon"
                    className={styles.icon}
                />
            </h2>
            <div className={styles.settingsListContainer}>
                <h2>Manage Your Settings</h2>
                <hr className={styles.horizontalRow} />
                <ul className={styles.navList}>
                    <li className={styles.navItem}>
                        <Link
                            to={`/${getUserRole()}/profile/settings/reset-password`}
                        >Reset Password
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SettingsPage;
