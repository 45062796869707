import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./AddInstitute.module.css";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";
import { BASE_URL } from "./../../../../config/config";
import { Button, Grid } from "@mui/material";

const dropdownCountryName = process.env.REACT_APP_DROPDOWN_GET_COUNTRY_NAMES;
const dropdownStateName = process.env.REACT_APP_DROPDOWN_GET_STATE_NAMES;
const dropdownDistrictName = process.env.REACT_APP_DROPDOWN_GET_DISTRICT_NAMES;
const registerInstitute = process.env.REACT_APP_SUPERADMIN_REGISTER_INSTITUTE
const dropdownRegisterInstitute = process.env.REACT_APP_SUPERADMIN_DROPDOWN_REGISTER_INSTITUTE

const InstituteName = () => {
  const [formData, setFormData] = useState({
    instituteType: "",
    instituteCode: "",
    instituteName: "",
    university: "",
    country: "India",
    state: "",
    district: "",
    city: "",
    addressLine: "",
    pincode: "",
    phoneNo: "",
    emailId: "",
    longitude: "",
    latitude: "",
    altitude: "",
  });
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    instituteTypes: []
  });
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const fetchDropdownData = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${dropdownCountryName}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      setCountries(() => response?.data?.data);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const fetchDropdownData2 = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`${BASE_URL}${dropdownRegisterInstitute}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response?.data?.result) {
        setDropdownData(response?.data?.data);
      } else {
        Toast.error(response?.data?.message || "Failed to fetch dropdown data");
      }

    } catch (error) {
      Toast.error(error.response?.data?.message || "Failed to fetch dropdown data");
    } finally {
      setShowLoader(false)
    }
  }

  const fetchStates = async (country) => {
    try {
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${dropdownStateName}?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setStates(response?.data?.data);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const fetchDistricts = async (country, state) => {
    try {
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${dropdownDistrictName}?country=${country}&state=${state}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setDistricts(() => response.data.data);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    validateForm();
  }, [formData])

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "instituteType",
      "instituteCode",
      "instituteName",
      "university",
      "country",
      "state",
      "district",
      "city",
      "addressLine",
      "pincode",
      "phoneNo",
      "emailId",
      "longitude",
      "latitude",
      "altitude",
    ];

    // Check for required fields
    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    // Validate character limits
    if (formData.instituteCode && formData.instituteCode.length > 20) {
      validationErrors["instituteCode"] = "**Institute Code must be less than 20 characters**";
    }

    if (formData.instituteName && formData.instituteName.length > 60) {
      validationErrors["instituteName"] = "**Institute Name must be less than 60 characters**";
    }

    if (formData.university && formData.university.length > 60) {
      validationErrors["university"] = "**University must be less than 60 characters**";
    }


    if (formData.city && formData.city.length > 30) {
      validationErrors["city"] = "**City must be less than 30 characters**";
    }

    if (formData.addressLine && formData.addressLine.length > 100) {
      validationErrors["addressLine"] = "**Address Line must be less than 100 characters**";
    }

    // Validate phone number format
    const phoneNo = formData["phoneNo"];
    if (phoneNo && !/^\d{10}$/.test(phoneNo)) {
      validationErrors["phoneNo"] = "**Phone number must be 10 digits**";
    }

    // Validate pincode
    if (formData.pincode === "") {
      validationErrors["pincode"] = "**This field is required**";
    } else if (
      formData.pincode &&
      (formData.pincode < 110000 || formData.pincode > 855999)
    ) {
      validationErrors["pincode"] = "**Pincode must be between 110000 and 855999**";
    }

    // Validate email format
    if (formData.emailId && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailId)) {
      validationErrors["emailId"] = "**Please enter a valid email address**";
    }

    const longitude = parseFloat(formData.longitude);
    const latitude = parseFloat(formData.latitude);

    if (longitude < -180 || longitude > 180) {
      validationErrors["longitude"] = "**Longitude must be between -180 and 180**";
    }

    if (latitude < -90 || latitude > 90) {
      validationErrors["latitude"] = "**Latitude must be between -90 and 90**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    try {
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${registerInstitute}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      Toast.success("Institute registered successfully!");
      setFormData({
        instituteType: "",
        instituteCode: "",
        instituteName: "",
        university: "",
        country: "",
        state: "",
        district: "",
        city: "",
        addressLine: "",
        pincode: "",
        phoneNo: "",
        emailId: "",
        longitude: "",
        latitude: "",
        altitude: "",
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (formData.country) {
      fetchStates(formData.country);
    }
  }, [formData.country])


  useEffect(() => {
    if (formData.state) {
      fetchDistricts(formData.country, formData.state);
    }
  }, [formData.state])

  useEffect(() => {
    fetchDropdownData();
    fetchDropdownData2();
  }, [])

  return (
    <div className={styles.wrapper}>
      {/* <h2 className={styles.header}>Add Institute</h2> */}

      <div className={styles.registerInstituteContainer}>
        <h2 className={styles.registerInstituteContainerHeader}>Register Institute</h2>
        <div className={styles.registerFormScrollable}>

          <form onSubmit={(event) => {
            event.preventDefault();
            showConfirmation("Want to register Institute?", handleSubmit, event);
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="instituteType">Institute Type</label>
                  <Dropdown options={dropdownData?.instituteTypes} value={formData.instituteType} name={'instituteType'} onChange={handleChange} />
                  {errors.instituteType && <p className={styles.error}>{errors.instituteType}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="instituteCode">Institute Code</label>
                  <input
                    type="text"
                    id="instituteCode"
                    name="instituteCode"
                    value={formData.instituteCode}
                    onChange={handleChange}
                    placeholder="Institute Code"
                  />
                  {errors.instituteCode && <p className={styles.error}>{errors.instituteCode}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="instituteName">Institute Name</label>
                  <input
                    type="text"
                    id="instituteName"
                    name="instituteName"
                    value={formData.instituteName}
                    onChange={handleChange}
                    placeholder="Institute Name"
                  />
                  {errors.instituteName && <p className={styles.error}>{errors.instituteName}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="university">University</label>
                  <input
                    type="text"
                    id="university"
                    name="university"
                    value={formData.university}
                    onChange={handleChange}
                    placeholder="University Name"
                  />
                  {errors.university && <p className={styles.error}>{errors.university}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="country">Country</label>
                  <Dropdown options={countries} value={formData.country} name={'country'} onChange={handleChange} />
                  {errors.country && <p className={styles.error}>{errors.country}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="state">State / Province</label>
                  <Dropdown options={states} value={formData.state} name={'state'} onChange={handleChange} />
                  {errors.state && <p className={styles.error}>{errors.state}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="district">District</label>
                  <Dropdown options={districts} value={formData.district} name={'district'} onChange={handleChange} />
                  {errors.district && <p className={styles.error}>{errors.district}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="city">City / Town</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="City"
                  />
                  {errors.city && <p className={styles.error}>{errors.city}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="pincode">Pincode / Zipcode</label>
                  <input
                    type="number"
                    id="pincode"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    placeholder="Pincode"
                  />
                  {errors.pincode && <p className={styles.error}>{errors.pincode}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="addressLine">Address Line</label>
                  <input
                    type="text"
                    id="addressLine"
                    name="addressLine"
                    value={formData.addressLine}
                    onChange={handleChange}
                    placeholder="Address Line"
                  />
                  {errors.addressLine && <p className={styles.error}>{errors.addressLine}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="phoneNo">Primary Phone / Telephone</label>
                  <input
                    type="number"
                    id="phoneNo"
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleChange}
                    placeholder="Phone Number"
                  />
                  {errors.phoneNo && <p className={styles.error}>{errors.phoneNo}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="emailId">Email</label>
                  <input
                    type="email"
                    id="emailId"
                    name="emailId"
                    value={formData.emailId}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  {errors.emailId && <p className={styles.error}>{errors.emailId}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="latitude">Latitude <span className={styles.span}>(Degrees in number)</span></label>
                  <input
                    type="number"
                    id="latitude"
                    name="latitude"
                    value={formData.latitude}
                    onChange={handleChange}
                    placeholder="Latitude"
                  />
                  {errors.latitude && <p className={styles.error}>{errors.latitude}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="longitude">Longitude <span className={styles.span}>(Degrees in number)</span></label>
                  <input
                    type="number"
                    id="longitude"
                    name="longitude"
                    value={formData.longitude}
                    onChange={handleChange}
                    placeholder="Longitude"
                  />
                  {errors.longitude && <p className={styles.error}>{errors.longitude}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.registerInputGroup}>
                  <label htmlFor="altitude">Altitude <span className={styles.span}>(Metres in number)</span></label>
                  <input
                    type="number"
                    id="altitude"
                    name="altitude"
                    value={formData.altitude}
                    onChange={handleChange}
                    placeholder="Altitude"
                  />
                  {errors.altitude && <p className={styles.error}>{errors.altitude}</p>}
                </div>
              </Grid>

              <Grid item xs={12} className={styles.submitButtonContainer}>
                <Button
                  disabled={isSaveDisabled}
                  variant="contained" type="submit" className={styles.submitButton}>
                  Save
                </Button>
              </Grid>

            </Grid>
          </form>
        </div>
      </div>
    </div >
  );
};

export default InstituteName;
