import React, { useContext, useState, useEffect } from "react";
import styles from "./BasicDetails.module.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Card, Stack, Modal, FormControlLabel, Switch } from "@mui/material";
import ProfilePhotoUpload from "../../ProfilePhotoUpload/ProfilePhotoUpload";
import EditAddress from "./EditAddress/EditAddress";
import UserContext from "../../../../../Context/UserContext";
import axios from "axios";
import LockIcon from "@mui/icons-material/Lock";
import Toast from "../../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../config/config";
import idCard from "../../../../../images/id-card (1).png";
import userName from "../../../../../images/user (3).png";
import gender from "../../../../../images/gender.png";
import birthday from "../../../../../images/birthday.png";
import email from "../../../../../images/email.png";
import phone from "../../../../../images/phone.png";
import institute from "../../../../../images/institute.png";
import branch from "../../../../../images/hierarchy.png";
import usertype from "../../../../../images/who.png";
import rollnumber from "../../../../../images/id.png";
import aadhar from "../../../../../images/id-card (2).png";
import father from "../../../../../images/dad.png";
import mother from "../../../../../images/mother.png";
import parentOccupation from "../../../../../images/businessman.png";
import location from "../../../../../images/location.png";

const profileData = process.env.REACT_APP_PROFILE;
const updatePermanentAddress = process.env.REACT_APP_UPDATE_PERMANENT_ADDRESS;
const updateCurrentAddress = process.env.REACT_APP_UPDATE_CURRENT_ADDRESS;
const togglePrivate = process.env.REACT_APP_MAKE_NUMBER_PRIVATE;

const BasicDetails = ({ currentUser }) => {
  const { getToken, setShowLoader } = useContext(UserContext);
  const [BasicData, setBasicData] = useState({});
  const [isPrivate, setIsPrivate] = useState(false);

  const UserImage = BasicData?.image;

  // hide edit button for student
  const isStudent = currentUser === "student";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [permanentAddress, setPermanentAddress] = useState({
    addressLine: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [currentAddress, setCurrentAddress] = useState({
    addressLine: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [currentAddressType, setCurrentAddressType] = useState(null);

  const fetchData = async () => {
    try {
      const headers = {
        headers: { Authorization: `Bearer ${getToken()}` },
      };
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${profileData}`, headers);
      setBasicData(response?.data.profileData);
      setIsPrivate(response?.data.profileData.isPrivate);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (BasicData?.permanentAddress) {
      setPermanentAddress(BasicData.permanentAddress);
    }
    if (BasicData?.currentAddress) {
      setCurrentAddress(BasicData.currentAddress);
    }
  }, [BasicData]);

  const handleOpenModal = (addressType) => {
    setIsModalOpen(true);
    setCurrentAddressType(addressType);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveAddress = async ({
    updatedPermanentAddress,
    updatedCurrentAddress,
  }) => {
    try {
      const updatedData = {};
      setShowLoader(true);

      if (updatedPermanentAddress && currentAddressType === "permanent") {
        const permanentResponse = await axios.put(
          `${BASE_URL}${updatePermanentAddress}`,
          updatedPermanentAddress,
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        Toast.success(
          permanentResponse?.data?.message || "Address Updated Successfully !"
        );

        updatedData.permanentAddress = permanentResponse.data.address;
      }
      if (updatedCurrentAddress && currentAddressType === "current") {
        const currentResponse = await axios.put(
          `${BASE_URL}${updateCurrentAddress}`,
          updatedCurrentAddress,
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        Toast.success(
          currentResponse?.data?.message || "Address Updated Successfully !"
        );

        updatedData.currentAddress = currentResponse.data.address;
      }

      if (updatedData.permanentAddress) {
        setPermanentAddress(updatedData.permanentAddress);
        BasicData.permanentAddress = permanentAddress;
      }
      if (updatedData.currentAddress) {
        setCurrentAddress(updatedData.currentAddress);
        BasicData.currentAddress = currentAddress;
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }

    handleCloseModal();
  };

  const handleTogglePrivate = async () => {
    const newPrivacyStatus = !isPrivate;

    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
      };
      setShowLoader(true);

      const response = await axios.put(
        `${BASE_URL}${togglePrivate}`,
        { isPrivate: newPrivacyStatus }, // Set the privacy status
        headers
      );

      setIsPrivate(newPrivacyStatus);
      Toast.success(
        response?.data?.message || "Privacy status updated successfully!"
      );
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <Card className={styles.imgUploader}>
        <Stack direction={styles.column}>
          <ProfilePhotoUpload
            className={styles.profilePhotoUpload}
            UserImage={UserImage}
          />
          <h3>{`${BasicData?.fname} ${BasicData?.mname} ${BasicData?.lname}`}</h3>
        </Stack>
      </Card>

      <div className={styles.forToggleStyling}>
        <h2 className={styles.headerPersonalDetails}>
          Personal Details{" "}
          <img
            src={idCard}
            alt="Personal Details Icon"
            className={styles.icon}
          />
        </h2>

        {isStudent && (
          <span className={styles.toggleSwitchContainer}>
            <FormControlLabel
              className={styles.toggleSwitch}
              label={
                <label
                  sx={{
                    fontWeight: "bold",
                    color: "#494949",
                    fontSize: "1rem",
                  }}
                >
                  Make phone/address Private
                </label>
              }
              labelPlacement="start"
              control={
                <Switch checked={isPrivate} onChange={handleTogglePrivate} />
              }
            />
          </span>
        )}
      </div>
      <div className={styles.aboutContainer}>
        <div className={styles.aboutHeader}>
          <h3>About</h3>
          {/* {!isStudent && (
            <button className={styles.editButton}>
              <span className={styles.editIcon}>
                <ModeEditIcon sx={{ fontSize: "1rem" }} />
              </span>
              Edit
            </button>
          )} */}
        </div>
        <hr className={styles.horizontalRow} />
        <div className={styles.personalDetailsContent}>
          <p>
            <img src={userName} alt="Name Icon" className={styles.aboutIcon} />{" "}
            <span>Name: </span>{" "}
            {`${BasicData?.fname} ${BasicData?.mname} ${BasicData?.lname}`}
          </p>
          <p>
            <img src={gender} alt="Gender Icon" className={styles.aboutIcon} />{" "}
            <span>Gender: </span>{" "}
            {`${BasicData?.gender ? BasicData.gender : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={birthday}
              alt="Birthday Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Date of Birth: </span>{" "}
            {`${
              BasicData?.dob
                ? new Date(BasicData?.dob).toLocaleDateString()
                : "N/A"
            }`}
          </p>
          <p>
            <img src={email} alt="Email Icon" className={styles.aboutIcon} />{" "}
            <span>Email: </span>{" "}
            {`${BasicData?.email ? BasicData.email : "N/A"}`}
          </p>
          <p>
            <img src={phone} alt="Phone Icon" className={styles.aboutIcon} />{" "}
            <span>Primary Phone: </span>{" "}
            {`${BasicData?.primaryPhone ? BasicData.primaryPhone : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={phone}
              alt="Telephone Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Secondary Phone: </span>{" "}
            {`${BasicData?.secondaryPhone ? BasicData.secondaryPhone : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={institute}
              alt="Institute Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Institute Name: </span>{" "}
            {`${BasicData?.instituteName ? BasicData.instituteName : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={branch}
              alt="Department Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Department: </span>{" "}
            {`${BasicData?.departmentName ? BasicData.departmentName : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={usertype}
              alt="Usertype Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>User Type: </span>{" "}
            {`${currentUser ? currentUser.toUpperCase() : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={rollnumber}
              alt="Id Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>User Enroll / Faculty ID: </span>{" "}
            {`${BasicData?.enrollmentId ? BasicData.enrollmentId : "N/A"}`}
          </p>
          <p>
            <img src={aadhar} alt="Aadhar Icon" className={styles.aboutIcon} />{" "}
            <span>User Aadhar: </span>{" "}
            {`${BasicData?.aadharNo ? BasicData.aadharNo : "N/A"}`}
          </p>
          {isStudent && (
            <>
              <p>
                <img
                  src={father}
                  alt="Father Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Father's / Guardian's Name: </span>{" "}
                {`${BasicData?.fatherName ? BasicData.fatherName : "N/A"}`}
              </p>
              <p>
                {" "}
                <img
                  src={mother}
                  alt="Mother Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Mother's / Guardian's Name: </span>{" "}
                {`${BasicData?.motherName ? BasicData.motherName : "N/A"}`}
              </p>
              <p>
                {" "}
                <img
                  src={phone}
                  alt="ParentPhone Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Parent / Guardian's Phone: </span>{" "}
                {`${BasicData?.parentPhone ? BasicData.parentPhone : "N/A"}`}
              </p>
              <p>
                {" "}
                <img
                  src={parentOccupation}
                  alt="ParentOccupation Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Parent / Guardian Occupation: </span>{" "}
                {`${
                  BasicData?.parentOccupation
                    ? BasicData.parentOccupation
                    : "N/A"
                }`}
              </p>
            </>
          )}
        </div>
      </div>

      <h2 className={styles.headerAddress}>
        Address{" "}
        <img src={location} alt="Address Icon" className={styles.icon} />
      </h2>
      <div className={styles.addressContainer}>
        <div className={styles.permanentHeader}>
          <h3>Permanent</h3>
          {BasicData?.isLocked ? (
            <span className="lockIcon">
              <LockIcon sx={{ marginRight: "40px", fontSize: "1.6rem" }} />
            </span>
          ) : (
            <button
              className={styles.editButton}
              onClick={() => handleOpenModal("permanent")}
            >
              <span className={styles.editIcon}>
                <ModeEditIcon sx={{ fontSize: "1rem" }} />
              </span>
              Edit
            </button>
          )}
        </div>
        <hr className={styles.horizontalRow} />
        <div className={styles.personalDetailsContent}>
          <p>
            {`${permanentAddress.addressLine}   ${permanentAddress.city}  ${permanentAddress.district}  ${permanentAddress.state}  ${permanentAddress.country}  ${permanentAddress.pincode}`}
          </p>
        </div>

        <div className={styles.currentHeader}>
          <h3>Current</h3>
          <button
            className={styles.editButton}
            onClick={() => handleOpenModal("current")}
          >
            <span className="editIcon">
              <ModeEditIcon sx={{ fontSize: "1rem" }} />
            </span>
            Edit
          </button>
        </div>
        <hr className={styles.horizontalRow} />
        <div className={styles.personalDetailsContent}>
          <p>
            {`${currentAddress.addressLine}  ${currentAddress.city}  ${currentAddress.district}  ${currentAddress.state}  ${currentAddress.country}  ${currentAddress.pincode}`}
          </p>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        className={styles.modal}
      >
        <EditAddress
          handleCloseModal={handleCloseModal}
          handleSaveAddress={handleSaveAddress}
          permanentAddress={permanentAddress}
          currentAddress={currentAddress}
          addressType={currentAddressType}
        />
      </Modal>
    </>
  );
};

export default BasicDetails;
