import React from 'react';
import styles from "./NotFound.module.css";
import Img from '../../../images/404 Error Page not Found with people connecting a plug (1).gif';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.errorHeading}>404 Error!</h1>
      <div className={styles.imageContainer}>
        <img src={Img} className={styles.imageLogin} alt='page not found' />
      </div>
    </div>
  );
}

export default NotFound;
