import React from 'react';
import { Box } from '@mui/material';
import Logo from "../../../images/HOSTEDMINDS-nobg.png";
import styles from "./Loader.module.css";

const Loader = () => {
    return (
        <Box className={styles.loaderBox}>
            <img
                src={Logo}
                alt="Loading..."
                className={styles.loader}
            />
        </Box>
    );
};

export default Loader;
