import React, { useState, useEffect } from "react";
import { Button, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./VUPEditAchievements.module.css";

const VUPEditAchievements = ({ achievementData, handleAddAchievement, handleCloseModal }) => {
    const [achievement, setAchievement] = useState("");
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");
    const [error, setError] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        if (achievementData) {
            setAchievement(achievementData.achievement || "");
            setDescription(achievementData.description || "");
            setLink(achievementData.link || "");
        }
    }, [achievementData]);

    useEffect(() => {
        validateForm();
    }, [achievement, description, link]);

    const handleAchievementChange = (e) => {
        setAchievement(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const validateForm = () => {
        let validationErrors = {};
        let isValid = true;

        if (!achievement) {
            validationErrors.achievement = "**This field is required**";
            isValid = false;
        } else if (achievement.length > 50) {
            validationErrors.achievement = "**Achievement must be at most 50 characters**";
            isValid = false;
        }

        if (!description) {
            validationErrors.description = "**This field is required**";
            isValid = false;
        } else if (description.length > 120) {
            validationErrors.description = "**Description must be at most 120 characters**";
            isValid = false;
        }

        const isValidUrl = (url) => {
            const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
            return urlPattern.test(url);
        };

        if (link && !isValidUrl(link)) {
            validationErrors.link = "**Please enter a valid URL**";
            isValid = false;
        }

        setError(validationErrors);
        setIsSaveDisabled(!isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateForm();

        if (isSaveDisabled) {
            return;
        }

        const formattedAchievement = {
            achievement: achievement || "",
            description: description || "",
            link: link || "",
        };

        handleAddAchievement(formattedAchievement);
    };

    return (
        <div className={styles.editAchievementContainer}>
            <Box className={styles.closeIconContainer}>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon className={styles.deleteAchievement} />
                </IconButton>
            </Box>
            <h2 className={styles.editAchievementHeader}>Add Achievement</h2>
            <form onSubmit={handleSubmit} className={styles.editAchievementScrollable}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="achievement">Achievement</label>
                            <input
                                id="achievement"
                                type="text"
                                value={achievement}
                                onChange={handleAchievementChange}
                                maxLength="200"
                            />
                            {error.achievement && <p className={styles.error}>{error.achievement}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="description">Description</label>
                            <input
                                id="description"
                                type="text"
                                value={description}
                                onChange={handleDescriptionChange}
                                maxLength="200"
                            />
                            {error.description && <p className={styles.error}>{error.description}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="link">Link <span className={styles.span}> (Optional)</span></label>
                            <input
                                id="link"
                                type="text"
                                value={link}
                                onChange={handleLinkChange}
                                maxLength="200"
                            />
                            {error.link && <p className={styles.error}>{error.link}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={styles.submitButtonEditAchievement}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default VUPEditAchievements;
