import React, { useState } from "react";
import styles from "./Job.module.css";
import { Button, Modal } from "@mui/material";
import JobPost from "./JobPost/JobPost";
import AddJob from "./AddJob/AddJob";
import { Routes, Route } from "react-router-dom";
const Job = () => {

  return (
    <>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Welcome to our Job Board</h1>
        {/* <p className={styles.description}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae neque et lectus tristique ullamcorper. Sed vel urna at nunc faucibus mattis. Cras nec justo vel turpis gravida viverra. Nullam et est id nunc consectetur sagittis.
        </p> */}
   

        <div className={styles.container}>
          <Routes>
            <Route path="/" element={<JobPost />} />
            <Route path="/add-job" element={<AddJob />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Job;
