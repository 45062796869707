import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Dropdown from "../../DropDown/DropDown";
import UserContext from "../../../../Context/UserContext";
import { MdModeEditOutline } from "react-icons/md";
import Toast from "../../ToastContainer/ToastContainer";
import styles from "./EditAttendance.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "./../../../../config/config";

const getIndividualStudentAttendance =
  process.env.REACT_APP_HOD_GET_INDIVIDUAL_STUDENT_ATTENDANCE;
const editIndividualStudentAttendance =
  process.env.REACT_APP_HOD_EDIT_INDIVIDUAL_STUDENT_ATTENDANCE;

const EditAttendance = () => {
  const { showConfirmation, getToken, setShowLoader } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [toEdit, setToEdit] = useState();
  const [student, setStudent] = useState();
  const [attendanceRecords, setAttendanceRecords] = useState(
    student?.attendanceRecords
  );

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const fetchIndividualAttendance = async () => {
    try {
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${getIndividualStudentAttendance}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );


      setStudent(() => response.data.data);
      setAttendanceRecords(() => response.data.data.attendanceRecords);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);

    }
  };

  const handleSetEdit = (data) => {
    data.subjectId = student.subjectId;
    data.studentId = location.state.studentId;
    data.enrollmentId = location.state.enrollmentId;
    setToEdit(() => data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    fetchIndividualAttendance();
  }, []);

  const handleUpdateAttendance = async () => {
    try {
      if (!toEdit.presentStatus) {
        return Toast.error("Select Attendance Status...!");
      }
      setShowLoader(true);

      const response = await axios.patch(
        `${BASE_URL}${editIndividualStudentAttendance}`,
        toEdit,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      if (response.data.result) {
        fetchIndividualAttendance();
        setOpen(false);
        setToEdit(null);
        Toast.success("Attendance Updated Successfully...");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong !");
    }
  };

  const handleChangePresentStatus = async (e) => {

    const data = {
      A: false,
      P: true,
    };

    let fieldName = "isPresent";
    if (e.target.value) {
    }
    setToEdit({
      ...toEdit,
      [e.target.name]: e.target.value,
      [fieldName]: data[e.target.value],
    });
  };

  return (
    <Box className={styles.editAttendanceContainer}>
      <h2 className={styles.containerTitle}>
        Attendance Records for : {student?.studentName} ({student?.enrollmentId}
        )
      </h2>
      <h2 className={styles.containerTitle}>
        Subject : {student?.subjectName} ({student?.subjectType})
      </h2>

      <Box className={styles.arrowContainer}>
        <ArrowBackIcon
          className={styles.previousButton}
          onClick={() => {
            navigate(-1);
          }}
        />
        <ArrowForwardIcon
          className={styles.nextButton}
          onClick={() => {
            navigate(+1);
          }}
        />
      </Box>

      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="customized table" className={styles.theTable}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHead}>Date</TableCell>
              {/* <TableCell>Lecture ID</TableCell> */}
              <TableCell className={styles.tableHead}>
                Present Status
              </TableCell>
              <TableCell className={styles.tableHead}>Is Present</TableCell>
              <TableCell className={styles.tableHead}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceRecords &&
              attendanceRecords?.map((record, index) => (
                <TableRow key={record.date}>
                  <TableCell className={styles.tableData}>
                    {new Date(record.date)
                      .toISOString()
                      .slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("/")}
                  </TableCell>
                  <TableCell className={styles.tableData}>
                    {record.presentStatus}
                  </TableCell>
                  <TableCell className={styles.tableData}>
                    {record.isPresent ? "Yes" : "No"}
                  </TableCell>
                  <TableCell className={styles.tableData}>
                    <Button
                      onClick={() => {
                        handleSetEdit(record);
                      }}
                    >
                      <MdModeEditOutline />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modal}>
            <Box className={styles.closeIconContainer}>
              <IconButton onClick={handleClose} className={styles.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Box>
            <h2 className={styles.modalTitle} >
              EDIT ATTENDANCE
            </h2>
            <p className={styles.modalPara}>
              Date:{" "}
              {toEdit?.date
                ? `${new Date(toEdit?.date)
                  .toISOString()
                  .slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")}`
                : ""}
            </p>
            <p className={styles.modalPara}>
              Subject :{student?.subjectName}
            </p>{" "}
            <p className={styles.modalPara}>
              Student :{student?.studentName}
            </p>

            <Box className={styles.inputGroup}>
              <label htmlFor="Status" className={styles.dropDownLabel}>
                Status
              </label>
              <Dropdown
                className={styles.dropdownInput}
                options={[
                  { label: "Present", value: "P" },
                  { label: "Absent", value: "A" },
                ]}
                name={"presentStatus"}
                value={toEdit?.presentStatus ? toEdit.presentStatus : ""}
                onChange={handleChangePresentStatus}
              />
            </Box>

            <Button
              className={styles.saveStatusButton}
              onClick={() => {
                showConfirmation("want to change Attendance Status !", handleUpdateAttendance, toEdit)
              }}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditAttendance;
