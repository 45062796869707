import React from 'react'
import styles from './StudentJobBoard.module.css'
import { Routes , Route} from 'react-router-dom';
import ApplyJob from '../../../CommonComponent/JobRelated/ApplyJob/ApplyJob';
import JobList from '../../../CommonComponent/JobRelated/JobList/JobList';
const StudentJobBoard = () => {
  return (
    <div>
      <h1 className={styles.title}>Welcome to our Job Board</h1>
      <Routes >
        <Route path="/" element={<JobList />} />
        <Route path="/apply-job/:jobId" element={<ApplyJob />} />
      </Routes>
    </div>
  );
}

export default StudentJobBoard