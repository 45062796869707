import React, { useState, useContext, useEffect } from "react";
import { Box, Grid, IconButton, Button } from "@mui/material";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import axios from "axios";
import styles from "./AddDepartment.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../../config/config";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import UserContext from "../../../../../Context/UserContext";

const getHodList = process.env.REACT_APP_GET_HOD_LIST;
const addDepartment = process.env.REACT_APP_ADD_DEPARTMENT;

function AddDepartment() {
  const { getToken, setShowLoader, showConfirmation } =
    useContext(UserContext);
  const [DropDownData, setDropDownData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [formData, setFormData] = useState({
    departmentCode: "",
    departmentName: "",
    buildingName: "",
    latitude: "",
    longitude: "",
    altitude: "",
    hodId: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${addDepartment}`, formData, {
        headers,
      });

      if (response.data.result) {
        Toast.success(response.data.message || "Success!");

        // Resetting form data after a successful submission
        setFormData({
          departmentCode: "",
          departmentName: "",
          buildingName: "",
          latitude: "",
          longitude: "",
          altitude: "",
          hodId: "",
        });
        navigate('../')
        setErrors({});
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "departmentCode",
      "departmentName",
      "buildingName",
      "latitude",
      "longitude",
      "altitude",
      //   "hodId",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (formData.departmentCode && formData.departmentCode.length > 20) {
      validationErrors["departmentCode"] = "**Department Code must be less than 20 characters**";
    }

    if (formData.departmentName && formData.departmentName.length > 50) {
      validationErrors["departmentName"] = "**Department Name must be less than 50 characters**";
    }

    if (formData.buildingName && formData.buildingName.length > 50) {
      validationErrors["buildingName"] = "**Building Name must be less than 50 characters**";
    }

    // Validate longitude and latitude to be between 1 and 100
    const longitude = parseFloat(formData.longitude);
    const latitude = parseFloat(formData.latitude);

    if (longitude && (longitude <= -180 || longitude >= 180)) {
      validationErrors["longitude"] = "**Longitude must be between -180 and 180**";
    }

    if (latitude && (latitude <= -90 || latitude >= 90)) {
      validationErrors["latitude"] = "**Latitude must be between -90 and 90**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const fetchDropdown = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      const response = await axios.get(`${BASE_URL}${getHodList}`, {
        headers,
      });
      const data = response?.data;
      if (data.result) {
        setDropDownData(() => data?.data);
      } else {
        Toast.error(data.message || "Something went wrong!");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong !");
      throw error;
    }
  };

  useEffect(() => {
    fetchDropdown();
  }, []);

  return (
    <Box className={styles.formContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={() => navigate(-1)} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <h2 className={styles.formContainerTitle}>REGISTER DEPARTMENT</h2>

      <div className={styles.addCourseScrollable}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            showConfirmation("Want to register new Department ?", handleSubmit, e);
          }}
        >
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              {getUserRole() !== "hod" && (
                <div className={styles.courseInputGroup}>
                  <label htmlFor="departmentCode">Department</label>
                  <DropDown
                    options={DropDownData.departmentList}
                    onChange={handleDepartmentSelect}
                    value={department}
                  />
                  {errors.departmentCode && <p className={styles.error}>{errors.departmentCode}</p>}
                </div>
              )}
            </Grid> */}

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="departmentCode">Department Code</label>
                <input
                  type="text"
                  id="departmentCode"
                  placeholder="Department Code"
                  name="departmentCode"
                  value={formData.departmentCode}
                  onChange={handleChange}
                />
                {errors.departmentCode && (
                  <p className={styles.error}>{errors.departmentCode}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="departmentName">Department Name</label>
                <input
                  type="text"
                  id="departmentName"
                  placeholder="Department Name"
                  name="departmentName"
                  value={formData.departmentName}
                  onChange={handleChange}
                />
                {errors.departmentName && (
                  <p className={styles.error}>{errors.departmentName}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="hodId">Head of Department <span className={styles.span}>(HOD)</span></label>
                <DropDown
                  options={DropDownData}
                  type="text"
                  id="hodId"
                  placeholder="Head of Department (HOD)"
                  name="hodId"
                  onChange={handleChange}
                  value={formData.hodId}
                />
                {errors.hodId && <p className={styles.error}>{errors.hodId}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="buildingName">Building Name</label>
                <input
                  type="text"
                  id="buildingName"
                  placeholder="Building Name"
                  name="buildingName"
                  value={formData.buildingName}
                  onChange={handleChange}
                />
                {errors.buildingName && (
                  <p className={styles.error}>{errors.buildingName}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="latitude">Latitude <span className={styles.span}>(Degrees in number)</span></label>
                <input
                  type="number"
                  id="latitude"
                  placeholder="Latitude"
                  name="latitude"
                  value={formData.latitude}
                  onChange={handleChange}
                />
                {errors.latitude && (
                  <p className={styles.error}>{errors.latitude}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="longitude">Longitude <span className={styles.span}>(Degrees in number)</span></label>
                <input
                  type="number"
                  id="longitude"
                  placeholder="Longitute"
                  name="longitude"
                  value={formData.longitude}
                  onChange={handleChange}
                />
                {errors.longitude && (
                  <p className={styles.error}>{errors.longitude}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="altitude">Altitude <span className={styles.span}>(Metres in number)</span></label>
                <input
                  type="number"
                  id="altitude"
                  placeholder="Altitude"
                  name="altitude"
                  value={formData.altitude}
                  onChange={handleChange}
                />
                {errors.altitude && (
                  <p className={styles.error}>{errors.altitude}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12} className={styles.saveButtonContainer}>
              <Button
                type="submit"
                variant="contained"
                className={styles.saveButton}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Box>
  );
}

export default AddDepartment;
