import React from "react";
import Sidebar from "../../CommonComponent/Sidebar/Sidebar";

function InstituteAdminDesktop({ logout, user }) {
  return (
    <>
      <Sidebar logout={logout} user={user} />
    </>
  );
}

export default InstituteAdminDesktop;
