// Base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Attendance URL
export const ATTENDANCE_URL = process.env.REACT_APP_ATTENDANCE_URL;

// Group Chat URL
export const GROUP_CHAT_URL = process.env.REACT_APP_GROUP_CHAT;

// Group Chat URL
export const PERSONAL_CHAT_URL = process.env.REACT_APP_PERSONAL_CHAT;
