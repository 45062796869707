import React, { useEffect, useState } from "react";
import UserContext from "./UserContext";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import io from "socket.io-client";
import io2 from "socket.io-client";
import io3 from "socket.io-client";
import { BASE_URL } from "./../config/config";
import { ATTENDANCE_URL } from "./../config/config";
import { GROUP_CHAT_URL } from "./../config/config";
import { PERSONAL_CHAT_URL } from "./../config/config";

const chatGetUserList = process.env.REACT_APP_CHAT_GETUSERLIST;
const chatGetGroupList = process.env.REACT_APP_CHAT_GETGROUPLIST;
const chatShowMessage = process.env.REACT_APP_CHAT_SHOWMESSAGE;

const Cookies = require("js-cookie");

function UserContextProvider({ children }) {
  const [userData, setuserData] = useState("");
  const [Profile, setProfile] = useState();
  const [departmentData, setdepartmentData] = useState("");
  const [SelectedStudent, setSelectedStudent] = useState([]);
  const [StudentList, setStudentList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(
    "Confirmation Message"
  );
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [dataForConfirm, setDataForConfirm] = useState(null);
  const [users, setUsers] = useState();
  const [socket, setSocket] = useState(null);
  const [groupSocket, setGroupSocket] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [GROUP_CHAT_URLMessages, setGROUP_CHAT_URLMessages] = useState([]);
  const [historymsg, setHistorymsg] = useState();
  const [groupHistorymsg, setGroupHistorymsg] = useState();
  const [receivedMessageCount, setReceivedMessageCount] = useState(0);
  const [GroupMessageCounts, setGroupMessageCounts] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [attendanceSocket, setAttendanceSocket] = useState(null);
  const [courseCode, setCourseCode] = useState("");
  const [batchId, setBatchId] = useState("");
  const [sectionId, setSectionId] = useState("");

  const showConfirmation = (message, onConfirmCallback, data) => {
    setShowConfirmationPopup(true);
    setConfirmationMessage(message);
    setDataForConfirm(data);
    setOnConfirm(() => onConfirmCallback);
  };

  const getToken = () => {
    const token = Cookies.get("token");

    if (!token) {
      return "";
    }
    const parts = token.split(".");
    if (parts.length !== 3) {
      return "";
    }
    const decodedToken = jwtDecode(token);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    if (decodedToken.exp < currentTimeInSeconds) {
      return "";
    }
    return token;
  };

  const getUserRole = () => {
    if (getToken() === "") {
      return null;
    }
    const decodedToken = jwtDecode(getToken());
    return decodedToken?.role || null;
  };

  const getDecodedToken = () => {
    if (getToken() === "") {
      return null;
    }
    const decodedToken = jwtDecode(getToken());
    return decodedToken;
  };

  useEffect(() => {
    const image = localStorage.getItem("profileimg");
    setProfile(image);
  }, []);

  const createSocketClient = async () => {
    if (socket == null) {
      let s = await io(`${PERSONAL_CHAT_URL}`);

      setTimeout(() => {
        localStorage.setItem("socket", s);
      }, 2000);
      setSocket(s);
    }
  };

  const creategroupSocketClient = async () => {
    let a = await io2(`${GROUP_CHAT_URL}`);

    setTimeout(() => {}, 2000);

    setGroupSocket(a);
  };

  const createAttendanceSocketConnection = async () => {
    try {
      let b;
      if (!attendanceSocket) {
        b = await io3(`${ATTENDANCE_URL}`);

        setShowLoader(true);
        setTimeout(() => {
          setShowLoader(false);
        }, 1000);
        setAttendanceSocket(b);
      }
    } catch (error) {}
  };

  const fetchData1 = async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(`${BASE_URL}${chatGetUserList}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUsers(response?.data.userList);
    } catch (error) {}
  };

  useEffect(() => {}, [users]);

  useEffect(() => {
    if (groupSocket) {
      const handleGroupMessage = (groupMessage) => {
        setGROUP_CHAT_URLMessages((prevGROUP_CHAT_URLMessages) => [
          ...prevGROUP_CHAT_URLMessages,
          groupMessage,
        ]);

        const groupId = groupMessage.groupId;
        const userId = localStorage.getItem("userId");

        const isMessageFromLoggedInUser = groupMessage.senderId === userId;

        if (!isMessageFromLoggedInUser) {
          setGroupMessageCounts((prevCounts) => {
            const updatedCounts = { ...prevCounts };
            const count = (updatedCounts[groupId] || 0) + 1;
            updatedCounts[groupId] = count;
            const countsObject = { [groupId]: count };
            return { ...updatedCounts, ...countsObject };
          });
        }
      };

      groupSocket.on("chat messagesend", handleGroupMessage);

      return () => {
        groupSocket.off("chat messagesend", handleGroupMessage);
      };
    }
  }, [
    groupSocket,
    setGROUP_CHAT_URLMessages,
    setGroupMessageCounts,
    GROUP_CHAT_URLMessages,
  ]);

  const handleGroupNameClick1 = (group) => {
    const groupId = group;
    setGroupMessageCounts((prevCounts) => ({
      ...prevCounts,
      [groupId]: 0,
    }));
    setSelectedGroup(group);
  };

  useEffect(() => {
    if (socket) {
      const handleReceivedMessage = (message) => {
        const senderEmail = message.users[0];

        setChatMessages((prevChatMessages) => [...prevChatMessages, message]);

        setReceivedMessageCount((prevCounts) => {
          const updatedCounts = { ...prevCounts };
          const count = (updatedCounts[senderEmail] || 0) + 1;
          updatedCounts[senderEmail] = count;
          const countsObject = { email: senderEmail, count: count };
          return { ...updatedCounts, ...countsObject };
        });
      };
      socket.on("messageReceived", handleReceivedMessage);

      return () => {
        socket.off("messageReceived", handleReceivedMessage);
      };
    } else {
    }
  }, [socket, setChatMessages, setReceivedMessageCount]);

  const handleClickUser = (user) => {
    setReceivedMessageCount((prevCounts) => {
      const updatedCounts = { ...prevCounts };
      updatedCounts[user.email] = 0;
      return updatedCounts;
    });
  };

  async function handleGroupMessageCall(userID) {
    try {
      const groupID = userID._id;
      const token = Cookies.get("token");

      const groupresponse = await axios.post(
        `${BASE_URL}${chatGetGroupList}`,
        { group: groupID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGROUP_CHAT_URLMessages(groupresponse.data.groupMessage);
    } catch (error) {}
  }

  async function handleMessageCall(userID) {
    try {
      const id = localStorage.getItem("userId");
      const token = Cookies.get("token");

      const response = await axios.post(
        `${BASE_URL}${chatShowMessage}`,
        { userID, id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setHistorymsg(response.data.userMessage);
      setChatMessages(response.data.userMessage);
    } catch (error) {}
  }

  useEffect(() => {
    const image = localStorage.getItem("profileimg");
    setProfile(image);
  }, []);

  return (
    <UserContext.Provider
      value={{
        getToken,
        getUserRole,
        getDecodedToken,
        Cookies,
        userData,
        setuserData,
        Profile,
        setdepartmentData,
        departmentData,
        fetchData1,
        users,
        socket,
        setSocket,
        createSocketClient,
        groupSocket,
        creategroupSocketClient,
        chatMessages,
        setChatMessages,
        GROUP_CHAT_URLMessages,
        setGROUP_CHAT_URLMessages,
        handleMessageCall,
        historymsg,
        setHistorymsg,
        handleGroupMessageCall,
        groupHistorymsg,
        setGroupHistorymsg,
        selectedGroup,
        receivedMessageCount,
        GroupMessageCounts,
        handleGroupNameClick1,
        handleClickUser,
        SelectedStudent,
        setSelectedStudent,
        StudentList,
        setStudentList,
        showLoader,
        setShowLoader,
        attendanceSocket,
        createAttendanceSocketConnection,
        showConfirmationPopup,
        setShowConfirmationPopup,
        confirmationMessage,
        setConfirmationMessage,
        onConfirm,
        setOnConfirm,
        showConfirmation,
        dataForConfirm,
        setDataForConfirm,
        setCourseCode,
        setBatchId,
        setSectionId,
        courseCode,
        batchId,
        sectionId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
