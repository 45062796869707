import React, { useState, useEffect, useContext } from "react";
import { Button } from "@mui/material";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import UserContext from "../../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./SingleUserRegistration.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { BASE_URL } from "./../../../../../config/config";

const singleUserRegistration = process.env.REACT_APP_ADD_USER_BY_HOD;
const getDropdownData = process.env.REACT_APP_GET_DATA_FOR_DROPDOWN;

function SingleUserRegistration({ isCollageAdmin }) {
  const { getToken, setShowLoader, getUserRole, showConfirmation } =
    useContext(UserContext);
  const [DropDownData, setDropDownData] = useState({
    userTypes: [],
    courseList: [],
    batchList: [],
    genderList: [],
  });

  const [departmentCode, setdepartmentCode] = useState();
  const [batchList, setBatchList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [semesterList, setSemesterList] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [formData, setFormData] = useState({
    fname: "",
    mname: "",
    lname: "",
    gender: "",
    primaryPhone: "",
    secondaryPhone: "",
    email: "",
    enrollmentId: "",
    dob: "",
    defaultPassword: "",
    confirmPassword: "",
    roleType: "",
    aadharNo: "",
    fatherName: "",
    motherName: "",
    parentPhone: "",
    parentOccupation: "",
  });

  useEffect(() => {
    validateForm();
  }, [formData]);

  const validateForm = () => {
    let validationErrors = {};
    let requiredFields = [
      "fname",
      "mname",
      "lname",
      "gender",
      "primaryPhone",
      "secondaryPhone",
      "email",
      "enrollmentId",
      "dob",
      "defaultPassword",
      "confirmPassword",
      "roleType",
      "aadharNo",
    ];

    let fieldsForStudents = [
      "fatherName",
      "motherName",
      "parentPhone",
      "parentOccupation",
    ];

    if (formData.roleType === "student") {
      requiredFields = [...requiredFields, ...fieldsForStudents];
    }

    // Check required fields
    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    let phoneFields = ["primaryPhone", "secondaryPhone"];
    if (formData.roleType === "student") {
      phoneFields.push("parentPhone");
    }
    phoneFields.forEach((field) => {
      const value = formData[field];
      if (value && !/^\d{10}$/.test(value)) {
        validationErrors[field] = "**Phone number must be 10 digits**";
      }
    });

    const aadharNo = formData["aadharNo"];
    if (aadharNo && !/^\d{12}$/.test(aadharNo)) {
      validationErrors["aadharNo"] = "**Aadhar number must be 12 digits**";
    }

    if (formData.dob) {
      const dob = dayjs(formData.dob);
      if (!dob.isValid()) {
        validationErrors.dob = "**Invalid date format**";
      } else if (dob.isAfter(dayjs(), "day")) {
        validationErrors.dob = "**Date cannot be in the future**";
      }
    }

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    if (
      formData.defaultPassword &&
      !passwordRegex.test(formData.defaultPassword)
    ) {
      validationErrors.defaultPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }

    if (
      formData.confirmPassword &&
      !passwordRegex.test(formData.confirmPassword)
    ) {
      validationErrors.confirmPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }

    // Validate passwords match
    if (
      formData.defaultPassword &&
      formData.confirmPassword &&
      formData.defaultPassword !== formData.confirmPassword
    ) {
      validationErrors.confirmPassword = "**Passwords do not match**";
    }

    // Conditional validation for student role
    if (formData.roleType === "student") {
      const conditionalFields = [
        "courseCode",
        "batchId",
        "sectionId",
        "semester",
      ];
      conditionalFields.forEach((field) => {
        const value = formData[field];
        if (!value || (typeof value === "string" && value.trim() === "")) {
          validationErrors[field] = "**This field is required**";
        }
      });
    }

    setErrors(validationErrors);
    setIsSubmitDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Reset specific fields when role type changes
    if (name === "roleType") {
      // Reset course-related fields if not a student
      if (value !== "student") {
        setFormData((prev) => ({
          ...prev,
          courseCode: "",
          batchId: "",
          sectionId: "",
          semester: "",
        }));
      }
    }

    validateForm();
  };

  const handleDateChange = (field, date) => {
    let parsedDate = dayjs(date);

    if (parsedDate.isValid()) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: parsedDate.toISOString(),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: null,
      }));
    }
    validateForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSubmitDisabled) return;

    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${singleUserRegistration}`,
        formData,
        {
          headers,
        }
      );

      if (response.data.result) {
        Toast.success(response.data.message || "Success !");
        setFormData({
          fname: "",
          mname: "",
          lname: "",
          gender: "",
          primaryPhone: "",
          secondaryPhone: "",
          email: "",
          enrollmentId: "",
          dob: "",
          defaultPassword: "",
          confirmPassword: "",
          courseCode: "",
          batchId: "",
          sectionId: "",
          semester: "",
          roleType: "",
          aadharNo: "",
          fatherName: "",
          motherName: "",
          parentPhone: "",
          parentOccupation: "",
        });
        setErrors({});
      } else {
        Toast.error(response.data.message || "Something went wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  useEffect(() => {
    const FetchDataForaddUserRegistration = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${getToken()}`,
        };
        setShowLoader(true);

        const response = await axios.get(`${BASE_URL}${getDropdownData}`, {
          headers,
        });
        const data = response?.data;
        setDropDownData(() => data?.data);
        setShowLoader(false);
        return data;
      } catch (error) {
        setShowLoader(false);
        Toast.error(error?.response?.data?.message || "Something Went Wrong !");
      }
    };
    FetchDataForaddUserRegistration();
  }, []);

  useEffect(() => {}, [errors, isSubmitDisabled]);

  // const handleUserTypesSelect = (e) => {
  //   setRoleType(e.target.value);
  //   setdepartmentCode(" ");
  //   setcourseCode(" ");
  //   if (e.target.value === "student") {
  //     setShowRollnoFild(true);
  //   } else {
  //     setShowRollnoFild(false);
  //   }
  // };

  useEffect(() => {}, [formData]);

  useEffect(() => {
    if (DropDownData.batchList.length !== 0 && formData.courseCode) {
      const batches = DropDownData.batchList.filter(
        (batchData) => batchData.value === formData.courseCode
      );
      if (batches.length !== 0) {
        setBatchList(() => batches[0].batches);
      }
    }

    if (DropDownData.courseList.length !== 0 && formData.courseCode) {
      const semesters = DropDownData.courseList.filter(
        (semester) => semester.value === formData.courseCode
      );

      if (semesters.length !== 0) {
        setSemesterList(() => semesters[0].semesters);
      }
    }
  }, [formData.courseCode]);

  useEffect(() => {
    if (batchList.length !== 0 && formData.batchId) {
      const sections = batchList.filter(
        (section) => section.value === formData.batchId
      );

      if (sections.length !== 0) {
        setSectionList(() => sections[0].sections);
      }
    }
  }, [formData.batchId]);

  // const handleCourseList = async (e) => {
  //   setcourseCode(e.target.value);
  // };

  return (
    <div className={styles.singleRegisterationContainer}>
      <h2 className={styles.singleTitle}>SINGLE USER REGISTRATION</h2>
      <form className={styles.formSection} onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <label htmlFor="roleType">Role Type</label>
          <DropDown
            name="roleType"
            options={DropDownData.userTypes}
            onChange={handleChange}
            value={formData.roleType}
          />
          {errors.roleType && <p className={styles.error}>{errors.roleType}</p>}
        </div>
        {getUserRole === "instituteadmin" && (
          <div className={styles.inputGroup}>
            <label htmlFor="departmentName">Department Name</label>
            <DropDown
              name="departmentCode"
              options={DropDownData.departmenList}
              onChange={handleChange}
              value={departmentCode}
            />
            {errors.departmentCode && (
              <p className={styles.error}>{errors.departmentCode}</p>
            )}
          </div>
        )}

        {formData.roleType === "student" && (
          <>
            <div className={styles.inputGroup}>
              <label htmlFor="courseName">Course</label>
              <DropDown
                id="courseCode"
                name="courseCode"
                value={formData.courseCode}
                onChange={handleChange}
                options={DropDownData.courseList}
              />
              {errors.courseCode && (
                <p className={styles.error}>{errors.courseCode}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="batch">Batch</label>
              <DropDown
                id="batch"
                name="batchId"
                value={formData.batchId}
                onChange={handleChange}
                options={batchList}
              />
              {errors.batchId && (
                <p className={styles.error}>{errors.batchId}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="section">Section</label>
              <DropDown
                id="section"
                name="sectionId"
                value={formData.sectionId}
                onChange={handleChange}
                options={sectionList}
              />
              {errors.sectionId && (
                <p className={styles.error}>{errors.sectionId}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="semester">Semester</label>
              <DropDown
                id="semester"
                name="semester"
                value={formData.semester}
                onChange={handleChange}
                options={semesterList}
              />
              {errors.semester && (
                <p className={styles.error}>{errors.semester}</p>
              )}
            </div>
          </>
        )}

        <div className={styles.inputGroup}>
          <label htmlFor="firstName"> First Name</label>
          <input
            type="text"
            id="firstName"
            placeholder="First Name"
            value={formData.fname}
            name="fname"
            onChange={handleChange}
            className={styles.inputbox}
          />
          {errors.fname && <p className={styles.error}>{errors.fname}</p>}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="middleName"> Middle Name</label>
          <input
            type="text"
            id="middleName"
            placeholder="Midlle Name"
            value={formData.mname}
            name="mname"
            onChange={handleChange}
            className={styles.inputbox}
          />
          {errors.mname && <p className={styles.error}>{errors.mname}</p>}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="lastName"> Last Name</label>
          <input
            type="text"
            id="lastName"
            placeholder="Last Name"
            value={formData.lname}
            name="lname"
            onChange={handleChange}
            className={styles.inputbox}
          />
          {errors.lname && <p className={styles.error}>{errors.lname}</p>}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="primaryPhone"> Primary Phone</label>
          <input
            type="number"
            aria-controls="none"
            id="primaryPhone"
            placeholder="Primary Phone"
            value={formData.primaryPhone}
            name="primaryPhone"
            onChange={handleChange}
            className={styles.inputbox}
          />
          {errors.primaryPhone && (
            <p className={styles.error}>{errors.primaryPhone}</p>
          )}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="secondaryPhone"> Secondary Phone</label>
          <input
            type="number"
            aria-controls="none"
            id="secondaryPhone"
            placeholder="Secondary Phone"
            value={formData.secondaryPhone}
            name="secondaryPhone"
            onChange={handleChange}
            className={styles.inputbox}
          />
          {errors.secondaryPhone && (
            <p className={styles.error}>{errors.secondaryPhone}</p>
          )}
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="gender">Gender</label>
          <DropDown
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            options={DropDownData.genderList}
          />
          {errors.gender && <p className={styles.error}>{errors.gender}</p>}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="emailId"> Email</label>
          <input
            type="email"
            id="emailId"
            placeholder="Email Id"
            value={formData.email}
            name="email"
            onChange={handleChange}
            className={styles.inputbox}
            autoComplete="off"
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="aadharNo"> Aadhar Number</label>
          <input
            type="number"
            id="aadharNo"
            placeholder="Aadhar Number"
            value={formData.aadharNo}
            name="aadharNo"
            onChange={handleChange}
            className={styles.inputbox}
            autoComplete="off"
          />
          {errors.aadharNo && <p className={styles.error}>{errors.aadharNo}</p>}
        </div>

        {formData.roleType === "student" && (
          <>
            <div className={styles.inputGroup}>
              <label htmlFor="fatherName"> Father's / Guardian's Name</label>
              <input
                type="text"
                id="fatherName"
                placeholder="Father's Name"
                value={formData.fatherName}
                name="fatherName"
                onChange={handleChange}
                className={styles.inputbox}
                autoComplete="off"
              />
              {errors.fatherName && (
                <p className={styles.error}>{errors.fatherName}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="motherName"> Mother's / Guardian's Name</label>
              <input
                type="text"
                id="motherName"
                placeholder="Mother's Name"
                value={formData.motherName}
                name="motherName"
                onChange={handleChange}
                className={styles.inputbox}
                autoComplete="off"
              />
              {errors.motherName && (
                <p className={styles.error}>{errors.motherName}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="parentPhone"> Parent / Guardian Phone</label>
              <input
                type="text"
                id="parentPhone"
                placeholder="Parent Phone"
                value={formData.parentPhone}
                name="parentPhone"
                onChange={handleChange}
                className={styles.inputbox}
                autoComplete="off"
              />
              {errors.parentPhone && (
                <p className={styles.error}>{errors.parentPhone}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="parentOccupation">
                {" "}
                Parent / Guardian Occupation
              </label>
              <input
                type="text"
                id="parentOccupation"
                placeholder="Parent Occupation"
                value={formData.parentOccupation}
                name="parentOccupation"
                onChange={handleChange}
                className={styles.inputbox}
                autoComplete="off"
              />
              {errors.parentOccupation && (
                <p className={styles.error}>{errors.parentOccupation}</p>
              )}
            </div>
          </>
        )}

        <div className={styles.inputGroup}>
          <label htmlFor="enrollmentId">Enrollment / Faculty Id</label>
          <input
            type="text"
            id="enrollmentId"
            placeholder="Enrollment Id /Faculty Id"
            value={formData.enrollmentId}
            name="enrollmentId"
            onChange={handleChange}
            className={styles.inputbox}
            autoComplete="off"
          />
          {errors.enrollmentId && (
            <p className={styles.error}>{errors.enrollmentId}</p>
          )}
        </div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={styles.inputGroup}>
            <label htmlFor="dob">Date of Birth</label>
            <DesktopDatePicker
              views={["year", "month", "day"]}
              className={styles.datePicker}
              value={formData.dob ? dayjs(formData.dob) : null}
              onChange={(date) => handleDateChange("dob", date)}
            />
            {errors.dob && <p className={styles.error}>{errors.dob}</p>}
          </div>
        </LocalizationProvider>

        <div className={styles.inputGroup}>
          <label htmlFor="defaultPassword">Default Password</label>
          <input
            type="password"
            id="defaultPassword"
            placeholder="Default Password"
            autoComplete="off"
            value={formData.defaultPassword}
            name="defaultPassword"
            onChange={handleChange}
            className={styles.inputbox}
          />
          {errors.defaultPassword && (
            <p className={styles.error}>{errors.defaultPassword}</p>
          )}
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="Confirm Default Password">
            Confirm Default Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            placeholder="Confirm Default Password"
            value={formData.confirmPassword}
            name="confirmPassword"
            autoComplete="off"
            onChange={handleChange}
            className={styles.inputbox}
          />
          {errors.confirmPassword && (
            <p className={styles.error}>{errors.confirmPassword}</p>
          )}
        </div>

        <Button
          type="button"
          variant="contained"
          disabled={isSubmitDisabled}
          onClick={(e) => {
            e.preventDefault();
            showConfirmation(" Want to Register User ?", handleSubmit, e);
          }}
          className={styles.saveButton}
        >
          Save
        </Button>
      </form>
    </div>
  );
}

export default SingleUserRegistration;
