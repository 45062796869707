import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DropDown from "../../../../DropDown/DropDown";
import CloseIcon from "@mui/icons-material/Close";
import styles from './VUPUserDetailsEdit.module.css'
import dayjs from 'dayjs';
import UserContext from '../../../../../../Context/UserContext';
import Toast from '../../../../ToastContainer/ToastContainer';
import { BASE_URL } from "./../../../../../../config/config";

const editUserDetails = process.env.REACT_APP_VUP_ADMIN_EDIT_USER_DETAILS;

const VUPUserDetailsEdit = ({ BasicData, fetchData, handleCloseModal }) => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext)
  const [error, setError] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [userDetails, setUserDetails] = useState({
    fname: '',
    mname: '',
    lname: '',
    gender: '',
    dob: '',
    email: '',
    primaryPhone: '',
    secondaryPhone: '',
    enrollmentId: '',
    aadharNo: '',
    fatherName: '',
    motherName: '',
    parentPhone: '',
    parentOccupation: '',
  });

  useEffect(() => {
    setUserDetails({ ...BasicData })
  }, [BasicData])

  useEffect(() => {
    validateForm();
  }, [userDetails]);

  const dropdown = [
    {
      label: "Male",
      value: "MALE"
    },
    {
      label: "Female",
      value: "FEMALE"
    },
    {
      label: "Other",
      value: "OTHER"
    }
  ]

  const handleDateChange = (name, date) => {
    const value = date ? date.format("YYYY-MM-DD") : null;


    setUserDetails((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      setShowLoader(true)

      const response = await axios.post(`${BASE_URL}${editUserDetails}/${BasicData.enrollmentId}`, userDetails, { headers: { Authorization: `Bearer ${getToken()}` } })

      if (response.data.result) {
        Toast.success(response.data.message);
        fetchData()
        handleCloseModal()
      } else {
        Toast.error(response.data.message);
        handleCloseModal()
      }
      setShowLoader(false)

    } catch (error) {
      setShowLoader(false)
      Toast.error(error?.response?.data?.message || "Something Went Wrong !")
    }

  };


  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "fname",
      "mname",
      "lname",
      "gender",
      "dob",
      "email",
      "primaryPhone",
      "enrollmentId",
      "aadharNo",
      "fatherName",
      "motherName",
      "parentPhone",
      "parentOccupation",
    ];

    setError({});

    requiredFields.forEach((field) => {
      const value = userDetails[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    const phoneFields = ["primaryPhone", "secondaryPhone", "parentPhone"];
    phoneFields.forEach((field) => {
      const value = userDetails[field];
      if (value && !/^\d{10}$/.test(value)) {
        validationErrors[field] = "**Phone number must be 10 digits**";
      }
    });

    const aadharNo = userDetails["aadharNo"];
    if (aadharNo && !/^\d{12}$/.test(aadharNo)) {
      validationErrors["aadharNo"] = "**Aadhar number must be 12 digits**";
    }

    if (userDetails.dob) {
      const dob = dayjs(userDetails.dob);
      if (!dob.isValid()) {
        validationErrors.dob = "**Invalid date format**";
      } else if (dob.isAfter(dayjs(), "day")) {
        validationErrors.dob = "**Date cannot be in the future**";
      }
    }

    const email = userDetails["email"];
    if (!email || email.trim() === '') {
      validationErrors["email"] = "**Email is required**";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        validationErrors["email"] = "**Invalid email format**";
      }
    }

    // Check pincode validation range
    if (userDetails.pincode === "") {
      validationErrors.currentPincode = "**This field is required**";
    } else if (
      userDetails.pincode &&
      (userDetails.pincode < 110000 || userDetails.pincode > 855999)
    ) {
      validationErrors.pincode =
        "Pincode must be between 110000 and 855999";
    }

    setError(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  return (
    <Box className={styles.editAddressContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton>
          <CloseIcon
            className={styles.closebtnEditAddress}
            onClick={handleCloseModal}
          />
        </IconButton>
      </Box>
      <h2 className={styles.editAddressHeader}>EDIT BASIC DETAILS</h2>
      <div className={styles.editAddressScrollable}>
        <form onSubmit={(event) => {
          event.preventDefault();
          showConfirmation("want to update user basic details ?", handleSubmit, event);
        }} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="permanentPincode">First Name</label>
                <input
                  type="text"
                  id="permanentPincode"
                  name="fname"
                  placeholder="First Name"
                  value={userDetails.fname}
                  onChange={handleChange}
                />
              </div>
              {error.fname && (
                <p className={styles.error}>{error.fname}</p>
              )}
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="mname">Middle Name</label>
                <input
                  type="text"
                  id="mname"
                  name="mname"
                  placeholder="Middle Name"
                  value={userDetails?.mname}
                  onChange={handleChange}
                />
                {error.mname && (
                  <p className={styles.error}>{error.mname}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="lname">Last Name</label>
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  placeholder="Last Name"
                  value={userDetails?.lname}
                  onChange={handleChange}
                />
                {error.lname && (
                  <p className={styles.error}>{error.lname}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="gender">Gender</label>
                <DropDown
                  id="gender"
                  name="gender"
                  value={userDetails.gender}
                  onChange={handleChange}
                  options={dropdown}
                />
                {error.gender && (
                  <p className={styles.error}>{error.gender}</p>
                )}
              </div>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} >
                <div className={styles.addressInputGroup}>
                  <label htmlFor="dob" >
                    Date of Birth
                  </label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    value={
                      userDetails.dob
                        ? dayjs(userDetails.dob)
                        : null
                    }
                    onChange={(date) => handleDateChange("dob", date)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onBlur={() =>
                          handleDateChange(
                            "dob",
                            dayjs(params.inputProps.value)
                          )
                        }
                      />
                    )}
                  />
                  {error.dob && (
                    <p className={styles.error}>{error.dob}</p>
                  )}
                </div>
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Id"
                  value={userDetails?.email}
                  disabled
                  className={styles.disabled}
                // onChange={handleChange}
                />
                {error.email && (
                  <p className={styles.error}>{error.email}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="primaryPhone">Primary Phone</label>
                <input
                  type="number"
                  id="primaryPhone"
                  name="primaryPhone"
                  placeholder="Primary Phone"
                  value={userDetails.primaryPhone}
                  onChange={handleChange}
                />
                {error.primaryPhone && (
                  <p className={styles.error}>{error.primaryPhone}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="secondaryPhone">Secondary Phone</label>
                <input
                  type="number"
                  id="secondaryPhone"
                  name="secondaryPhone"
                  placeholder="Secondary Phone"
                  value={userDetails.secondaryPhone}
                  onChange={handleChange}
                />
                {error.secondaryPhone && (
                  <p className={styles.error}>{error.secondaryPhone}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="enrollmentId">Enrollment / Faculty ID</label>
                <input
                  type="text"
                  id="enrollmentId"
                  name="enrollmentId"
                  placeholder="Enrollment ID"
                  value={userDetails.enrollmentId}
                  onChange={handleChange}
                />
                {error.enrollmentId && (
                  <p className={styles.error}>{error.enrollmentId}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="aadharNo">Aadhar Number</label>
                <input
                  type="number"
                  id="aadharNo"
                  name="aadharNo"
                  placeholder="Aadhar Number"
                  value={userDetails.aadharNo}
                  onChange={handleChange}
                />
                {error.aadharNo && (
                  <p className={styles.error}>{error.aadharNo}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="fatherName">Father's / Guardian's Name</label>
                <input
                  type="text"
                  id="fatherName"
                  name="fatherName"
                  placeholder="Father's Name"
                  value={userDetails.fatherName}
                  onChange={handleChange}
                />
                {error.fatherName && (
                  <p className={styles.error}>{error.fatherName}</p>
                )}
              </div>
            </Grid>


            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="motherName">Mother's / Guardian's Name</label>
                <input
                  type="text"
                  id="motherName"
                  name="motherName"
                  placeholder="Mother's Name"
                  value={userDetails.motherName}
                  onChange={handleChange}
                />
                {error.motherName && (
                  <p className={styles.error}>{error.motherName}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="permanentPincode">Parent / Guardian's Phone</label>
                <input
                  type="number"
                  id="permanentPincode"
                  name="parentPhone"
                  placeholder="Parent Phone"
                  value={userDetails.parentPhone}
                  onChange={handleChange}
                />
                {error.parentPhone && (
                  <p className={styles.error}>{error.parentPhone}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.addressInputGroup}>
                <label htmlFor="permanentPincode">Parent / Guardian's Occupation</label>
                <input
                  type="text"
                  id="permanentPincode"
                  name="parentOccupation"
                  placeholder="Parent Occupation"
                  value={userDetails.parentOccupation}
                  onChange={handleChange}
                />
                {error.parentOccupation && (
                  <p className={styles.error}>{error.parentOccupation}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={isSaveDisabled}
                type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Box>
  );
};

export default VUPUserDetailsEdit;
