import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, IconButton, Tabs, Tab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./VUPEditAddress.module.css";
import axios from "axios";
import DropDown from "../../../../DropDown/DropDown";
import UserContext from "../../../../../../Context/UserContext";
import Toast from "../../../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../../config/config";

const dropdownCountryName = process.env.REACT_APP_DROPDOWN_GET_COUNTRY_NAMES;
const dropdownStateName = process.env.REACT_APP_DROPDOWN_GET_STATE_NAMES;
const dropdownDistrictName = process.env.REACT_APP_DROPDOWN_GET_DISTRICT_NAMES;

const VUPEditAddress = ({
  handleCloseModal,
  handleSaveAddress,
  permanentAddress = {},
  currentAddress = {},
  addressType,
}) => {
  const [permanentFormData, setPermanentFormData] = useState({
    addressLine: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  });

  const [currentFormData, setCurrentFormData] = useState({
    addressLine: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [error, setError] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [currentTab, setCurrentTab] = useState(
    addressType === "current" ? 1 : 0
  );

  const [isStateEnabled, setIsStateEnabled] = useState(false);
  const [isDistrictEnabled, setIsDistrictEnabled] = useState(false);
  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);
  const [token] = useState(Cookies.get("token"));

  useEffect(() => {
    if (permanentAddress) {
      setPermanentFormData(permanentAddress);
    }
    if (currentAddress) {
      setCurrentFormData(currentAddress);
    }
  }, [permanentAddress, currentAddress]);

  useEffect(() => {
    validateForm();
  }, [permanentFormData, currentFormData, currentTab]);

  useEffect(() => {
    fetchDropdownData();
    setPermanentFormData({ ...permanentAddress, ["country"]: "India" });
    setCurrentFormData({ ...currentAddress, ["country"]: "India" });
  }, []);

  useEffect(() => {
    if (permanentFormData.country) {
      fetchStates(permanentFormData.country);
    }
  }, [permanentFormData.country]);

  useEffect(() => {
    if (permanentFormData.state) {
      fetchDistricts(permanentFormData.country, permanentFormData.state);
    }
  }, [permanentFormData.state]);

  useEffect(() => {
    if (currentFormData.country) {
      fetchStates(currentFormData.country);
    }
  }, [currentFormData.country]);

  useEffect(() => {
    if (currentFormData.state) {
      fetchDistricts(currentFormData.country, currentFormData.state);
    }
  }, [currentFormData.state]);

  const fetchDropdownData = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${dropdownCountryName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCountries(() => response?.data?.data);
      setShowLoader(false);
      // Toast.error(response?.data?.message || "Something Went Wrong !");
    } catch (err) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const fetchStates = async (country) => {
    try {
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${dropdownStateName}?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStates(response?.data?.data);
      setIsStateEnabled(true);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const fetchDistricts = async (country, state) => {
    try {
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${dropdownDistrictName}?country=${country}&state=${state}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDistricts(() => response.data.data);
      setIsDistrictEnabled(true);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handlePermanentChange = (e) => {
    const { name, value } = e.target;
    setPermanentFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCurrentChange = (e) => {
    const { name, value } = e.target;
    setCurrentFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSameAsPermanentChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setCurrentFormData({ ...permanentFormData });
    } else {
      setCurrentFormData({
        addressLine: "",
        city: "",
        district: "",
        state: "",
        country: "",
        pincode: "",
      });
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "addressLine",
      "city",
      "district",
      "state",
      "country",
      "pincode",
    ];

    // Reset previous errors
    setError({});

    // Determine which form to validate based on the current tab
    const formData = currentTab === 0 ? permanentFormData : currentFormData;

    // Validate required fields
    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    // Additional validations for addressLine length
    if (formData.addressLine && formData.addressLine.trim().length > 120) {
      validationErrors.addressLine =
        "**Address Line must be at most 120 characters**";
    }

    // Additional validations for city length
    if (formData.city && formData.city.trim().length > 30) {
      validationErrors.city = "**City must be at most 30 characters**";
    }

    // Validate pincode
    if (formData.pincode === "") {
      validationErrors.pincode = "**This field is required**";
    } else if (formData.pincode < 110000 || formData.pincode > 855999) {
      validationErrors.pincode = "Pincode must be between 110000 and 855999";
    }

    // Set validation errors
    setError(validationErrors);

    // Enable save button if there are any validation errors
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleSave = () => {
    if (isSaveDisabled) {
      Toast.error("Please fill all the correct details."); // Show error message
      return; // Prevent saving if there are errors
    }
    const addressData = {
      updatedPermanentAddress: permanentFormData,
      updatedCurrentAddress: currentFormData,
    };
    if (currentTab === 0) {
      showConfirmation(
        "want to update address...?",
        handleSaveAddress,
        addressData
      );
    } else {
      showConfirmation(
        "want to update address...?",
        handleSaveAddress,
        addressData
      );
    }
  };

  return (
    <Box className={styles.editAddressContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton>
          <CloseIcon
            className={styles.closebtnEditAddress}
            onClick={handleCloseModal}
          />
        </IconButton>
      </Box>
      <h2 className={styles.editAddressHeader}>EDIT ADDRESS</h2>
      <div className={styles.tabs}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Permanent Address" />
          <Tab label="Current Address" />
        </Tabs>
      </div>
      <div className={styles.editAddressScrollable}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            {currentTab === 0 && (
              <>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="permanentCountry">Country</label>
                    <DropDown
                      id="permanentCountry"
                      name="country"
                      placeholder="Country"
                      value={permanentFormData.country}
                      onChange={handlePermanentChange}
                      options={countries}
                    />
                    {error.country && (
                      <div className={styles.error}>{error.country}</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="permanentState">State / Province</label>
                    <DropDown
                      id="permanentState"
                      name="state"
                      placeholder="State"
                      value={permanentFormData.state}
                      onChange={handlePermanentChange}
                      options={states}
                    />
                    {error.state && (
                      <div className={styles.error}>{error.state}</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="permanentDistrict">District</label>
                    <DropDown
                      id="permanentDistrict"
                      name="district"
                      placeholder="District"
                      value={permanentFormData.district}
                      onChange={handlePermanentChange}
                      options={districts}
                    />
                    {error.district && (
                      <div className={styles.error}>{error.district}</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="permanentCity">City / Town</label>
                    <input
                      type="text"
                      id="permanentCity"
                      name="city"
                      placeholder="City"
                      value={permanentFormData.city}
                      onChange={handlePermanentChange}
                    />
                    {error.city && (
                      <div className={styles.error}>{error.city}</div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="permanentPincode">Pincode / ZipCode</label>
                    <input
                      type="number"
                      id="permanentPincode"
                      name="pincode"
                      placeholder="Pincode"
                      value={permanentFormData.pincode}
                      onChange={handlePermanentChange}
                      onInput={(e) => {
                        if (e.target.value.length > 6)
                          e.target.value = e.target.value.slice(0, 6);
                      }}
                    />
                    {error.pincode && (
                      <p className={styles.error}>{error.pincode}</p>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="permanentAddressLine">Address Line</label>
                    <input
                      type="text"
                      id="permanentAddressLine"
                      name="addressLine"
                      placeholder="Address Line"
                      value={permanentFormData.addressLine}
                      onChange={handlePermanentChange}
                    />
                    {error.addressLine && (
                      <div className={styles.error}>{error.addressLine}</div>
                    )}
                  </div>
                </Grid>
              </>
            )}
            {currentTab === 1 && (
              <>
                <Grid item xs={12}>
                  <div className={styles.checkboxGroup}>
                    <input
                      type="checkbox"
                      id="sameAsPermanent"
                      className={styles.sameAsPermanent}
                      name="sameAsPermanent"
                      onChange={handleSameAsPermanentChange}
                    />
                    <label htmlFor="sameAsPermanent">
                      Same as Permanent Address
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="currentCountry">Country</label>
                    <DropDown
                      id="currentCountry"
                      name="country"
                      placeholder="Country"
                      value={currentFormData.country}
                      onChange={handleCurrentChange}
                      options={countries}
                    />
                    {error.country && (
                      <p className={styles.error}>{error.country}</p>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="currentState">State / Province</label>
                    <DropDown
                      id="currentState"
                      name="state"
                      placeholder="State"
                      value={currentFormData.state}
                      onChange={handleCurrentChange}
                      options={states}
                    />
                    {error.state && (
                      <p className={styles.error}>{error.state}</p>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="currentDistrict">District</label>
                    <DropDown
                      id="currentDistrict"
                      name="district"
                      placeholder="District"
                      value={currentFormData.district}
                      onChange={handleCurrentChange}
                      options={districts}
                    />
                    {error.district && (
                      <p className={styles.error}>{error.district}</p>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="currentCity">City / Town</label>
                    <input
                      type="text"
                      id="currentCity"
                      name="city"
                      placeholder="City"
                      value={currentFormData.city}
                      onChange={handleCurrentChange}
                    />
                    {error.city && <p className={styles.error}>{error.city}</p>}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="currentPincode">Pincode / ZipCode</label>
                    <input
                      type="number"
                      id="currentPincode"
                      name="pincode"
                      placeholder="Pincode"
                      value={currentFormData.pincode}
                      onChange={handleCurrentChange}
                      onInput={(e) => {
                        if (e.target.value.length > 6)
                          e.target.value = e.target.value.slice(0, 6);
                      }}
                    />
                    {error.pincode && (
                      <p className={styles.error}>{error.pincode}</p>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.addressInputGroup}>
                    <label htmlFor="currentAddressLine">Address Line</label>
                    <input
                      type="text"
                      id="currentAddressLine"
                      name="addressLine"
                      placeholder="Address Line"
                      value={currentFormData.addressLine}
                      onChange={handleCurrentChange}
                    />
                    {error.addressLine && (
                      <div className={styles.error}>{error.addressLine}</div>
                    )}
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} className={styles.submitButtonEditAddress}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isSaveDisabled}
            >
              Save
            </Button>
          </Grid>
        </form>
      </div>
    </Box>
  );
};

export default VUPEditAddress;
