import React from "react";
import ViewBatches from "./ViewBatches/ViewBatches";
import AddBatch from "./AddBatch/AddBatch";
import { Route, Routes } from "react-router-dom";
import EditBatch from "./EditBatch/EditBatch";
import CreatePracticalBatch from "../Batches/PracticalBatches/CreatePracticalBatch";
import AllocateStudents from "./AllocateStudents/AllocateStudents";
import ViewPracticalBatches from "./PracticalBatches/ViewPracticalBatches/ViewPracticalBatches";

const BatchManager = (FetchDataForaddCourse) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ViewBatches />}></Route>
        <Route path="/add-new-batch" element={<AddBatch />}></Route>
        <Route path="/edit-batch/:batchId" element={<EditBatch />}></Route>

        <Route path="/batch-users" element={<AllocateStudents />}></Route>
        <Route
          path="/add-practical-batch"
          element={
            <CreatePracticalBatch
              FetchDataForaddCourse={FetchDataForaddCourse}
            />
          }
        ></Route>
        <Route
          path="/view-practical-batches"
          element={<ViewPracticalBatches />}
        ></Route>
      </Routes>
    </>
  );
};

export default BatchManager;
