import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../../../Context/UserContext";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Box, IconButton, Grid } from "@mui/material";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./AddSubject.module.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "./../../../../../config/config";

const addSubject = process.env.REACT_APP_ADD_SUBJECT;
const getDropdownDataAddSubject =
  process.env.REACT_APP_GET_DATA_FOR_DROPDOWN_ADD_SUBJECT;

function AddSubject() {
  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);
  const [DropDownData, setDropDownData] = useState({});
  const [course, setCourse] = useState("");
  const [ListofSemester, setListofSemester] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [subjectTypes, setSubjectTypes] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    courseCode: "",
    semester: "",
    subjectType: "",
    subjectCode: "",
    subjectName: "",
    subjectCredit: "",
    teacherId: "",
    color: "",
    minAttendance: "",
  });

  const navigate = useNavigate();

  // Validate the form whenever formData changes
  useEffect(() => {
    validateForm();
  }, [formData]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        ...formData,
        subjectType: subjectTypes,
        semester: selectedSemester,
        courseCode: course,
        teacherId,
      };

      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${addSubject}`, formData, {
        headers,
      });

      if (response.data.result) {
        Toast.success(response?.data?.message || "Success !");
        // navigate(-1);

        setFormData({
          courseCode: "",
          semester: "",
          subjectType: "",
          subjectCode: "",
          subjectName: "",
          subjectCredit: "",
          teacherId: "",
          color: "",
          minAttendance: "",
        }); 
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "courseCode",
      "semester",
      "subjectType",
      "subjectCode",
      "subjectName",
      "subjectCredit",
      "color",
      "minAttendance",
      // "teacherId",
    ];

    // Check for empty fields
    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (
      formData.minAttendance == null ||
      String(formData.minAttendance).trim() === ""
    ) {
      validationErrors["minAttendance"] = "**This field is required**";
    }

    if (formData.subjectCode && formData.subjectCode.length > 20) {
      validationErrors["subjectCode"] =
        "**Subject Code must be less than 20 characters**";
    }

    if (formData.subjectName && formData.subjectName.length > 40) {
      validationErrors["subjectName"] =
        "**Subject Name must be less than 40 characters**";
    }

    if (formData.subjectCredit) {
      const subjectCredit = Number(formData.subjectCredit);
      if (
        subjectCredit <= 1 ||
        subjectCredit >= 11 ||
        isNaN(subjectCredit) // Check if it's a valid number
      ) {
        validationErrors.subjectCredit =
          "**Must be a number greater than 1 and less than 11**";
      }
    }

    // Check for subjectCode (custom validation for alphanumeric)
    const subjectCodePattern = /^[a-zA-Z0-9]+$/;
    if (
      formData.subjectCode &&
      !subjectCodePattern.test(formData.subjectCode)
    ) {
      validationErrors.subjectCode =
        "Subject Code should contain only alphanumeric characters.";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if(!formData.courseCode) return;
    const selectedCourseData = DropDownData.courses.find(
      (course) => course.value === formData.courseCode
    );
    if (selectedCourseData) {
      setListofSemester(selectedCourseData.semesters);
    }
  },[formData.courseCode])
  useEffect(() => {
    const fetchDataForAddSubject = async () => {
      try {
        const token = Cookies.get("token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        setShowLoader(true);

        const response = await axios.get(
          `${BASE_URL}${getDropdownDataAddSubject}`,
          { headers }
        );
        const data = response?.data?.data;
        setDropDownData(data || {});
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        Toast.error(error?.response?.data?.message || "Something Went Wrong !");
      }
    };
    fetchDataForAddSubject();
  }, []);

  return (
    <Box className={styles.formContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={() => navigate(-1)} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>

      <h2 className={styles.formContainerTitle}>ADD NEW SUBJECT</h2>

      <div className={styles.registerSubjectScrollable}>
        <form
          className={styles.formGroup}
          onSubmit={(event) => {
            event.preventDefault();
            showConfirmation("Want to add new Subject?", handleSubmit, event);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="course">Course</label>
                <DropDown
                  options={DropDownData.courses}
                  onChange={handleChange}
                  value={formData.courseCode}
                  name={'courseCode'}
                />
                {errors.course && (
                  <p className={styles.error}>{errors.course}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="selectedSemester">Semester</label>
                <DropDown
                  options={ListofSemester}
                  name={'semester'}
                  onChange={handleChange}
                  value={formData.semester}
                />
                {errors.selectedSemester && (
                  <p className={styles.error}>{errors.selectedSemester}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="subjectTypes">Subject Type</label>
                <DropDown
                  options={DropDownData.subjectTypes}
                  name={'subjectType'}
                  onChange={handleChange}
                  value={formData.subjectType}
                />
                {errors.subjectTypes && (
                  <p className={styles.error}>{errors.subjectTypes}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="subjectCode">Subject Code</label>
                <input
                  type="text"
                  id="subjectCode"
                  name="subjectCode"
                  value={formData.subjectCode}
                  onChange={handleChange}
                  placeholder="Subject Code"
                  className={styles.inputbox}
                />
                {errors.subjectCode && (
                  <p className={styles.error}>{errors.subjectCode}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="subjectName">Subject Name</label>
                <input
                  type="text"
                  id="subjectName"
                  name="subjectName"
                  value={formData.subjectName}
                  onChange={handleChange}
                  placeholder="Subject Name"
                  className={styles.inputbox}
                />
                {errors.subjectName && (
                  <p className={styles.error}>{errors.subjectName}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="subjectCredit">Subject Credit</label>
                <input
                  type="number"
                  id="subjectCredit"
                  name="subjectCredit"
                  value={formData.subjectCredit}
                  onChange={handleChange}
                  placeholder="Subject Credit"
                  className={styles.inputbox}
                />
                {errors.subjectCredit && (
                  <p className={styles.error}>{errors.subjectCredit}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="colorPicker">Subject Color</label>
                <input
                  type="color"
                  id="colorPicker"
                  name="color"
                  value={formData.color}
                  onChange={handleChange}
                  placeholder="Subject Color"
                  style={{
                    backgroundColor: `${formData.color}`,
                    width: "100%",
                    height: "45px",
                    color: "transparent",
                  }}
                  className={styles.inputbox}
                />
                {/* <span style={{backgroundColor:`${formData.color}`, height: '20px', width:'100%'}}></span> */}
                {errors.color && <p className={styles.error}>{errors.color}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="minAttendance">
                  Least Attendance Criteria{" "}
                  <span className={styles.span}>(in Percentage)</span>
                </label>
                <input
                  type="number"
                  id="minAttendance"
                  name="minAttendance"
                  value={formData.minAttendance}
                  onChange={handleChange}
                  placeholder="Least Attendance Criteria"
                  className={styles.inputbox}
                />
                {errors.minAttendance && (
                  <p className={styles.error}>{errors.minAttendance}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.subjectInputGroup}>
                <label htmlFor="teacherId">Faculty Name</label>
                <DropDown
                  options={DropDownData.facultyList}
                  onChange={handleChange}
                  name={'teacherId'}
                  value={formData.teacherId}
                />
                {errors.teacherId && (
                  <p className={styles.error}>{errors.teacherId}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12} className={styles.saveButtonContainer}>
              <Button
                variant="contained"
                type="submit"
                disabled={isSaveDisabled}
                className={styles.saveButton}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Box>
  );
}

export default AddSubject;
