import React, { useState } from 'react'
import styles from './JobList.module.css'
import JobPost from '../JobPost/JobPost';

const JobList = () => {
  const [jobList, setJobList] = useState([1, 2, 3, 4, 5, 6]);
  
//   const

  return (
    <div>
      {jobList &&
        jobList.map((job, index) => {
        //   return <JobPost key={index} jobData={job} />;
        })}
    </div>
  );
};

export default JobList