import React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "1rem",
    padding: "8px",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
}));

export default function Dropdown({
  id,
  label,
  name,
  value,
  onChange,
  options = [],
  showCustomInput,
  customValue,
  onCustomChange,
  customInputLabel,
  showOtherOption,
  disabled,
  sx,
}) {
  const optionsArray = Array.isArray(options) ? options : [];

  return (
    <FormControl sx={{ width: "100%", ...sx }} variant="standard">
      <InputLabel htmlFor={`${id}-native`}>{label}</InputLabel>

      {!showCustomInput ? (
        <NativeSelect
          id={`${id}-native`}
          value={value}
          name={name}
          onChange={onChange}
          input={<BootstrapInput />}
          disabled={disabled}
        >
          <option value="">Select</option>
          {optionsArray.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
          {showOtherOption && <option value="other">Other</option>}
        </NativeSelect>
      ) : (
        <TextField
          fullWidth
          variant="outlined"
          label={customInputLabel}
          value={customValue}
          onChange={onCustomChange}
        />
      )}
    </FormControl>
  );
}
