import React, { useEffect, useState, useContext } from "react";
import "./Sidebar.css";
import {
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Avatar, MenuItem, Menu } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import classImage from "./../../../images/classroom (5).png";
import addInstituteAdmin from "../../../images/add-user.png";
import books from "./../../../images/book.png";
import course from "./../../../images/curriculum (1).png";
import institute from "./../../../images/condo.png";
import addInstitute from "./../../../images/buildings.png";
import IconButton from "@mui/material/IconButton";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import community from "../../../images/group-users.png";
import SchoolIcon from "@mui/icons-material/School";
// import MailIcon from "@mui/icons-material/Mail";
import MoreIcon from "@mui/icons-material/MoreVert";
import Profile from "../Profile/Profile";
import UserContext from "../../../Context/UserContext";
import CommunityPage from "../../CommonComponent/Community/CommunityPage";
import AddUser from "../../HODComponent/Pages/AddUser/AddUser";
import SubjectPage from "../../HODComponent/Pages/Subject/Subject";
// import ChatPrimaryBox from "../ChatComponent/ChatPrimaryBox/ChatPrimaryBox";
// import ChatPersonal from "../ChatComponent/ChatPersonal/ChatPersonal";
import CreateTimeTable from "../../HODComponent/Pages/HODCreateTimeTable/HODTimetable/HODTimetable";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Department from "../../InstituteAdminComponent/Pages/Department/Department";
import Courses from "../../CommonComponent/Courses/Courses";
import AttendanceIcon from "../../../images/correct.png";
import Attendance from "../../FacultyComponent/Pages/FacultyAttendance/FacultyAttendance";
import FacultyTimeTable from "../../FacultyComponent/Pages/FacultyTimeTable/FacultyTimeTable";
import StudentDashboard from "../../StudentComponent/pages/StudentDashboard/StudentDashboard";
import HodRegistration from "../../InstituteAdminComponent/Pages/HodRegistration/HodRegistration";
import TimeTableIcon from "../../../images/schedule.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import StudentAttendance from "../../StudentComponent/pages/StudentAttendance/StudentAttendance";
import StudentTimeTable from "../../StudentComponent/pages/StudentTimeTable/StudentTimeTable";
import HODAttendance from "../../HODComponent/Pages/HODAttendance/HodAttendance";
import AddInstitute from "../../SuperAdminComponents/Pages/AddInstitute/AddInstitute";
import ListOfInstitute from "../../SuperAdminComponents/Pages/ListOfInstitute/ListOfInstitute";
import RegisterInstituteAdmin from "../../SuperAdminComponents/Pages/RegisterInstituteAdmin/RegisterInstituteAdmin";
import BatchManager from "../Batches/BatchManager";
import FacultyDashboard from "../../FacultyComponent/Pages/FacultyDashboard/FacultyDashboard";
import HODDashboard from "../../HODComponent/Pages/HODDashboard/HODDashboard";
import MyClassrooms from "../MyClassrooms/MyClassrooms";
import SuperAdminDashboard from "../../SuperAdminComponents/Pages/SuperAdminDashboard/SuperAdminDashboard";
import defaultUserImage from "../../../images/user (1).png";
// import TNPComponent from "../TNPComponent/TNPComponent";
import RecruiterDashboard from "../../RecruiterComponent/Pages/RecruiterDashboard/RecruiterDashboard";
import ViewStudents from "../TNPComponent/ViewStudents/ViewStudents";
// import UserRegistration from "../../SuperAdminComponents/Pages/UserRegistration/UserRegistration";
import Job from "../../RecruiterComponent/Pages/Job/Job";
import WorkIcon from "@mui/icons-material/Work";
import StudentJobBoard from "../../StudentComponent/pages/JobBoard/StudentJobBoard";
import InstituteAdminDashboard from "../../InstituteAdminComponent/Pages/InstituteAdminDashboard/InstituteAdminDashboard";

// const chatUserMessage = process.env.REACT_APP_CHAT_USER_MESSAGE_COUNT;
// const chatUserGroupMessageCount =
//   process.env.REACT_APP_CHAT_USER_GROUP_MESSAGE_COUNT;

const drawerWidth = 258;

const openedMixin = (theme) => ({
  width: 258,
  backgroundColor: "#6699CC",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: theme.zIndex.drawer,
});

const closedMixin = (theme) => ({
  backgroundColor: "#6699CC",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: theme.zIndex.drawer,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  backgroundColor: "#6699cc",
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    "& .tooltip-container .tooltip-text": {
      display: "none",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .tooltip-container .tooltip-text": {
      display: "block",
    },
  }),
}));

const structure = {
  superadmin: [
    {
      text: "Dashboard",
      icon: (
        <div className="tooltip-container">
          <DashboardCustomizeIcon className="Icon" title="Dashboard" />
          <span className="tooltip-text">Dashboard</span>
        </div>
      ),
      to: "/superadmin",
    },
    {
      text: "Institutes",
      icon: (
        <div className="tooltip-container">
          <img src={institute} alt="institute Icon" className="course" />
          <span className="tooltip-text">Institutes</span>
        </div>
      ),
      to: "/superadmin/institute-list",
    },
    {
      text: "Community",
      icon: (
        <div className="tooltip-container">
          <img src={community} alt="community Icon" className="course" />
          <span className="tooltip-text">Community</span>
        </div>
      ),
      to: "/superadmin/community",
    },
    {
      text: "Register Institute",
      icon: (
        <div className="tooltip-container">
          <img src={addInstitute} alt="addInstitute Icon" className="course" />
          <span className="tooltip-text">Register Institute</span>
        </div>
      ),
      to: "/superadmin/add-institute",
    },
    {
      text: "Register Institute Admin",
      icon: (
        <div className="tooltip-container">
          <img
            src={addInstituteAdmin}
            alt="addInstituteAdmin Icon"
            className="course"
          />
          <span className="tooltip-text">Register Institute Admin</span>
        </div>
      ),
      to: "/superadmin/admin-register",
    },
  ],
  instituteadmin: [
    {
      text: "Dashboard",
      icon: (
        <div className="tooltip-container">
          <DashboardCustomizeIcon className="Icon" />
          <span className="tooltip-text">Dashboard</span>
        </div>
      ),
      to: "/instituteadmin",
    },
    {
      text: "Department",
      icon: (
        <div className="tooltip-container">
          <ApartmentIcon className="Icon" />
          <span className="tooltip-text">Department</span>
        </div>
      ),
      to: "/instituteadmin/department",
    },
    {
      text: "Course",
      icon: (
        <div className="tooltip-container">
          <img src={course} alt="course Icon" className="course" />
          <span className="tooltip-text">Course</span>
        </div>
      ),
      to: "/instituteadmin/courses",
    },
    {
      text: "Community",
      icon: (
        <div className="tooltip-container">
          <img src={community} alt="community Icon" className="course" />
          <span className="tooltip-text">Community</span>
        </div>
      ),
      to: "/instituteadmin/community",
    },
    {
      text: "Register HOD",
      icon: (
        <div className="tooltip-container">
          <img
            src={addInstituteAdmin}
            alt="addInstituteAdmin Icon"
            className="course"
          />
          <span className="tooltip-text">Register HOD</span>
        </div>
      ),
      to: "/instituteadmin/hod-register",
    },
  ],
  hod: [
    {
      text: "Dashboard",
      icon: (
        <div className="tooltip-container">
          <DashboardCustomizeIcon className="Icon" />
          <span className="tooltip-text">Dashboard</span>
        </div>
      ),
      to: "/hod",
    },
    {
      text: "TimeTable",
      icon: (
        <div className="tooltip-container">
          <EditCalendarIcon className="Icon" />{" "}
          <span className="tooltip-text">TimeTable</span>
        </div>
      ),
      to: "/hod/create-time-table",
    },
    {
      text: "Attendance",
      icon: (
        <div className="tooltip-container">
          <img src={AttendanceIcon} alt="attendance Icon" className="course" />
          <span className="tooltip-text">Attendance</span>
        </div>
      ),
      to: "/hod/attendance",
    },
    {
      text: "Register User",
      icon: (
        <div className="tooltip-container">
          <img
            src={addInstituteAdmin}
            alt="addInstituteAdmin Icon"
            className="course"
          />
          <span className="tooltip-text">Register User</span>
        </div>
      ),
      to: "/hod/new-user-register",
    },
    {
      text: "Community",
      icon: (
        <div className="tooltip-container">
          <img src={community} alt="community Icon" className="course" />
          <span className="tooltip-text">Community</span>
        </div>
      ),
      to: "/hod/community",
    },
    {
      text: "Subjects ",
      icon: (
        <div className="tooltip-container">
          <img src={books} alt="Books" className="subject" />
          <span className="tooltip-text">Subjects</span>
        </div>
      ),
      to: "/hod/subjects",
    },
    {
      text: "Academics Batch",
      icon: (
        <div className="tooltip-container">
          <SchoolIcon className="Icon" />
          <span className="tooltip-text">Academics Batch</span>
        </div>
      ),
      to: "/hod/batches",
    },
    {
      text: "Classrooms",
      icon: (
        <div className="tooltip-container">
          <img src={classImage} alt="Classroom Icon" className="classroom" />
          <span className="tooltip-text">Classrooms</span>
        </div>
      ),
      to: "/hod/classrooms",
    },
    {
      text: "Course",
      icon: (
        <div className="tooltip-container">
          <img src={course} alt="course Icon" className="course" />
          <span className="tooltip-text">Course</span>
        </div>
      ),
      to: "/hod/courses",
    },
    // {
    //   text: "TNP Cell",
    //   icon: <Groups3Icon className="Icon" />,
    //   to: "/hod/tnp",
    // },
  ],
  recruiter: [
    {
      text: "Dashboard",
      icon: (
        <div className="tooltip-container">
          <DashboardCustomizeIcon className="Icon" />
          <span className="tooltip-text">Dashboard</span>
        </div>
      ),
      to: "/recruiter",
    },
    {
      text: "Filter Students",
      icon: (
        <div className="tooltip-container">
          <FilterListIcon className="Icon" />
          <span className="tooltip-text">Filter Students</span>
        </div>
      ),
      to: "/recruiter/view-students",
    },
    {
      text: "Job Board",
      icon: (
        <div className="tooltip-container">
          <WorkIcon className="Icon" />
          <span className="tooltip-text">Job Board</span>
        </div>
      ),
      to: "/recruiter/job",
    },
    // FilterListIcon
  ],
  faculty: [
    {
      text: "Dashboard",
      icon: (
        <div className="tooltip-container">
          <DashboardCustomizeIcon className="Icon" />
          <span className="tooltip-text">Dashboard</span>
        </div>
      ),
      to: "/faculty",
    },
    {
      text: "Attendance",
      icon: (
        <div className="tooltip-container">
          <img src={AttendanceIcon} alt="attendance Icon" className="course" />
          <span className="tooltip-text">Attendance</span>
        </div>
      ),
      to: "/faculty/attendance",
    },
    {
      text: "TimeTable",
      icon: (
        <div className="tooltip-container">
          <img src={TimeTableIcon} alt="TimeTable Icon" className="course" />
          <span className="tooltip-text">TimeTable</span>
        </div>
      ),
      to: "/faculty/time-table",
    },
    {
      text: "Community",
      icon: (
        <div className="tooltip-container">
          <img src={community} alt="community Icon" className="course" />
          <span className="tooltip-text">Community</span>
        </div>
      ),
      to: "/faculty/community",
    },
  ],
  student: [
    {
      text: "Dashboard",
      icon: (
        <div className="tooltip-container">
          <DashboardCustomizeIcon className="Icon" />
          <span className="tooltip-text">Dashboard</span>
        </div>
      ),
      to: "/student",
    },
    {
      text: "Attendance",
      icon: (
        <div className="tooltip-container">
          <img src={AttendanceIcon} alt="attendance Icon" className="course" />
          <span className="tooltip-text">Attendance</span>
        </div>
      ),
      to: "/student/attendance",
    },
    {
      text: "TimeTable",
      icon: (
        <div className="tooltip-container">
          <img src={TimeTableIcon} alt="TimeTable Icon" className="course" />
          <span className="tooltip-text">TimeTable</span>
        </div>
      ),
      to: "/student/time-table",
    },
    {
      text: "Community",
      icon: (
        <div className="tooltip-container">
          <img src={community} alt="community Icon" className="course" />
          <span className="tooltip-text">Community</span>
        </div>
      ),
      to: "/student/community",
    },
    // {
    //   text: "Job Board",
    //   icon: (
    //     <div className="tooltip-container">
    //       <WorkIcon className="Icon" />
    //       <span className="tooltip-text">Job Board</span>
    //     </div>
    //   ),
    //   to: "/student/job",
    // },
  ],
};

export default function AdminSidebar({ logout, user }) {
  const navigationItems = structure[user] || [];
  const { Cookies, fetchData1, showConfirmation } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [storedImg, setStoredImg] = useState(
    localStorage.getItem("profileImage") || null
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  let UserImag = localStorage.getItem("profileImage");

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // const [selectedUser, setSelectedUser] = useState(null);
  // const [showUserList, setShowUserList] = useState(false);
  // const [showGroupPopup, setShowGroupPopup] = useState(false);
  // const [messageCount, setMessageCount] = useState(0);
  // const [msgCount, setMsgCount] = useState();
  // const [groupMessageCount, setgroupMessageCount] = useState(0);
  // const [groupMsgCount, setgroupMsgCount] = useState();

  // check user is student or not
  // const isStudent = user === "student";

  const handleLogout = () => {
    Cookies.remove("token");
    localStorage.clear();

    navigate("/login");
  };

  const handleProfileImageChange = (newImage) => {
    localStorage.setItem("profileImage", newImage);
    setStoredImg(newImage);
  };

  const handleStorageChange = () => {
    const updatedImage = localStorage.getItem("profileImage");
    setStoredImg(updatedImage);
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    handleStorageChange();
  }, []);

  useEffect(() => {
    setStoredImg(localStorage.getItem("profileImage"));
  }, [localStorage.getItem("profileImage")]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // const fetchMessageCount = async () => {
  //   try {
  //     const token = Cookies.get("token");

  //     const messageCountResponse = await fetch(
  //       `${BASE_URL}${chatUserMessage}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (messageCountResponse.ok) {
  //       const messageCountData = await messageCountResponse.json();
  //       setMessageCount(messageCountData);
  //       setMsgCount(messageCountData.count.length);
  //     } else {
  //     }
  //   } catch (error) {}
  // };

  // const fetchGroupMessageCount = async () => {
  //   try {
  //     const token = Cookies.get("token");

  //     const response = await fetch(`${BASE_URL}${chatUserGroupMessageCount}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       setgroupMessageCount(data.userGroups);

  //       let totalCount = 0;
  //       data.userGroups.forEach((item) => {
  //         totalCount += item.count;
  //       });

  //       setgroupMsgCount(totalCount);
  //     } else {
  //     }
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   fetchMessageCount();
  // }, []);

  // const handleUserSelect = (user) => {
  //   setSelectedUser(user);
  // };

  // const handleCloseChatBox = () => {
  //   setSelectedUser(null);
  // };

  // const toggleUserList = () => {
  //   setShowUserList(!showUserList);
  //   setShowGroupPopup(false);
  // };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const drawer = (
    <div>
      <List>
        {navigationItems.map(({ text, icon, to }, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{ display: "block", color: "white" }}
          >
            <ListItemButton
              component={Link}
              to={to}
              // onClick={handleDrawerToggle}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setIsMobileMenuOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setIsMobileMenuOpen(true);
  };

  const HandleNavigate = () => {
    // fetchData();
    navigate(`/${user}/profile`);
    handleMenuClose();
    return null;
  };

  // const handleClickOpen = () => {
  //   setOpenDialog(true);
  // };

  useEffect(() => {
    const storedImageData = localStorage.getItem("profileImage");
    if (storedImageData) {
      setStoredImg(storedImageData);
    } else {
      setStoredImg(null);
    }
  }, []);

  useEffect(() => {
    handleMenuClose();
  }, [location]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={HandleNavigate}>My account</MenuItem>
      <MenuItem
        onClick={() => {
          showConfirmation("want to Logout ?", handleLogout);
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem
        onClick={() => {
          toggleUserList();
          fetchGroupMessageCount();
          // fetchMessageCount();
          fetchData1();
          handleMobileMenuClose();
        }}
      >
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={23} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {/* <Tooltip title="Open settings"> */}
          {/* <div className="tooltip-container"> */}
          <Avatar
            src={
              storedImg && storedImg !== "null"
                ? `data:image/png;base64,${storedImg}`
                : defaultUserImage
            }
          />
          {/* <span className="tooltip-profileText">Profile</span>
            </div> */}
          {/* </Tooltip> */}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: "white" }}>
          <IconButton
            color="black"
            edge="start"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              paddingRight: "100px",
              gap: "0.2rem",
            }}
          >
            {/* <IconButton
              size="large"
              // aria-label="show 4 new mails"
              color="black"
              onClick={() => {
                toggleUserList();
                fetchGroupMessageCount();
                // fetchMessageCount();
                fetchData1();
              }}
            >
              <Badge badgeContent={msgCount} color="error">
                <MailIcon />
              </Badge>
            </IconButton> */}

            <div className="tooltip-container">
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="#2B4157"
              >
                {/* <Tooltip title="Open settings"> */}
                <Avatar
                  src={
                    storedImg && storedImg !== "null"
                      ? `data:image/png;base64,${storedImg}`
                      : defaultUserImage
                  }
                />
                {/* </Tooltip> */}
              </IconButton>
              <span className="tooltip-profileText">Profile</span>
            </div>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="#2B4157"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <Drawer
        variant="permanent"
        open={open}
        sx={open ? styles.drawerStyled : styles.hideDrawer}
      >
        <Toolbar sx={styles.toolBarStyled}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon sx={{ color: "white" }} />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">{drawer}</List>
      </Drawer>

      {!loading && (
        <Box
          component="main"
          sx={{ flexGrow: 1 }}
          role="presentation"
          onClick={handleDrawerClose}
        >
          <DrawerHeader />
          {user === "superadmin" && (
            <Routes>
              <Route path="/*" element={<SuperAdminDashboard />}></Route>
              <Route path="/add-institute" element={<AddInstitute />}></Route>
              <Route
                path="/institute-list"
                element={<ListOfInstitute />}
              ></Route>
              <Route
                path="/admin-register"
                element={<RegisterInstituteAdmin />}
                // element={<UserRegistration />}
              ></Route>
              {/* <Route
                path="/list-of-institute/institute-home-page/:id"
                element={<InstituteHomePage />}
              ></Route> */}
              <Route path="/community/*" element={<CommunityPage />}></Route>
              <Route
                path="/profile/*"
                element={<Profile user={user} />}
              ></Route>
            </Routes>
          )}

          {user === "instituteadmin" && (
            <Routes>
              <Route path="/*" element={<InstituteAdminDashboard />}></Route>
              <Route path="department/*" element={<Department />}></Route>
              <Route path="courses/*" element={<Courses />}></Route>
              <Route
                path="hod-register/*"
                element={<HodRegistration user={user} />}
              ></Route>
              <Route path="profile/*" element={<Profile user={user} />}></Route>
              <Route path="/community/*" element={<CommunityPage />}></Route>
              <Route
                path="/classrooms/*"
                element={<MyClassrooms user={user} />}
              ></Route>
              <Route
                path="/batches/*"
                element={<BatchManager user={user} />}
              ></Route>
            </Routes>
          )}

          {user === "hod" && (
            <Routes>
              <Route path="/" element={<HODDashboard />}></Route>
              <Route path="/courses/*" element={<Courses />}></Route>
              <Route path="/hod-dashboard/*" element={<HODDashboard />}></Route>
              <Route path="/attendance/*" element={<HODAttendance />}></Route>
              <Route path="/community/*" element={<CommunityPage />}></Route>
              <Route path="subjects/*" element={<SubjectPage />}></Route>
              <Route
                path="new-user-register/*"
                element={<AddUser user={user} />}
              ></Route>
              <Route
                path="create-time-table/*"
                element={<CreateTimeTable user={user} />}
              ></Route>

              <Route
                path="/profile/*"
                element={<Profile user={user} />}
              ></Route>
              <Route
                path="/classrooms/*"
                element={<MyClassrooms user={user} />}
              ></Route>
              <Route
                path="/batches/*"
                element={<BatchManager user={user} />}
              ></Route>
              {/* <Route
                path="/tnp/*"
                element={<TNPComponent user={user} />}
              ></Route> */}
            </Routes>
          )}

          {user === "recruiter" && (
            <Routes>
              <Route path="/" element={<RecruiterDashboard />}></Route>
              <Route path="/view-students" element={<ViewStudents />}></Route>
              <Route
                path="/profile/*"
                element={<Profile user={user} />}
              ></Route>
              <Route path="/job/*" element={<Job user={user} />}></Route>
            </Routes>
          )}

          {user === "faculty" && (
            <Routes>
              <Route path="/" element={<FacultyDashboard />}></Route>
              <Route
                path="/faculty-dashboard/*"
                element={<FacultyDashboard />}
              ></Route>
              <Route path="/community/*" element={<CommunityPage />}></Route>
              <Route
                path="/profile/*"
                element={<Profile user={user} />}
              ></Route>
              <Route path="/attendance/*" element={<Attendance />}>
                {" "}
              </Route>
              <Route
                path="/time-table/*"
                element={<FacultyTimeTable />}
              ></Route>
            </Routes>
          )}

          {user === "student" && (
            <Routes>
              <Route path="/" element={<StudentDashboard />}></Route>
              <Route
                path="/student-dashboard/*"
                element={<StudentDashboard />}
              ></Route>
              <Route
                path="/time-table/*"
                element={<StudentTimeTable />}
              ></Route>
              <Route
                path="/profile/*"
                element={<Profile user={user} />}
              ></Route>
              <Route
                path="/attendance/*"
                element={<StudentAttendance user={user} />}
              ></Route>
              <Route
                path="/job/*"
                element={<StudentJobBoard user={user} />}
              ></Route>
              <Route path="/community/*" element={<CommunityPage />}></Route>
            </Routes>
          )}
        </Box>
      )}
      {/* {showUserList && (
        <ChatPrimaryBox
          handleUserSelect={handleUserSelect}
          isVisible={showUserList}
          toggleVisibility={toggleUserList}
          messageCount={messageCount}
          msgCount={msgCount}
          fetchMessageCount={fetchMessageCount}
        />
      )}
      {selectedUser && (
        <ChatPersonal
          selectedUser={selectedUser}
          onClose={handleCloseChatBox}
        />
      )} */}
    </Box>
  );
}

const styles = {
  boxStyled: {
    backgroundColor: (theme) =>
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  toolBarStyled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    px: [1],
  },
  drawerStyled: {
    display: "flex",
  },
  hideDrawer: {
    display: "flex",
    "@media (max-width: 900px)": {
      display: "none",
    },
  },
};
