import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../ToastContainer/ToastContainer";
import styles from "./EditBatch.module.css";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "./../../../../config/config";

const getBatchDetails = process.env.REACT_APP_ADMIN_GET_BATCH_DETAILS;
const updateBatchDetails = process.env.REACT_APP_ADMIN_UPDATE_BATCH_DETAILS;
const updateSection = process.env.REACT_APP_ADMIN_UPDATE_SECTION_NAME;

const EditBatch = () => {
  const { getToken, showConfirmation } = useContext(UserContext);
  const [batchDetails, setBatchDetails] = useState({
    batchId: "",
    batchName: "",
    courseName: "",
    startDate: "",
    endDate: "",
    description: "",
  });
  const [sections, setSections] = useState([]);
  const { batchId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [sectionToEdit, setSectionToEdit] = useState(null);
  const [newSectionName, setNewSectionName] = useState("");
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    validateForm();
  }, [batchDetails]);

  const handleBatchDetailChange = (e) => {
    const { name, value } = e.target;
    setBatchDetails({ ...batchDetails, [name]: value });
  };

  const fetchBatchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${getBatchDetails}/${batchId}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        const batch = {
          batchId: response.data?.data?.batchId,
          batchName: response.data?.data?.batchName,
          courseName: response.data?.data?.courseName,
          startDate: response.data?.data?.startDate,
          endDate: response.data?.data?.endDate,
          description: response.data?.data?.description,
        };
        setBatchDetails({ ...batch });
        setSections(() => response?.data?.data?.sections);
      }
    } catch (error) {
    }
  };

  const updateBasicDetails = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      const response = await axios.put(
        `${BASE_URL}${updateBatchDetails}`,
        batchDetails,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        Toast.success(response.data.message || "Success !");
        fetchBatchData();
        navigate(-1)
      }
    } catch (error) {
      Toast.error(
        error?.response?.data?.message || "Error fetching batch data!"
      );
    }
  };

  const updateSectionName = async () => {
    try {
      const dataToUpdate = {
        sectionId: sectionToEdit.sectionId,
        sectionName: newSectionName,
        batchId: batchDetails.batchId,
      };

      const response = await axios.put(
        `${BASE_URL}${updateSection}`,
        dataToUpdate,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        Toast.success(response.data.message || "Success!");
        handleDialogClose();
        fetchBatchData();
      }
    } catch (error) {
      Toast.error(
        error?.response?.data?.message || "Error fetching batch data!"
      );
      handleDialogClose();
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "courseName",
      "batchName",
      "startDate",
      "endDate",
      "description",
    ];

    requiredFields.forEach((field) => {
      const value = batchDetails[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });


    const startDateValue = batchDetails.startDate;
    if (!startDateValue) {
      validationErrors.startDate = "**This field is required.**";
    } else {
      const startDate = dayjs(startDateValue);
      if (!startDate.isValid()) {
        validationErrors.startDate = "**Invalid date format**";
      } else if (startDate.isAfter(dayjs(), "day")) {
        validationErrors.startDate = "**Date cannot be in the future**";
      }
    }

    const endDateValue = batchDetails.endDate;
    if (!endDateValue) {
      validationErrors.endDate = "**This field is required.**";
    } else {
      const endDate = dayjs(endDateValue);
      if (!endDate.isValid()) {
        validationErrors.endDate = "**Invalid date format**";
      } else if (endDate.isBefore(dayjs(startDateValue))) {
        validationErrors.endDate = "**End Date must be after Start Date.**";
      }
    }

    // Validation for batchName length
    const batchName = batchDetails.batchName;
    if (batchName && batchName.length > 30) {
      validationErrors.batchName = "**Batch Name must be less than 30 characters.**";
    }

    // Validation for description length
    const description = batchDetails.description;
    if (description && description.length > 70) {
      validationErrors.description = "**Description must be less than 70 characters.**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };


  const handleDialogClose = () => {
    setOpenDialog(false);
    setSectionToEdit(null);
    setSelectedSectionId("");
  };

  const handleAddStudentToSection = () => {
    if (selectedSectionId) {
      const updatedSections = sections.map((section) => {
        if (section.sectionId === selectedSectionId) {
          return {
            ...section,
            students: [...section.students, selectedStudent],
          };
        }
        return section;
      });

      setSections(updatedSections);
      handleDialogClose();
    }
  };

  const handleEditSection = (section) => {
    setSectionToEdit(section);
    setNewSectionName(section.sectionName);
  };
  const setDate = (value, name) => {
    setBatchDetails({
      ...batchDetails,
      [name]: value ? new Date(value) : null,
    });
  };

  useEffect(() => {
    fetchBatchData();
  }, []);

  return (
    <>
      <Box className={styles.formContainer}>
        <Box className={styles.closeIconContainer}>
          <IconButton onClick={() => {
            navigate(-1)
          }}
            className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>

        <h2 className={styles.formContainerTitle}>
          EDIT BATCH
        </h2>

        <div className={styles.editBatchScrollable}>
          <form >

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="courseName">Course Name</label>
                  <input
                    type="text"
                    id="courseName"
                    name="courseName"
                    placeholder="Course Name"
                    value={batchDetails.courseName}
                    disabled
                    className={styles.disabled}
                  />
                  {errors.courseName && <p className={styles.error}>{errors.courseName}</p>}

                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="batchName">Batch Name</label>
                  <input
                    type="text"
                    id="batchName"
                    name="batchName"
                    placeholder="Batch Name"
                    value={batchDetails.batchName}
                    onChange={handleBatchDetailChange}
                  />
                  {errors.batchName && <p className={styles.error}>{errors.batchName}</p>}
                </div>
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="startDate">Start Date</label>
                    <DesktopDatePicker
                      className={styles.datePicker}
                      value={dayjs(batchDetails.startDate)}
                      onChange={(newValue) => setDate(newValue, "startDate")}
                    />
                    {errors.startDate && <p className={styles.error}>{errors.startDate}</p>}
                  </div>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="endDate">End Date</label>
                    <DesktopDatePicker
                      className={styles.datePicker}
                      value={dayjs(batchDetails.endDate)}
                      onChange={(newValue) => setDate(newValue, "endDate")}
                    />
                    {errors.endDate && <p className={styles.error}>{errors.endDate}</p>}
                  </div>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={batchDetails.description}
                    onChange={handleBatchDetailChange}
                  />
                  {errors.description && <p className={styles.error}>{errors.description}</p>}

                </div>
              </Grid>

              <Grid item xs={12} className={styles.saveButtonContainer}>
                <Button
                  variant="contained"
                  className={styles.saveButton}
                  color="primary"
                  disabled={isSaveDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    showConfirmation("want to Update Batch ? ", updateBasicDetails, e)
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            <div className={styles.accordionContainer}>
              <h3 className={styles.accordionContainerTitle}>
                YOUR PRACTICAL BATCHES
              </h3>
              {sections &&
                sections?.map((section, index) => (
                  <Accordion key={index} className={styles.theAccordion}>
                    <AccordionSummary className={styles.accordionContent} expandIcon={<ExpandMoreIcon />}>
                      <h4>
                        {index + 1}. {section.sectionName}
                      </h4>
                      <EditIcon onClick={() => handleEditSection(section)} className={styles.accordionEdit} />
                    </AccordionSummary>

                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Sr No</TableCell>
                            <TableCell>Enrollment Id</TableCell>
                            <TableCell>Student Name</TableCell>
                          </TableRow>
                          {section.students.map((student, index) => (
                            <TableRow key={student.enrollmentId}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{student.enrollmentId}</TableCell>
                              <TableCell>{student.studentName}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>

                  </Accordion>
                ))}
            </div>

            <Dialog
              open={sectionToEdit !== null}
              onClose={() => setSectionToEdit(null)}>
              <div style={{ paddingTop: "1rem" }}>
                <div className={styles.inputGroup}>
                  <label htmlFor="sectionName">Rename Section</label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    placeholder="sectionName"
                    value={newSectionName}
                    onChange={(e) => setNewSectionName(e.target.value)}
                  />
                </div>
              </div>

              <DialogActions>
                <Button onClick={() => setSectionToEdit(null)} color="primary">
                  Cancel
                </Button>
                <Button onClick={updateSectionName} color="primary">
                  Rename
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </div >


        < Dialog open={openDialog} onClose={handleDialogClose} >
          <DialogTitle>Add Student to Section</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="select-section-label">Select Section</InputLabel>
              <Select
                labelId="select-section-label"
                value={selectedSectionId}
                onChange={(e) => setSelectedSectionId(e.target.value)}
              >
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddStudentToSection} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog >
      </Box >
    </>
  );
};

export default EditBatch;
