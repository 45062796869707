import React from "react";
import Sidebar from "../../CommonComponent/Sidebar/Sidebar";
import { Routes, Route } from "react-router-dom";
import styles from "../HODDesktop/HODDesktop.module.css";
import ManualAttendance from "../../CommonComponent/Attendance/ManualAttendance/ManualAttendance";

function HODDesktop({ logout, user }) {
  return (
    <div>
      <Sidebar logout={logout} user={user} />
      <div>
        <Routes>
          <Route
            path="takeAttendance/:lectureId"
            element={<ManualAttendance />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default HODDesktop;
