import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import styles from "./VUPSkills.module.css";
import AddIcon from "@mui/icons-material/Add";
import { Modal, Button, Box, IconButton } from "@mui/material";
import VUPEditSkills from "./VUPEditSkills/VUPEditSkills";
import UserContext from "../../../../../Context/UserContext";
import Toast from "../../../ToastContainer/ToastContainer";
import { BASE_URL } from "../../../../../config/config";
import skillsIcon from "../../../../../images/learning (1).png";
import skillLevel from "../../../../../images/level-up.png";
import skillType from "../../../../../images/pencil.png";
import hobbyIcon from "../../../../../images/hobby.png";
import languageIcon from "../../../../../images/languages.png";
import VUPEditHobbies from "./VUPEditHobbies/VUPEditHobbies";
import DeleteIcon from "@mui/icons-material/Delete";
import VUPEditLanguages from "./VUPEditLanguages/VUPEditLanguages";
import { useParams } from "react-router-dom";

const getSkills = process.env.REACT_APP_VUP_ADMIN_GET_SKILLS;
const addSkills = process.env.REACT_APP_VUP_ADMIN_ADD_SKILLS;
const deleteSkills = process.env.REACT_APP_VUP_ADMIN_DELETE_SKILLS;
const getHobbies = process.env.REACT_APP_VUP_ADMIN_GET_HOBBIES;
const addHobby = process.env.REACT_APP_VUP_ADMIN_ADD_Hobby;
const deleteHobby = process.env.REACT_APP_VUP_ADMIN_DELETE_Hobby;
const getLanguages = process.env.REACT_APP_VUP_ADMIN_GET_LANGUAGES;
const addLanguages = process.env.REACT_APP_VUP_ADMIN_ADD_LANGUAGE;
const deleteLanguage = process.env.REACT_APP_VUP_ADMIN_DELETE_LANGUAGE;
const showSkillsDropdown = process.env.REACT_APP_DROPDOWN_SHOW_SKILLS_DROPDOWN;
const showHobbiesDropdown =
  process.env.REACT_APP_DROPDOWN_SHOW_HOBBIES_DROPDOWN;
const showLanguagesDropdown =
  process.env.REACT_APP_DROPDOWN_SHOW_LANGUAGES_DROPDOWN;

const VUPSkills = ({isEditable}) => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [modalType, setModalType] = useState("");
  const {enrollmentId} = useParams();

  useEffect(() => {
    fetchSkillsData();
    fetchHobbiesData();
    fetchLanguagesData();
  }, []);

  // Fetch skills data
  const fetchSkillsData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getSkills}/${enrollmentId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setTechnicalSkills(response.data.data);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Fetch hobbies data
  const fetchHobbiesData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getHobbies}/${enrollmentId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setHobbies(response.data.data);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Fetch Languages data
  const fetchLanguagesData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getLanguages}/${enrollmentId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setLanguages(response?.data?.data || []);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Handle opening modal for Skill,Hobby and Language
  const handleOpenModal = (data, type) => {
    setSelectedData(data);
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
    setModalType("");
  };

  // Handle adding skills
  const handleAddSkill = async (data) => {
    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${addSkills}/${enrollmentId}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.data.result === true) {
        setTechnicalSkills((prevSkills) => [
          ...prevSkills,
          { ...data, _id: response.data.skillId },
        ]);
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      handleCloseModal();
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Handle adding hobbies
  const handleAddHobby = async (data) => {
    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${addHobby}/${enrollmentId}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.data.result === true) {
        setHobbies(() => response.data.data);
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      handleCloseModal();
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Handle adding languages
  const handleAddLanguage = async (data) => {
    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${addLanguages}/${enrollmentId}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.data.result === true) {
        setLanguages(() => response.data.data);
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      handleCloseModal();
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Handle deleting skills
  const handleDeleteSkill = async (_id) => {
    try {
      setShowLoader(true);
      const response = await axios.delete(`${BASE_URL}${deleteSkills}/${enrollmentId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        data: { id: _id },
      });

      if (response.data.result) {
        setTechnicalSkills((prevSkills) =>
          prevSkills.filter((skill) => skill._id !== _id)
        );
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Handle deleting hobbies
  const handleDeleteHobby = async (hobby) => {
    try {
      setShowLoader(true);
      const response = await axios.delete(`${BASE_URL}${deleteHobby}/${enrollmentId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        data: { hobbyName: hobby },
      });

      if (response.data.result) {
        setHobbies(response.data.data);
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  // Handle deleting languages
  const handleDeleteLanguage = async (language) => {
    try {
      setShowLoader(true);
      const response = await axios.delete(`${BASE_URL}${deleteLanguage}/${enrollmentId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        data: { languageName: language },
      });

      if (response.data.result) {
        setLanguages(response.data.data);

        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className={styles.parentContainer}>
      {/* Skills Section */}
      <div className={styles.skillsSection}>
        <div className={styles.skillsContainer}>
          <h2 className={styles.headingSkills}>
            Skills{" "}
            <img src={skillsIcon} alt="Skills Icon" className={styles.icon} />
          </h2>
          {technicalSkills.length > 0 ? (
            <div className={styles.skillsList}>
              {technicalSkills.map((skillData, index) => (
                <div className={styles.yourSkills} key={skillData._id}>
                  <h3>
                    {index + 1}. {skillData.skill}{" "}
                  </h3>
                  <hr className={styles.horizontalRow} />
                  <Box className={styles.closeIconContainer}>
                    {isEditable && (
                      <IconButton
                        onClick={() =>
                          showConfirmation(
                            "want to delete skill?",
                            handleDeleteSkill,
                            skillData._id
                          )
                        }
                      >
                        <DeleteIcon className={styles.deleteSkillButton} />
                      </IconButton>
                    )}
                  </Box>
                  <div className={styles.skillContent}>
                    <div className={styles.skillStats}>
                      <p>
                        {" "}
                        <img
                          src={skillType}
                          alt="Skill Type Icon"
                          className={styles.aboutIcon}
                        />{" "}
                        <span>Skill Type: </span> {skillData.type}
                      </p>
                      <p>
                        {" "}
                        <img
                          src={skillLevel}
                          alt="Skill Level Icon"
                          className={styles.aboutIcon}
                        />{" "}
                        <span>Level: </span> {skillData.level}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className={styles.noData}>
              No technical skills records available.
            </p>
          )}
          {isEditable && (
            <Button
              variant="contained"
              onClick={() => handleOpenModal(null, "skill")}
              className={styles.addButton}
            >
              <AddIcon />
              <span>Add Skills</span>
            </Button>
          )}
        </div>
      </div>

      {/* Hobbies Section */}
      <div className={styles.hobbiesSection}>
        <h2 className={styles.headingHobbies}>
          Hobbies{" "}
          <img src={hobbyIcon} alt="Hobbies Icon" className={styles.icon} />
        </h2>
        {hobbies.length > 0 ? (
          <div className={styles.hobbiesList}>
            <h3>Hobby List</h3>
            <hr className={styles.horizontalRow} />
            {hobbies.map((hobby, index) => (
              <div className={styles.yourHobbies} key={index}>
                <p>
                  {" "}
                  {index + 1}. {hobby}
                </p>
                <Box className={styles.deleteHobbieContainer}>
                  {isEditable && (
                    <IconButton
                      className={styles.deleteHobbyButton}
                      onClick={() =>
                        showConfirmation(
                          "want to delete hobby?",
                          handleDeleteHobby,
                          hobby
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </div>
            ))}
          </div>
        ) : (
          <p className={styles.noData}>No hobbies records available.</p>
        )}
        {isEditable && (
          <Button
            variant="contained"
            onClick={() => handleOpenModal(null, "hobby")}
            className={styles.addButton}
          >
            <AddIcon />
            <span>Add Hobby</span>
          </Button>
        )}
      </div>

      {/* Language Section */}
      <div className={styles.languagesSection}>
        <h2 className={styles.headingLanguages}>
          Language{" "}
          <img src={languageIcon} alt="Language Icon" className={styles.icon} />
        </h2>
        {languages?.length > 0 ? (
          <div className={styles.languagesList}>
            <h3>Language List</h3>
            <hr className={styles.horizontalRow} />
            {languages &&
              languages?.map((language, index) => (
                <div className={styles.yourLanguages} key={index}>
                  <p>
                    {" "}
                    {index + 1}. {language}
                  </p>
                  <Box className={styles.deleteLanguageContainer}>
                    {isEditable && (
                      <IconButton
                        className={styles.deleteLanguageButton}
                        onClick={() =>
                          showConfirmation(
                            "want to delete hobby?",
                            handleDeleteLanguage,
                            language
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </div>
              ))}
          </div>
        ) : (
          <p className={styles.noData}>No languages records available.</p>
        )}
        {isEditable && <Button
          variant="contained"
          onClick={() => handleOpenModal(null, "language")}
          className={styles.addButton}
        >
          <AddIcon />
          <span>Add Language</span>
        </Button>}
      </div>

      {/* Modal for adding/editing */}
      <Modal
        className={styles.modal}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        {modalType === "skill" ? (
          <VUPEditSkills
            skillData={selectedData}
            handleAddSkill={handleAddSkill}
            handleCloseModal={handleCloseModal}
            dropdownEndpoint={showSkillsDropdown}
          />
        ) : modalType === "hobby" ? (
          <VUPEditHobbies
            hobbyData={selectedData}
            handleAddHobby={handleAddHobby}
            handleCloseModal={handleCloseModal}
            dropdownEndpoint={showHobbiesDropdown}
          />
        ) : (
          <VUPEditLanguages
            languageData={selectedData}
            handleAddLanguage={handleAddLanguage}
            handleCloseModal={handleCloseModal}
            dropdownEndpoint={showLanguagesDropdown}
            className={styles.modal}
          />
        )}
      </Modal>
    </div>
  );
};

export default VUPSkills;
