import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Box,
  Grid,
  TableContainer,
  Dialog,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DropDown from "../../DropDown/DropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./CreatePracticalBatch.module.css";
import UserContext from "../../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Toast from "../../ToastContainer/ToastContainer";
import axios from "axios";
import { BASE_URL } from "./../../../../config/config";

const addPracticalBatch = process.env.REACT_APP_ADMIN_ADD_PRACTICAL_BATCH;
const getStudentBySection = process.env.REACT_APP_ADMIN_GET_STUDENT_BY_SECTION;
const savePracticalBatch = process.env.REACT_APP_ADMIN_SAVE_PRACTICAL_BATCH;

const CreatePracticalBatch = () => {
  const { setShowLoader, getToken, showConfirmation, setCourseCode,
    setBatchId,
    setSectionId, } = useContext(UserContext);
  const [dropDownData, setDropDownData] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [batchDropdown, setBatchDropdown] = useState([]);
  const [sectionDropdown, setSectionDropdown] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({
    courseName: "",
    batchName: "",
    sectionName: "",
  });
  const [formData, setFormData] = useState({
    batchId: "",
    sectionId: "",
    courseCode: "",
    departmentCode: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [batchSize, setBatchSize] = useState(5);
  const [batches, setBatches] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [batchNames, setBatchNames] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentBatchName, setCurrentBatchName] = useState("");
  const [showBatchForm, setShowBatchForm] = useState(false);


  const navigate = useNavigate();

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${addPracticalBatch}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response?.data?.result) {
        setDropDownData(() => response?.data?.data);
      }
      if (response.data?.data?.departmentCode) {
        setFormData({
          ...formData,
          departmentCode: response.data?.data?.departmentCode,
        });
      }
      setShowLoader(false);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");

      setShowLoader(false);
    }
  };

  const fetchStudents = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${getStudentBySection}?courseCode=${formData.courseCode}&batchId=${formData.batchId}&sectionId=${formData.sectionId}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response?.data?.result) {
        setStudentList(() => response.data?.data?.studentList);
        setStudents(() => response.data?.data?.studentList);
      }
    } catch (error) {
      Toast.error(error.response.data.message || "Something Went Wrong");
    }
  };

  const handleSaveBatches = async (e) => {
    try {
      formData.batches = batches
      formData.batchNames = batchNames
      setShowLoader(true)
      const response = await axios.post(`${BASE_URL}${savePracticalBatch}`, formData, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        Toast.success(response.data.message || 'Batches Added Successfuly...!')
      }
      setShowLoader(false)

    } catch (error) {
      setShowLoader(false)
      Toast.error(error.response.data.message || 'Something Went Wrong !')
    }
  }


  useEffect(() => {
    if (formData.sectionId) {
      fetchStudents();
    }
  }, [formData.sectionId]);

  useEffect(() => {

    if (!formData.courseCode) {
      setBatchDropdown([]);
      setSectionDropdown([]);
      setFormData({
        ...formData,
        batchId: "",
        sectionId: "",
      });
      return;
    }

    const filteredBatch = dropDownData?.batchList?.filter(
      (data) => data.value === formData.courseCode
    );
    setSelectedLabel({ ...selectedLabel, courseName: filteredBatch[0]?.label });
    setBatchDropdown(() => filteredBatch[0].batches || []);
  }, [formData.courseCode]);

  useEffect(() => {
    if (!formData.batchId) {
      setSectionDropdown([]);
      setFormData({ ...formData, sectionId: "" });
      return;
    }
    const filteredSection = batchDropdown?.filter(
      (data) => data.value === formData.batchId
    );
    setSelectedLabel({
      ...selectedLabel,
      batchName: filteredSection[0]?.label,
    });

    setSectionDropdown(() => filteredSection[0].sections || []);
  }, [formData.batchId]);

  useEffect(() => {
    if (formData.sectionId) {
      const filtered = sectionDropdown.filter(
        (data) => data.value === formData.sectionId
      );
      setSelectedLabel({ ...selectedLabel, sectionName: filtered[0]?.label });
    }
  }, [formData.sectionId]);

  useEffect(() => {
    fetchDropdown();
  }, []);

  const isAllDropdownsSelected = () => {
    return (
      formData.courseCode &&
      formData.batchId &&
      formData.sectionId
    );
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "courseCode") {
      setCourseCode(value)
    }
    if (name === "batchId") {
      setBatchId(value)
    }
    if (name === "sectionId") {
      setSectionId(value)
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBatchSizeChange = (e) => {
    setBatchSize(Number(e.target.value) || "");
  };

  const toggleStudentSelection = (studentId) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((enrollmentId) => enrollmentId !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const toggleSelectAll = () => {
    const currentStudentsIds = currentStudents.map((student) => student.enrollmentId);
    const allSelected = selectedStudents.length === currentStudentsIds.length;

    setSelectedStudents((prevSelected) =>
      allSelected
        ? prevSelected.filter((id) => !currentStudentsIds.includes(id))
        : [...prevSelected, ...currentStudentsIds]
    );
  };


  const createBatches = () => {
    const newBatches = [];
    if (selectedStudents.length < 1) {
      Toast.warning(`No Students Selected !`)
      return
    }
    for (let i = 0; i < selectedStudents.length; i += batchSize) {
      newBatches.push(selectedStudents.slice(i, i + batchSize));
    }
    setBatches([...batches, ...newBatches]);

    setBatchNames([...batchNames, `${selectedLabel.sectionName} ${batches.length + 1}`]);
    setSelectedStudents([]);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const setPracticalBatchName = () => {
    const newList = batchNames.map((name, index) => {
      if (index === currentIndex) {
        return currentBatchName;
      } else {
        return name
      }
    })

    setBatchNames([...newList]);
    setOpenDialog(false);
  }
  const excludedStudents = batches.flat();
  const filteredStudents = students.filter(
    (student) => !excludedStudents.includes(student.enrollmentId)
  );

  const indexOfLastStudent = (currentPage + 1) * batchSize;
  const indexOfFirstStudent = indexOfLastStudent - batchSize;
  const currentStudents = filteredStudents.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  const handleNavigateToPracticalBatches = () => {
    navigate("../view-practical-batches");

  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.formContainer}>
        <Box className={styles.closeIconContainer}>
          <ArrowBackIcon
            className={styles.previousButton}
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box>

        <h2 className={styles.formContainerTitle}>Search Students</h2>
        <form className={styles.formSection}>
          <Box className={styles.dropDownParentContainer}>
            <Box className={styles.inputGroup}>
              <label htmlFor="courseName" className={styles.dropDownLabel}>
                Course Name
              </label>
              <DropDown
                name={"courseCode"}
                value={formData?.courseCode}
                options={dropDownData?.courseList}
                onChange={handleChange}
                className={styles.dropDownSelect}
              />
            </Box>

            <Box className={styles.inputGroup}>
              <label htmlFor="batch" className={styles.dropDownLabel}>
                Batch
              </label>
              <DropDown
                name={"batchId"}
                value={formData?.batchId}
                options={batchDropdown || []}
                onChange={handleChange}
                className={styles.dropDownSelect}
              />
            </Box>

            <Box className={styles.inputGroup}>
              <label htmlFor="section" className={styles.dropDownLabel}>
                Section
              </label>
              <DropDown
                name={"sectionId"}
                value={formData?.sectionId}
                options={sectionDropdown || []}
                onChange={handleChange}
                className={styles.dropDownSelect}
              />
            </Box>
          </Box>
        </form>

        <Box className={styles.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowBatchForm(!showBatchForm)}
            disabled={!isAllDropdownsSelected()}
          >
            Create Practical Batch
          </Button>

          <Button
            variant="contained"
            onClick={handleNavigateToPracticalBatches}
            disabled={!isAllDropdownsSelected()}
          >
            View Practical Batch
          </Button>
        </Box>

        {!isAllDropdownsSelected() && (
          <h2 className={styles.noData}>
            Please select options from the dropdowns to view Batch Details.
          </h2>
        )}

      </div>

      {showBatchForm && (
        <div className={styles.practicalBatchContainer}>
          <h2 className={styles.practicalBatchTitle}>Create Practical Batch</h2>

          <Box className={styles.practicalnputGroup}>
            <label htmlFor="batchSize" className={styles.dropDownLabel}>
              Batch Size*
            </label>
            <input
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={batchSize}
              onChange={handleBatchSizeChange}
              inputProps={{ min: 1 }}
            />
          </Box>

          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2 className={styles.tableContainerTitle}>Students List</h2>
                <TableContainer component={Paper} className={styles.tableContainer}>
                  <Table aria-label="customized table" className={styles.theTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={styles.tableHead} padding="checkbox">
                          <Checkbox
                            indeterminate={
                              selectedStudents.length > 0 &&
                              selectedStudents.length < students.length
                            }
                            checked={
                              currentStudents.length > 0 &&
                              currentStudents.every((student) =>
                                selectedStudents.includes(student.enrollmentId)
                              )
                            }
                            onChange={toggleSelectAll}
                          />
                        </TableCell>
                        <TableCell className={styles.tableHead}>Enrollment ID</TableCell>
                        <TableCell className={styles.tableHead}>Student Name</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {currentStudents.map((student, index) => {
                        const isItemSelected = selectedStudents.includes(student.enrollmentId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            key={student._id}
                            hover
                            onClick={() => toggleStudentSelection(student.enrollmentId)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                            className={isItemSelected ? styles.selectedRow : ""}
                          >
                            <TableCell className={styles.tableData} padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}

                              />
                            </TableCell>
                            <TableCell className={styles.tableData} id={labelId}
                              scope="row">
                              {student.enrollmentId}
                            </TableCell>
                            <TableCell className={styles.tableData}>
                              {student.fname} {student.mname} {student.lname}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Container>


          <Box className={styles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                createBatches();
              }}
              disabled={selectedStudents.length === 0 || batchSize <= 0}
            >
              Show Batch
            </Button>
          </Box>

          <Box className={styles.paginationContainer}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={filteredStudents.length}
              rowsPerPage={batchSize}
              page={currentPage}
              onPageChange={handleChangePage}
              className={styles.pagination}
            />
          </Box>

          {batches.length > 0 && (
            <div className={styles.createdBatchContainer}>
              <h2 className={styles.createdBatchTitle}>Your Batches</h2>
              {batches.map((batch, index) => (
                <Accordion key={index} className={styles.theAccordion}>
                  <AccordionSummary
                    className={styles.accordionContent}
                    expandIcon={<ExpandMoreIcon />}>
                    <h4>
                      {index + 1}. {batch.sectionName}
                    </h4>
                    <h4>
                      {batchNames[index]} ({batch.length} Students)
                    </h4>
                    <EditIcon className={styles.accordionEdit} onClick={() => {
                      setCurrentIndex(index);
                      setCurrentBatchName(batchNames[index]);
                      setOpenDialog(true);
                    }} />
                  </AccordionSummary>

                  <AccordionDetails>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Sr No</TableCell>
                          <TableCell>Enrollment Id</TableCell>
                          <TableCell>Student Name</TableCell>
                        </TableRow>
                        {batch.map((studentId, index) => {
                          const student = students.find(
                            (s) => s.enrollmentId === studentId
                          );
                          return (
                            <TableRow key={studentId}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{student.enrollmentId}</TableCell>
                              <TableCell>
                                {student.fname} {student.mname} {student.lname}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>

                </Accordion>
              ))}

              <Box className={styles.batchButtonContainer}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={(e) => {
                    showConfirmation("want to add batches ?", handleSaveBatches, e);
                  }}
                  className={styles.submitBatchButton}
                >
                  Create Batch
                </Button>
              </Box>
            </div>
          )}

          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <div style={{ paddingTop: "1rem" }}>
              <div className={styles.inputGroup}>
                <label htmlFor="batch" className={styles.dropDownLabel}>
                  Rename Practical Batch
                </label>
                <input
                  type="text"
                  name={"batchId"}
                  value={currentBatchName}
                  onChange={(e) => setCurrentBatchName(e.target.value)}
                  className={styles.dropDownSelect}
                />
              </div>
            </div>

            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  setOpenDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setPracticalBatchName();
                }}
              >
                Rename
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default CreatePracticalBatch;
