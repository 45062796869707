import React from "react";
import styles from "./ViewDocument.module.css"; // Import your modal styles
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ViewDocument = ({ isOpen, onClose, documentUrl }) => {
  if (!isOpen) return null;

  // Function to transform Google Drive link into a preview link
  // const getPreviewUrl = (url) => {
  //   const match = url.match(/\/d\/(.*?)(\/|$)/);
  //   return match ? `https://drive.google.com/uc?export=preview&id=${match[1]}` : url;
  // };

  function modifyGoogleDriveLink(url) {
    // Match the URL pattern up to the last '/'
    const baseUrl = url.substring(0, url.lastIndexOf('/'));

    // Append '/preview' at the end
    const modifiedUrl = baseUrl + '/preview';
    return modifiedUrl;
  }
  const previewUrl = modifyGoogleDriveLink(documentUrl);

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <Box className={styles.closeIconContainer}>
          <IconButton
            className={styles.closeButtonEditEducation}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <iframe
          src={previewUrl}
          title="Document"
          className={styles.modalIframe}
        />
      </div>
    </div>
  );
};

export default ViewDocument;
