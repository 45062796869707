import React from "react";
// import FacultySidebar from "../FacultySidebar/FacultySidebar";
import Sidebar from "../../CommonComponent/Sidebar/Sidebar";
import ManualAttendance from "../../CommonComponent/Attendance/ManualAttendance/ManualAttendance";
import { Route,Routes } from "react-router-dom";

function FacultyDesktop({ logout, user }) {
  return (
    <div>
      {/* <FacultySidebar logout={logout} user={user} /> */}
      <Sidebar logout={logout} user={user} />
      <>
      <Routes>
          <Route
            path="takeAttendance/:lectureId"
            element={<ManualAttendance />}
          />
        </Routes>
      </>
    </div>
  );
}

export default FacultyDesktop;
