import React from "react";
import { Routes, Route } from "react-router-dom";
import CommunityList from "./CommunityList/CommunityList";
import ViewUserProfile from "../ViewUserProfile/ViewUserProfile";

function CommunityPage() {
  return (
    <Routes>
      <Route path="/*" element={<CommunityList />}></Route>
      <Route
        path="view-user/:enrollmentId/*"
        element={<ViewUserProfile />}
      ></Route>
    </Routes>
  );
}

export default CommunityPage;
