import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  Typography,
  Grid,
} from "@mui/material";
// import { useHistory } from 'react-router-dom';
import styles from "./ViewStudents.module.css";
import StudentView from "../StudentView/StudentView";
import axios from "axios";
import { BASE_URL } from "../../../../config/config";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../ToastContainer/ToastContainer";

const recruiterFilter = process.env.REACT_APP_RECRUITER_CRITERIA_FILTER
const dropdownFilterStudent = process.env.REACT_APP_RECRUITER_DROPDOWN_FILTER_STUDENT

const ViewStudents = () => {
  const { getToken } = useContext(UserContext);
  const [skills, setSkills] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    minPercentage: {
      SSC: 0,
      HSC: 0,
      DIPLOMA: 0,
      UG: 0,
      PG: 0,
    },
    minCGPA: {
      SSC: 0,
      HSC: 0,
      DIPLOMA: 0,
      UG: 0,
      PG: 0,
    },
  });
  const [ageRange, setAgeRange] = useState([18, 60]);
  const [studentData, setStudentData] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);

  const handleSkillChange = (event, value) => {
    setSkills(value);
  };

  const handleChange = (event, type) => {
    const { name, value } = event.target;
    setFilterCriteria((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: Number(value),
      },
    }));
  };

  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${dropdownFilterStudent}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data?.result) {
        const data = response.data.data;
        setSkillsList(data.skillsList || []);
        setCourseTypes(data.courseTypes || []);
      } else {
        Toast.error(response.data?.message || "Something went wrong");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong");
    }
  };

  const handleSubmit = async () => {
    try {
      const filteredMinPercentage = Object.fromEntries(
        Object.entries(filterCriteria.minPercentage).filter(([key, value]) => value > 0)
      );
      const filteredMinCGPA = Object.fromEntries(
        Object.entries(filterCriteria.minCGPA).filter(([key, value]) => value > 0)
      );

      const dataToSend = {
        skills,
        // minPercentage: filteredMinPercentage,
        // minCGPA: filteredMinCGPA,
        minPercentage: filterCriteria.minPercentage,
        minCGPA: filterCriteria.minCGPA,
        ageRange,
      };


      const response = await axios.post(`${BASE_URL}${recruiterFilter}`, dataToSend, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      if (response?.data?.result) {
        setStudentData(response.data.data);
      } else {
        Toast.error(response.data?.message || "Something went wrong");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Box
        sx={{
          padding: 2,
          margin: 2,
          border: "1px solid #ccc",
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" align="center">
          Filter Criteria
        </Typography>

        <Autocomplete
          multiple
          options={skillsList}
          getOptionLabel={(option) => option}
          onChange={handleSkillChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Skills"
              size="small"
              fullWidth
            />
          )}
          size="small"
        />

        <Typography variant="subtitle1">Minimum Percentage & CGPA</Typography>
        <Grid container spacing={2}>
          {courseTypes.length > 0 && courseTypes.map((level) => (
            <Grid item xs={6} key={level}>
              <TextField
                name={level}
                label={`${level.charAt(0).toUpperCase() + level.slice(1)} (%)`}
                type="number"
                value={filterCriteria.minPercentage[level]}
                onChange={(e) => handleChange(e, 'minPercentage')}
                fullWidth
                size="small"
              />
              <TextField
                name={level}
                label={`${level.charAt(0).toUpperCase() + level.slice(1)} CGPA`}
                type="number"
                value={filterCriteria.minCGPA[level]}
                onChange={(e) => handleChange(e, 'minCGPA')}
                fullWidth
                size="small"
                sx={{ marginTop: 1 }}
              />
            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          size="small"
          sx={{ marginTop: 2 }}
        >
          Apply Filters
        </Button>
      </Box>

      <div className={styles.container}>
        <StudentView studentData={studentData} />
      </div>
    </div>
  );
};

export default ViewStudents;
