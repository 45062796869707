import React, { useState, useContext, useRef } from "react";
import {
  Modal,
  Button,
  Typography,
  Card,
  CardMedia,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../../../Context/UserContext";
import axios from "axios";
import styles from "./VUPPhotoUpload.module.css";
import Toast from "../../ToastContainer/ToastContainer";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./../../../../config/config";
import defaultUserImage from "../../../../images/user (1).png";

const updateProfileImg = process.env.REACT_APP_VUP_ADMIN_UPDATE_PROFILE_IMG;

function VUPPhotoUpload({ UserImag, userData, isEditable }) {
  const [dialogs, setDialogs] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(
    "**Upload file size more than 1 kb and less than 300 Kb**"
  );
  const fileInputRef = useRef(null);
  const { enrollmentId } = useParams();

  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);

  const validateFile = (file) => {
    if (file.size > 300 * 1024) {
      return "**File size exceeds. Please choose a file less than 300Kb**";
    }
    return "";
  };

  const handleChoosePhoto = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const error = validateFile(selectedFile);
      setFileError(error);
      setFile(error ? null : selectedFile);
    }
  };

  const createFormData = (file) => {
    const formData = new FormData();
    formData.append("photo", file);
    return formData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    try {
      const token = Cookies.get("token");
      const formData = createFormData(file);
      if (!enrollmentId) {
        Toast.error(`User Enrollment Id Not Found...!`);
        return;
      }
      setShowLoader(true);

      const response = await axios.put(
        `${BASE_URL}${updateProfileImg}/${enrollmentId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.result) {
        setDialogs(false);
        localStorage.setItem("viewUserImage", response.data.data);
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  return (
    <>
      <Card
        sx={{
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          margin: "20px auto",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          height="200px"
          image={
            localStorage.getItem("viewUserImage")
              ? `data:image/png;base64,${localStorage.getItem("viewUserImage")}`
              : defaultUserImage
          }
          alt="Profile Image"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          onClick={() => {
            if (!isEditable) return;
            setDialogs(true);
          }}
        />
      </Card>

      <div>
        {isEditable && (
          <Modal open={dialogs} onClose={() => setDialogs(false)}>
            <div className={styles.modalBackground}>
              <div className={styles.modal}>
                <div className={styles.modalHeader}>
                  <Box className={styles.closeIconContainer}>
                    <IconButton
                      onClick={() => setDialogs(false)}
                      className={styles.closeIcon}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>

                  <h2>Update Profile Picture</h2>
                </div>
                <div className={styles.modalButtonContainer}>
                  <Button
                    className={styles.modalBtn}
                    variant="contained"
                    onClick={handleChoosePhoto}
                  >
                    Choose Photo
                  </Button>
                  <input
                    accept="image/*"
                    id="btn-upload"
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  {file && <Typography>{file.name}</Typography>}

                  {fileError && <p className={styles.error}>{fileError}</p>}

                  <Button
                    className={styles.modalBtn}
                    variant="contained"
                    onClick={(event) => {
                      event.preventDefault();
                      showConfirmation(
                        "Want to update profile image..?",
                        handleSubmit,
                        event
                      );
                    }}
                    sx={{ marginTop: "10px" }}
                    disabled={fileError !== ""}
                  >
                    Update Image
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default VUPPhotoUpload;
