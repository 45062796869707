import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import styles from "./VUPEducationalDetails.module.css";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Modal, Button, IconButton, Box } from "@mui/material";
import VUPEditEducation from "./VUPEditEducation/VUPEditEducation";
import { format } from "date-fns";
import UserContext from "../../../../../Context/UserContext";
import Toast from "../../../ToastContainer/ToastContainer";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./../../../../../config/config";
import currentEducation from "../../../../../images/learning.png";
import previousEducation from "../../../../../images/education (1).png";
import institute from "../../../../../images/institute.png";
import university from "../../../../../images/university (1).png";
import courseType from "../../../../../images/graduated.png";
import department from "../../../../../images/department.png";
import percentage from "../../../../../images/percentage.png";
import cgpa from "../../../../../images/cgpa.png";
import ViewDocument from "../../../ViewDocument/ViewDocument";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const getEducation = process.env.REACT_APP_VUP_ADMIN_GET_EDUCATION;
const addEducation = process.env.REACT_APP_VUP_ADMIN_ADD_EDUCATION;
const updateEducation = process.env.REACT_APP_VUP_ADMIN_UPDATE_EDUCATION;
const deleteEducation = process.env.REACT_APP_VUP_ADMIN_DELETE_EDUCATION;
const getMarksheet = process.env.REACT_APP_GET_MARKSHEET;

const VUPEducationalDetails = ({ userData, isEditable }) => {
  const { Cookies, getToken, setShowLoader, showConfirmation } =
    useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [educationalDetails, setEducationalDetails] = useState([]);
  const [currentEducations, setCurrentEducations] = useState([]);
  const [previousEducations, setPreviousEducations] = useState([]);
  const { enrollmentId } = useParams();
  const [viewDocument, setViewDocument] = useState(false);
  const [marksheetUrl, setMarksheetUrl] = useState("");

  const handleCloseDocumetView = () => {
    setViewDocument(false);
    setMarksheetUrl("");
  };

  const handleOpenDocumetView = (documentUrl) => {
    setViewDocument(true);
    setMarksheetUrl(documentUrl);
  };

  const fetchEducationData = async () => {
    const token = getToken();

    try {
      if (!enrollmentId) {
        return;
      }
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${getEducation}/${enrollmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { educationalDetails } = response.data.data;
      setEducationalDetails(() => educationalDetails);
      setShowLoader(false);
      if (response?.data?.result) {
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  useEffect(() => {
    fetchEducationData();
  }, []);

  useEffect(() => {
    const current = educationalDetails?.filter(
      (edu) => edu.isPursuing && !edu.passoutDate
    );
    const previous = educationalDetails?.filter(
      (edu) => !edu.isPursuing || (edu.isPursuing && edu.passoutDate)
    );
    setCurrentEducations(current || []);
    setPreviousEducations(previous || []);
  }, [educationalDetails]);

  const handleOpenModal = (educationData) => {
    setSelectedEducation(educationData);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEducation(null);
  };

  const handleAddEducation = async (data) => {
    try {
      const token = Cookies.get("token");
      if (!userData?.enrollmentId) {
        Toast.error(`User Enrollment Not Found...!`);
        return;
      }
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${addEducation}/${userData.enrollmentId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      setEducationalDetails(response.data.data.educationalData);
      setIsLocked(response.data.data.isLocked);
      handleCloseModal();
      setShowLoader(false);
      if (response.data.result) {
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleUpdateEducation = async (data) => {
    try {
      const token = Cookies.get("token");
      if (!userData?.enrollmentId) {
        Toast.error(`User Enrollment Not Found...!`);
        return;
      }
      setShowLoader(true);

      const response = await axios.patch(
        `${BASE_URL}${updateEducation}/${userData.enrollmentId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      setEducationalDetails(response.data.data.educationalData);
      setIsLocked(response.data.data.isLocked);
      handleCloseModal();
      setShowLoader(false);
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleDeleteEducation = async (_id) => {
    try {
      const token = Cookies.get("token");
      if (!userData?.enrollmentId) {
        Toast.error(`User Enrollment Not Found...!`);
        return;
      }
      setShowLoader(true);

      const response = await axios.delete(
        `${BASE_URL}${deleteEducation}/${userData?.enrollmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            id: _id,
          },
        }
      );

      if (response.data.result) {
        setEducationalDetails((prevDetails) =>
          prevDetails.filter((edu) => edu._id !== _id)
        );
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleDownloadMarksheet = async (marksheetFilename) => {
    if (!marksheetFilename) {
      alert("No marksheet available for download.");
      return;
    }
    const token = Cookies.get("token");
    if (!token) {
      alert("Unauthorized access. Please log in again.");
      return;
    }
    try {
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${getMarksheet}`,
        { filename: marksheetFilename },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const blobData = response.data;
      const url = window.URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = marksheetFilename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setShowLoader(false);
      if (response.data.result) {
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");

      if (error.response && error.response.status === 401) {
        alert("Unauthorized access. Please log in again.");
      } else {
        alert("Error downloading file. Please try again later.");
      }
    }
  };

  const renderEducationSection = (educationData) => {
    const formattedAdmissionDate = educationData.admissionDate
      ? format(new Date(educationData.admissionDate), "dd/MM/yyyy")
      : "";
    const formattedPassoutDate = educationData.passoutDate
      ? format(new Date(educationData.passoutDate), "dd/MM/yyyy")
      : "Present";

    // const handleDeleteClick = async (_id) => {
    //   if (window.confirm("Are you sure you want to delete this education?")) {
    //     try {
    //       await handleDeleteEducation(_id);
    //     } catch (error) {
    //     }
    //   }
    // };

    return (
      <div className={styles.educationContent} key={educationData._id}>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsHeader}>
            <div className={styles.courseInfo}>
              <h3>
                Course: <span>{educationData?.courseName}</span>
              </h3>
              <h3>
                Year:{" "}
                <span>
                  {formattedAdmissionDate} - {formattedPassoutDate}
                </span>
              </h3>
            </div>
            <div className={styles.courseStats}>
              {isEditable && !educationData.isPursuing && (
                <button
                  className={styles.educationEditButton}
                  onClick={() => handleOpenModal(educationData)}
                >
                  <ModeEditIcon sx={{ fontSize: "1rem" }} />
                  <span>Edit</span>
                </button>
              )}
              {isEditable && !educationData.isPursuing && (
                <Box className={styles.closeIconContainer}>
                  <IconButton
                    className={styles.deleteEducationButton}
                    onClick={() =>
                      showConfirmation(
                        "want to delete user education data ?",
                        handleDeleteEducation,
                        educationData._id
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </div>
          </div>
          <hr className={styles.horizontalRow} />
          <div className={styles.additionalInfo}>
            <p>
              {" "}
              <img
                src={institute}
                alt="Institute Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Institute Name: </span> {educationData.institute}
            </p>
            <p>
              {" "}
              <img
                src={university}
                alt="University Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>University: </span>{" "}
              {educationData?.university ? educationData?.university : "N/A"}
            </p>
            <p>
              {" "}
              <img
                src={courseType}
                alt="Course Type Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Course Type: </span> {educationData.courseType}
            </p>
            <p>
              {" "}
              <img
                src={department}
                alt="Department Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Department: </span> {educationData.department}
            </p>

            {educationData.percentage ? (
              <p>
                {" "}
                <img
                  src={percentage}
                  alt="Percentage Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Percentage: </span>
                {educationData.percentage ? educationData.percentage : "N/A"}
              </p>
            ) : educationData.cgpa ? (
              <p>
                <img src={cgpa} alt="CGPA Icon" className={styles.aboutIcon} />
                <span>CGPA: </span>{" "}
                {educationData.cgpa ? educationData.cgpa : "N/A"}
              </p>
            ) : (
              <p>
                {" "}
                <img
                  src={percentage}
                  alt="Percentage Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Percentage: </span>
                {educationData.percentage ? educationData.percentage : "N/A"}
              </p>
            )}
          </div>
          
         {!educationData.isPursuing && educationData?.marksheetLink && <button
            className={styles.downloadMarksheetButton}
            onClick={() => handleOpenDocumetView(educationData.marksheetLink)}
          >
            Marksheet
            <VisibilityIcon
              sx={{
                marginLeft: "10px",
                marginTop: "2px",
                fontSize: "1.2rem",
              }}
            />
          </button>}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.educationDetails}>
      <div className={styles.educationSection}>
        <div className={styles.educationContainer}>
          <h2 className={styles.headingCurrent}>
            Current Education{" "}
            <img
              src={currentEducation}
              alt="Current Education Icon"
              className={styles.icon}
            />
          </h2>
          {currentEducations?.length > 0 ? (
            currentEducations.map(renderEducationSection)
          ) : (
            <p className={styles.noData}>
              No current education records available.
            </p>
          )}
          {/* {isEditable && (
            <Button
              variant="contained"
              className={styles.addButton}
              onClick={() =>
                handleOpenModal({
                  courseName: "",
                  admissionDate: "",
                  isPursuing: true,
                })
              }
            >
              <AddIcon />
              <span>Add Current Education</span>
            </Button>
          )} */}
        </div>
      </div>
      <div className={styles.previousEducation}>
        <div className={styles.educationContainer}>
          <h2 className={styles.headingPrevious}>
            Previous Education{" "}
            <img
              src={previousEducation}
              alt="Previous Education Icon"
              className={styles.icon}
            />
          </h2>
          {previousEducations.length > 0 ? (
            previousEducations.map(renderEducationSection)
          ) : (
            <p className={styles.noData}>
              No previous education records available.
            </p>
          )}
          {isEditable && (
            <Button
              variant="contained"
              className={styles.addButton}
              onClick={() =>
                handleOpenModal({ courseName: "", admissionDate: "" })
              }
            >
              <AddIcon />
              <span>Add Previous Education</span>
            </Button>
          )}
        </div>
      </div>
      {isEditable && (
        <Modal
          className={styles.modal}
          open={isModalOpen}
          onClose={handleCloseModal}
        >
          <VUPEditEducation
            educationData={selectedEducation}
            handleAddEducation={handleAddEducation}
            handleUpdateEducation={handleUpdateEducation}
            handleCloseModal={handleCloseModal}
          />
        </Modal>
      )}
      <ViewDocument
        isOpen={viewDocument}
        onClose={handleCloseDocumetView}
        documentUrl={marksheetUrl}
      />{" "}
    </div>
  );
};

export default VUPEducationalDetails;
