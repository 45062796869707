import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Grid,
  Typography,
  Modal,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import styles from "./FacultyDashboard.module.css";
import UserContext from "../../../../Context/UserContext";
import TimerClock from "../../../CommonComponent/TimerClock/TimerClock";
import axios from "axios";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../config/config";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const getFacultyLectures = process.env.REACT_APP_GET_FACULTY_LECTURES;
const getNewsLetter = process.env.REACT_APP_HOD_GET_NEWSLETTER;

const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const FacultyDashboard = () => {
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const {
    createAttendanceSocketConnection,
    attendanceSocket,
    facultyId,
    setShowLoader,
    getToken,
    getDecodedToken,
  } = useContext(UserContext);
  const [lectureData, setLectureData] = useState([]);
  const { Cookies } = useContext(UserContext);
  const [markedAttendanceLectures, setMarkedAttendanceLectures] = useState(
    new Set()
  );
  const [currentLecture, setCurrentLecture] = useState(null);
  const [totalTime, setTotalTime] = useState(0);
  const [activeLectures, setActiveLectures] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const checkLectureActive = (startTimeISO, endTimeISO) => {
    const currentTime = new Date();

    // Extract the time parts from the current time
    const currentHours = currentTime.getUTCHours();
    const currentMinutes = currentTime.getUTCMinutes();

    // Parse the ISO strings to get the time parts
    const startTime = new Date(startTimeISO);
    const startHours = startTime.getUTCHours();
    const startMinutes = startTime.getUTCMinutes();

    const endTime = new Date(endTimeISO);
    const endHours = endTime.getUTCHours();
    const endMinutes = endTime.getUTCMinutes();

    // Convert everything to minutes since the start of the day
    const currentTotalMinutes = currentHours * 60 + currentMinutes;
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;

    // Check if the current time is within the range
    return (
      currentTotalMinutes >= startTotalMinutes &&
      currentTotalMinutes <= endTotalMinutes
    );
  };

  const fetchLectures = async () => {
    try {
      const currentDate = new Date();

      const token = Cookies.get("token");
      // Fetch faculty lectures
      setShowLoader(true);

      const facultyResponse = await axios.get(
        `${BASE_URL}${getFacultyLectures}?date=${currentDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const sortedLectures = facultyResponse.data.data.sort((a, b) => {
        const aIsActive = checkLectureActive(a.startTime, a.endTime);
        const bIsActive = checkLectureActive(b.startTime, b.endTime);

        if (aIsActive && !bIsActive) return -1;
        if (!aIsActive && bIsActive) return 1;
        return 0;
      });
      setLectureData(sortedLectures);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const navigateToTakeAttendance = (lectureId) => {
    navigate(`./takeAttendance/${lectureId}`);
  };

  useEffect(() => {
    const stopper = setInterval(() => {
      if (lectureData.length > 0) {
        let actives = [];
        lectureData.map((OBJ) => {
          const startTimeISO = OBJ.startTime;
          const endTimeISO = OBJ.endTime;
          const check = checkLectureActive(startTimeISO, endTimeISO);
          if (check) {
            setActiveLectures(() => OBJ.lectureId);
            actives.push(OBJ.lectureId);
          }
        });
        setActiveLectures(() => actives);
      }
    }, 1000);
    return () => {
      clearInterval(stopper);
    };
  });

  useEffect(() => {
    fetchLectures();
  }, [facultyId]);

  const handleOpen = (lecture) => {
    if (markedAttendanceLectures.has(lecture.lectureId)) return;
    createAttendanceSocketConnection();
    setSelectedLecture(lecture);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSecondModalClose = () => {
    setOpenSecondModal(false);
  };

  const StopTime = async () => {
    try {
      const userId = getDecodedToken()._id;
      attendanceSocket.emit(`stop-Timer`, {
        lectureId: selectedLecture.lectureId,
        userId: userId,
      });
    } catch (error) {}
  };

  const joinRoom = () => {
    // setRemainingTime(null);
    const userId = getDecodedToken()._id;
    if (currentLecture && currentLecture !== selectedLecture.lectureId) {
      Toast.error("Another Lecture Already Running....");
      return;
    }

    if (currentLecture === selectedLecture.lectureId) {
      // const userId = getDecodedToken()._id
      setOpenSecondModal(true);
      return;
    }

    setCurrentLecture(() => selectedLecture?.lectureId);
    const data = {
      lectureId: selectedLecture?.lectureId,
      subjectName: selectedLecture?.subjectName,
      startTime: selectedLecture?.startTime,
      endTime: selectedLecture?.endTime,
      timer: selectedLecture?.timer || "0.3",
      userId: userId,
      batchId: selectedLecture?.batchId,
      sectionId: selectedLecture?.sectionId,
    };

    setTotalTime(Number(data.timer) * 60);
    attendanceSocket.emit("joinRoom", data);
    attendanceSocket.on("setTimeoutValue", (data) => {
      setTotalTime(data.time);
      setOpenSecondModal(true);
    });

    attendanceSocket.on(`${selectedLecture?.lectureId}`, (data) => {
      setRemainingTime(Number(data.time));
    });

    attendanceSocket.on(`${selectedLecture?.lectureId}-close`, (data) => {
      setCurrentLecture(null);
      setOpen(false);
      setOpenSecondModal(false);

      // handleClose();
      // setSelectedLecture(null);
    });

    attendanceSocket.on(`${selectedLecture?.lectureId}-present`, (data) => {
      let msg = `${data.fname} ${data.lname} PRESENT 🎉`;
      Toast.success(msg);
    });
  };

  const getNews = async () => {
    try {
      const token = getToken(); // Assuming you're getting the token from context
      const response = await axios.get(`${BASE_URL}${getNewsLetter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.result) {
        setNewsList(response.data.data || []);
      } else {
        // setNewsList([]);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const itemsPerPage = 1;
  const totalPages = Math.ceil(newsList.length / itemsPerPage);

  const pageColors = [
    "#f0f8ff",
    "#ffe4e1",
    "#e6fffa",
    "#ffebcd",
    "#fffacd",
    "#f5f5dc",
    "#fafad2",
    "#d3d3d3",
    "#add8e6",
    "#90ee90",
    "#dcdcdc",
    "#f0fff0",
    "#ffb6c1",
    "#ff6347",
    "#ff4500",
  ];

  useEffect(() => {
    if (totalPages === 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(1);
    }

    const intervalId = setInterval(() => {
      setCurrentPage((prevPage) => {
        if (totalPages === 0) return 0;
        return prevPage < totalPages ? prevPage + 1 : 1; // Loop back to page 1 when reaching last page
      });
    }, 7000);

    return () => clearInterval(intervalId);
  }, [totalPages]);

  const currentNewsItem = newsList[currentPage - 1]; // Adjusted for 1-based indexing

  // Handle next page
  const nextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  // Handle previous page
  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const pageStyle = {
    backgroundColor: pageColors[currentPage] || "#ffffff",
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Box className={styles.mainDashboard}>
          <h1 className={styles.titleDashboard}>Today's Lectures</h1>
          <Box className={styles.cardsContainer}>
            <Box className={styles.lectureContainer}>
              <Grid container spacing={3}>
                {lectureData.length > 0 ? (
                  lectureData.map((lecture, index) => (
                    <Grid item key={index} xs={12} sm={6} md={3} lg={4}>
                      <Card
                        className={
                          activeLectures.some(
                            (data) => data === lecture.lectureId
                          )
                            ? styles.activeCard
                            : styles.cards
                        }
                        onClick={() => handleOpen(lecture)}
                      >
                        <Box className={styles.cardContent}>
                          <Typography
                            variant="h6"
                            className={styles.cardHeader}
                          >
                            {lecture.subjectName}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            {lecture.courseName}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Batch: {lecture.section} | Sem: {lecture.sem}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Room No: {lecture.roomNo}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Type: {lecture.subjectType}
                          </Typography>
                          <div className={styles.lectureTime}>
                            <Typography
                              variant="body2"
                              className={styles.cardPara}
                            >
                              Start Time: {formatTime(lecture.startTime)}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={styles.cardPara}
                            >
                              End Time: {formatTime(lecture.endTime)}
                            </Typography>
                          </div>
                        </Box>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Box className={styles.noLectureContainer}>
                    <h2 className={styles.noLecture}>No lectures available</h2>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box className={styles.sideContainer}>
          <div className={styles.marqueeContainer}>
            <h2 className={styles.newsTitle}>News / Events</h2>
            <Box className={styles.marqueeScrollable} style={pageStyle}>
              <Box className={styles.newsContent}>
                {currentNewsItem ? (
                  <div className={styles.contentInfo}>
                    <div className={styles.descContainer}>
                      <span>
                        <p>Title</p>
                        <p>{currentNewsItem.title}</p>
                      </span>
                      <span>
                        <p>Date</p>
                        <p>
                          {" "}
                          {new Date(currentNewsItem.date).toLocaleDateString()}
                        </p>
                      </span>
                      <span>
                        <p>Details</p>
                        <p>{currentNewsItem.description}</p>
                      </span>
                    </div>
                  </div>
                ) : (
                  <p className={styles.noData}>No news available.</p>
                )}
              </Box>
            </Box>

            <Box
              className={styles.paginationContainer}
              display="flex"
              alignItems="center"
            >
              <IconButton
                className={styles.pageButton}
                onClick={prevPage}
                disabled={currentPage === 1 || totalPages === 0}
              >
                <ArrowBackIosIcon />
              </IconButton>

              <h4 style={{ fontStyle: "italic" }}>
                {totalPages === 0
                  ? "0 of 0"
                  : `${currentPage} of ${totalPages}`}
              </h4>

              <IconButton
                className={styles.pageButton}
                onClick={nextPage}
                disabled={currentPage === totalPages || totalPages === 0}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              className={styles.calendar}
              sx={{
                "& .MuiPickersDay-root": {
                  color: "white",
                },
                "& .Mui-selected": {
                  backgroundColor: "yourSelectedColor",
                },
                "& .MuiIconButton-root": {
                  color: "white",
                },
                "& .MuiTypography-root": {
                  color: "white",
                },
              }}
            />{" "}
          </LocalizationProvider>
        </Box>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modal}>
            <Box className={styles.closeIconContainer}>
              <IconButton onClick={handleClose}>
                <CloseIcon className={styles.closeIcon} />
              </IconButton>
            </Box>
            {selectedLecture && (
              <Box className={styles.modalContent}>
                <h2 className={styles.modalSubjectHeader}>
                  {selectedLecture.subjectName}
                </h2>
                <h3 className={styles.modalSubCodeHeader}>
                  ({selectedLecture.subjectCode})
                </h3>
                <p className={styles.modalPara}>
                  Course Code: {selectedLecture.courseCode}
                </p>
                <p className={styles.modalPara}>
                  Course Name: {selectedLecture.courseName}
                </p>
                <p className={styles.modalPara}>
                  Semester: {selectedLecture.sem}
                </p>
                <p className={styles.modalPara}>
                  Section: {selectedLecture.section}
                </p>
                <p className={styles.modalPara}>
                  Room No: {selectedLecture.roomNo}
                </p>
                <p className={styles.modalPara}>Day: {selectedLecture.day}</p>
                <p className={styles.modalPara}>
                  Start Time: {formatTime(selectedLecture.startTime)}
                </p>
                <p className={styles.modalPara}>
                  End Time: {formatTime(selectedLecture.endTime)}
                </p>

                {activeLectures.includes(selectedLecture.lectureId) ? (
                  <Button
                    className={styles.modalButton}
                    variant="contained"
                    color="primary"
                    onClick={joinRoom}
                  >
                    {currentLecture !== null
                      ? "View Timer"
                      : "Start Lecture Timer"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.modalInactiveButton}
                    disabled
                  >
                    Lecture Inactive
                  </Button>
                )}
                {activeLectures.includes(selectedLecture.lectureId) && (
                  <Button
                    variant="contained"
                    className={styles.modalButton}
                    onClick={() => {
                      navigateToTakeAttendance(selectedLecture.lectureId);
                    }}
                  >
                    Manual Attendance
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal open={openSecondModal} onClose={handleSecondModalClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.lectureModal}>
            <Box className={styles.closeIconContainer}>
              <IconButton onClick={handleSecondModalClose}>
                <CloseIcon className={styles.closeIconSecondModal} />
              </IconButton>
            </Box>
            <h2 className={styles.lectureTimeHeader}>Remaining Time</h2>
            {remainingTime !== null && (
              <TimerClock
                className={styles.timer}
                remainingTime={remainingTime}
                initTimer={totalTime}
              />
            )}
            <Button
              className={styles.finishTimerButton}
              variant="contained"
              color="primary"
              onClick={StopTime}
            >
              Finish Timer
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default FacultyDashboard;
