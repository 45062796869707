import React, { useState, useEffect } from "react";
import { Button, IconButton, Grid, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./VUPEditCertifications.module.css";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const VUPEditCertification = ({ certificationData, handleAddCertification, handleCloseModal }) => {
  const [certification, setCertification] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [issuedBy, setIssuedBy] = useState("");
  const [issuedDate, setIssuedDate] = useState(null);
  const [error, setError] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    if (certificationData) {
      setCertification(certificationData.certification || "");
      setDescription(certificationData.description || "");
      setLink(certificationData.link || "");
      setIssuedBy(certificationData.issuedBy || "");
      setIssuedDate(certificationData.issuedDate ? dayjs(certificationData.issuedDate) : null);  // Convert to dayjs object
    }
  }, [certificationData]);

  useEffect(() => {
    validateForm();
  }, [certification, description, link, issuedBy, issuedDate]);

  const handleCertificationChange = (e) => {
    setCertification(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleIssuedByChange = (e) => {
    setIssuedBy(e.target.value);
  };

  const handleDateChange = (field, newDate) => {
    setIssuedDate(newDate ? newDate.format("YYYY-MM-DD") : null);
  };

  const validateForm = () => {
    let validationErrors = {};
    let isValid = true;

    if (!certification) {
      validationErrors.certification = "**This field is required**";
      isValid = false;
    } else if (certification.length > 50) {
      validationErrors.certification = "**Certification must be at most 50 characters**";
      isValid = false;
    }

    if (!description) {
      validationErrors.description = "**This field is required**";
      isValid = false;
    } else if (description.length > 120) {
      validationErrors.description = "**Description must be at most 120 characters**";
      isValid = false;
    }

    if (link && !isValidUrl(link)) {
      validationErrors.link = "**Please enter a valid URL**";
      isValid = false;
    }

    if (!issuedBy) {
      validationErrors.issuedBy = "**This field is required**";
      isValid = false;
    } else if (issuedBy.length > 50) {
      validationErrors.issuedBy = "**Issued By must be at most 50 characters**";
      isValid = false;
    }


    if (!issuedDate || !dayjs(issuedDate).isValid()) {
      validationErrors.issuedDate = "**Please enter a valid date**";
      isValid = false;
    }

    setError(validationErrors);
    setIsSaveDisabled(!isValid);
  };

  const isValidUrl = (url) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();

    if (isSaveDisabled) {
      return;
    }

    const formattedCertification = {
      certification: certification || "",
      description: description || "",
      link: link || "",
      issuedBy: issuedBy || "",
      issuedDate: issuedDate || "",
    };

    handleAddCertification(formattedCertification);
  };

  return (
    <div className={styles.editCertificationContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon className={styles.deleteCertification} />
        </IconButton>
      </Box>
      <h2 className={styles.editCertificationHeader}>Add Certification</h2>
      <form onSubmit={handleSubmit} className={styles.editCertificationScrollable}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.editInputs}>
              <label htmlFor="certification">Certification</label>
              <input
                id="certification"
                type="text"
                value={certification}
                onChange={handleCertificationChange}
                maxLength="200"
              />
              {error.certification && <p className={styles.error}>{error.certification}</p>}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.editInputs}>
              <label htmlFor="issuedDate">Date of Issue</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  views={["year", "month", "day"]}
                  value={issuedDate ? dayjs(issuedDate) : null}
                  onChange={(newDate) => handleDateChange("issuedDate", newDate)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
              {error.issuedDate && <p className={styles.error}>{error.issuedDate}</p>}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.editInputs}>
              <label htmlFor="issuedBy">Issued By</label>
              <input
                id="issuedBy"
                type="text"
                value={issuedBy}
                onChange={handleIssuedByChange}
                maxLength="200"
              />
              {error.issuedBy && <p className={styles.error}>{error.issuedBy}</p>}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.editInputs}>
              <label htmlFor="description">Description</label>
              <input
                id="description"
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                maxLength="200"
              />
              {error.description && <p className={styles.error}>{error.description}</p>}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.editInputs}>
              <label htmlFor="link">Link <span className={styles.span}> (Optional)</span></label>
              <input
                id="link"
                type="text"
                value={link}
                onChange={handleLinkChange}
                maxLength="200"
              />
              {error.link && <p className={styles.error}>{error.link}</p>}
            </div>
          </Grid>

          <Grid item xs={12} className={styles.submitButtonEditAchievement}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSaveDisabled}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default VUPEditCertification;
