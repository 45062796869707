import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import styles from "./VUPWorkExperience.module.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal, Button, IconButton, Box } from "@mui/material";
import VUPEditWorkExperience from "./VUPEditWorkExperience/VUPEditWorkExperience";
import { format, differenceInMonths } from "date-fns";
import UserContext from "../../../../../Context/UserContext";
import Toast from "../../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../config/config";
import currentWork from "../../../../../images/work-in-progress.png";
import pastWork from "../../../../../images/suitcase.png";
import company from "../../../../../images/corporate.png";
import location from "../../../../../images/map-location.png";
import empType from "../../../../../images/part-time.png";
import duration from "../../../../../images/hourglass-clock.png";
import jobDescription from "../../../../../images/job-description.png";

const getWorkExperience = process.env.REACT_APP_VUP_ADMIN_GET_WORK_EXPERIENCE;
const updateWorkExperience =
  process.env.REACT_APP_VUP_ADMIN_UPDATE_WORK_EXPERIENCE;
const deleteWorkExperience =
  process.env.REACT_APP_VUP_ADMIN_DELETE_WORK_EXPERIENCE;
const addWorkExperience = process.env.REACT_APP_VUP_ADMIN_ADD_WORK_EXPERIENCE;

function VUPWorkExperience({ userData, isEditable }) {
  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [selectedWorkExperience, setSelectedWorkExperience] = useState(null);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [currentWorkExperience, setCurrentWorkExperience] = useState({});
  const [pastWorkExperiences, setPastWorkExperiences] = useState([]);
  const [modalActionType, setModalActionType] = useState("");

  const fetchWorkExperienceData = async () => {
    const token = Cookies.get("token");

    if (!userData?.enrollmentId) {
      return;
    }

    try {
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${getWorkExperience}/${userData?.enrollmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { pastExperience, isLocked } = response.data.data;
      setShowLoader(false);
      setWorkExperiences(() => pastExperience);
      setIsLocked(() => isLocked);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went Wrong...!");
    }
  };

  useEffect(() => {
    fetchWorkExperienceData();
  }, [userData]);

  useEffect(() => {
    const current = workExperiences?.filter(
      (exp) => exp.isWorking && !exp.lastDate
    );
    const previous = workExperiences?.filter(
      (exp) => !exp.isWorking || (exp.isWorking && exp.lastDate)
    );

    setPastWorkExperiences(() => previous);
    setCurrentWorkExperience(() => current);
  }, [workExperiences]);

  const handleOpenModal = (experienceData = {}) => {
    setSelectedWorkExperience(experienceData);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedWorkExperience(null);
  };

  const handleAddWorkExperience = async (data) => {
    try {
      const token = Cookies.get("token");
      if (!userData?.enrollmentId) {
        Toast.error(`User Enrollment Not Found...!`);
        return;
      }
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${addWorkExperience}/${userData.enrollmentId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setWorkExperiences(response.data.data.expData);
      setIsLocked(response.data.data.isLocked);
      handleCloseModal();
      setShowLoader(false);
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleUpdateWorkExperience = async (data) => {
    try {
      const token = Cookies.get("token");
      if (!userData?.enrollmentId) {
        Toast.error(`User Enrollment Not Found...!`);
        return;
      }
      setShowLoader(true);

      const response = await axios.patch(
        `${BASE_URL}${updateWorkExperience}/${userData.enrollmentId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setWorkExperiences(response.data.data.expData);
      setIsLocked(response.data.data.isLocked);
      handleCloseModal();
      setShowLoader(false);
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const handleDeleteWorkExperience = async (_id) => {
    try {
      const token = Cookies.get("token");
      if (!userData?.enrollmentId) {
        Toast.error(`User Enrollment Not Found...!`);
        return;
      }
      setShowLoader(true);

      const response = await axios.delete(
        `${BASE_URL}${deleteWorkExperience}/${userData.enrollmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            id: _id,
          },
        }
      );

      if (response?.data?.result) {
        const newData = response.data.data;
        setWorkExperiences([...newData]);
        Toast.success(response?.data?.message || "Success !");
      } else {
        Toast.error(response?.data?.message || "Something Went Wrong !");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something Went Wrong !");
    }
  };

  const renderWorkExperienceSection = (experienceData) => {
    if (!experienceData) return null;

    const formattedJoiningDate = experienceData.joiningDate
      ? format(new Date(experienceData.joiningDate), "dd/MM/yyyy")
      : "";
    const formattedLastDate = experienceData.lastDate
      ? format(new Date(experienceData.lastDate), "dd/MM/yyyy")
      : experienceData.isWorking
      ? "Present"
      : "";

    // const handleDeleteClick = async (_id) => {
    //   if (
    //     window.confirm("Are you sure you want to delete this work Experience?")
    //   ) {
    //     try {
    //       await handleDeleteWorkExperience(_id);
    //     } catch (error) {
    //     }
    //   }
    // };

    const calculateTenure = () => {
      if (experienceData.joiningDate && experienceData.lastDate) {
        const joiningDate = new Date(experienceData.joiningDate);
        const lastDate = new Date(experienceData.lastDate);
        const tenureMonths = differenceInMonths(lastDate, joiningDate);
        const years = Math.floor(tenureMonths / 12);
        const months = tenureMonths % 12;

        return `${years} years ${months} months`;
      } else if (experienceData.isWorking && experienceData.joiningDate) {
        const joiningDate = new Date(experienceData.joiningDate);
        const currentMonth = new Date();
        const tenureMonths = differenceInMonths(currentMonth, joiningDate);
        const years = Math.floor(tenureMonths / 12);
        const months = tenureMonths % 12;

        return `${years} years ${months} months`;
      } else {
        return "Tenure not calculated";
      }
    };

    return (
      <div className={styles.experienceContent} key={experienceData._id}>
        <div className={styles.dataContainer}>
          <div className={styles.dataHeader}>
            <div className={styles.jobInfo}>
              <h3>
                Position: <span>{experienceData.position}</span>
              </h3>
              <h3>
                Duration:{" "}
                <span>
                  {formattedJoiningDate} - {formattedLastDate}
                </span>
              </h3>
            </div>
            <div className={styles.jobStats}>
              {isEditable && (
                <button
                  className={styles.experienceEditButton}
                  onClick={() => handleOpenModal(experienceData)}
                >
                  <ModeEditIcon sx={{ fontSize: "1rem" }} />
                  <span>Edit</span>
                </button>
              )}
              {isEditable && (
                <Box className={styles.closeIconContainer}>
                  <IconButton
                    className={styles.deleteJobButton}
                    onClick={() => {
                      showConfirmation(
                        "want to delete user work Experience ?",
                        handleDeleteWorkExperience,
                        experienceData._id
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </div>
          </div>
          <hr className={styles.horizontalRow} />
          <div className={styles.additionalJobInfo}>
            <p>
              {" "}
              <img
                src={company}
                alt="Company Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Company: </span> {experienceData.company}
            </p>
            <p>
              {" "}
              <img
                src={location}
                alt="Location Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Location: </span> {experienceData.location}
            </p>
            <p>
              {" "}
              <img
                src={empType}
                alt="Employment Type Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Type: </span> {experienceData.empType}
            </p>
            <p>
              {" "}
              <img
                src={duration}
                alt="Duration Tenurity Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Tenure: </span> {calculateTenure()}
            </p>
            <p>
              {" "}
              <img
                src={jobDescription}
                alt="Job Description Icon"
                className={styles.aboutIcon}
              />{" "}
              <span>Role Description / Responsibilities: </span>{" "}
              {experienceData.roleDescription}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.workExperienceDetails}>
      <div className={styles.currentWorkExperience}>
        <div className={styles.experienceContainer}>
          <h2 className={styles.headingCurrent}>
            Current Work Experience{" "}
            <img
              src={currentWork}
              alt="Current Work Icon"
              className={styles.icon}
            />
          </h2>
          {currentWorkExperience && currentWorkExperience.length > 0 ? (
            currentWorkExperience.map(renderWorkExperienceSection)
          ) : (
            <>
              <p className={styles.noData}>
                No current work experience records available.
              </p>{" "}
            </>
          )}
          {isEditable && (
            <Button
              className={styles.addButton}
              variant="contained"
              onClick={() => handleOpenModal({})}
            >
              <AddIcon />
              <span>Add Current Experience</span>
            </Button>
          )}
        </div>
      </div>

      <div className={styles.pastWorkExperiences}>
        <div className={styles.experienceContainer}>
          <h2 className={styles.headingPrevious}>
            Past Work Experience{" "}
            <img src={pastWork} alt="Past Work Icon" className={styles.icon} />
          </h2>
          {pastWorkExperiences && pastWorkExperiences.length > 0 ? (
            pastWorkExperiences.map(renderWorkExperienceSection)
          ) : (
            <p className={styles.noData}>
              No past work experience records available.
            </p>
          )}
          {isEditable && (
            <Button
              className={styles.addButton}
              variant="contained"
              onClick={() => handleOpenModal({})}
            >
              <AddIcon />
              <span>Add Past Experience</span>
            </Button>
          )}
        </div>
      </div>

      <Modal
        className={styles.modal}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <h2>{modalActionType}</h2>
          </div>
          <div className={styles.modalBody}>
            <VUPEditWorkExperience
              experienceData={selectedWorkExperience}
              handleAddWorkExperience={handleAddWorkExperience}
              handleUpdateWorkExperience={handleUpdateWorkExperience}
              handleCloseModal={handleCloseModal}
              // modalActionType={modalActionType}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VUPWorkExperience;
