import React from "react";
import CreateClassroom from "./CreateClassroom/CreateClassroom";
import ClassroomList from "./ClassroomList/ClassroomList";
import { Route, Routes } from "react-router-dom";


const MyClassrooms = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ClassroomList />}></Route>
        <Route path="add-classroom" element={<CreateClassroom />}></Route>
      </Routes>
    </>
  );
};

export default MyClassrooms;
