import { Routes, Route } from "react-router-dom";
import ViewSubject from "./ViewSubject/ViewSubject";
import AddSubject from "./AddSubject/AddSubject";

function Subject({ FetchDataForaddCourse }) {
  return (
    <Routes>
      <Route
        path="/"
        element={<ViewSubject FetchDataForaddCourse={FetchDataForaddCourse} />}
      ></Route>
      <Route
        path="add-subject"
        element={<AddSubject FetchDataForaddCourse={FetchDataForaddCourse} />}
      ></Route>
    </Routes>
  );
}

export default Subject;
