import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import styles from "./StudentAttendance.module.css";
import AverageAttendance from "./AverageAttendance/AverageAttendance";
import DateWiseAttendance from "./DatewiseAttendance/DateWiseAttendance";

export default function StudentAttendance() {
  const [current, setCurrent] = useState({});

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleContainer}>Attendance</h1>
      <Routes>
        <Route
          path="/"
          element={<AverageAttendance selectedField={current} />}
        ></Route>
        <Route
          path="datewiseAttendance"
          element={<DateWiseAttendance selectedField={current} />}
        ></Route>
      </Routes>
    </div>
  );
}
