import React from 'react'
import Sidebar from '../../CommonComponent/Sidebar/Sidebar'

const SuperAdminDesktop = ({ user, logout }) => {
  return (
    <>
      <Sidebar logout={logout} user={user} />
    </>
  )
}

export default SuperAdminDesktop  